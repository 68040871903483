import { useAxios } from '../../../context/AxiosContext';
import { ApiResponse } from '../response';

export interface EnglishCategoryDto {
  id: number;
  title: string;
  description: string;
}

export function useEnglishCategory() {
  const { instance } = useAxios();

  async function getEnglishCategories(): Promise<
    ApiResponse<EnglishCategoryDto[]>
  > {
    try {
      const response =
        await instance.get<EnglishCategoryDto[]>(`/english/categories`);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  return { getEnglishCategories };
}
