import { useEffect } from 'react';

import { Toast } from '@sealfye/ui-components';
import classnames from 'classnames';

import { useToaster, useToasterActions } from '../../../state/toasterStore';
import { BaseComponentProps } from '../../../types/base-component.types';

import styles from './Toaster.module.scss';

function Toaster({ className }: BaseComponentProps) {
  const messages = useToaster();
  const { deleteMessage } = useToasterActions();

  useEffect(() => {
    const interval = setInterval(() => {
      const dateNow = new Date().getTime();
      messages
        .filter((element) => element.timestamp < dateNow - 60000)
        .forEach((element) => {
          deleteMessage(element.id);
        });
    }, 1000);
    return () => clearInterval(interval);
  });

  if (messages.length === 0) {
    return null;
  }

  return (
    <div className={classnames(styles['main'], className)}>
      {messages.map((item) => {
        return (
          <Toast
            key={item.id}
            message={{
              id: item.id,
              title: item.title,
              timestamp: item.timestamp,
              text: item.message,
            }}
            variant={item.variant}
            onClose={() => deleteMessage(item.id)}
          />
        );
      })}
    </div>
  );
}

export { Toaster };
