import { Button } from '@sealfye/ui-components';
import classnames from 'classnames';

import { BaseComponentProps } from '../../../../types/base-component.types';

import styles from './Footer.module.scss';

type ContainerProps = BaseComponentProps & {
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

function Footer({ className, loading, disabled, onClick }: ContainerProps) {
  return (
    <div className={classnames(styles['main'], className)}>
      <div className={styles['agreement']}>
        <div className={styles['buttons']}>
          <Button
            className={styles['button']}
            onClick={onClick}
            loading={loading}
            disabled={loading || disabled}
          >
            Empezar
          </Button>
        </div>
      </div>
    </div>
  );
}

export { Footer };
