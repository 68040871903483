import { useEffect, useState } from 'react';

import { Button } from '@sealfye/ui-components';
import classnames from 'classnames';

import { useProfile } from '../../../../../context/ProfileContext';
import { Room } from '../../../../../services/api/hooks/useChallenge';
import { BaseComponentProps } from '../../../../../types/base-component.types';
import { PlayerCard } from '../layout/PlayerCard';

import styles from './Presentation.module.scss';

export type ContainerProps = BaseComponentProps & {
  room: Room;
  onReady: () => void;
};

function Presentation({
  className,
  room,
  onReady,
  testId = 'ui-challenge-presentation',
}: ContainerProps) {
  const { profile } = useProfile();
  const [active, setActive] = useState(false);

  const [ready, setReady] = useState(false);

  const onClickReady = async () => {
    setReady(true);
    onReady();
  };

  useEffect(() => {
    setActive(true);

    return () => {
      setActive(false);
    };
  }, []);

  return (
    <div
      className={classnames(styles['main'], className)}
      data-test-id={testId}
    >
      <div className={styles['title']}>
        <h3 className={styles['title__text']}>¡Oponente encontrado!</h3>
      </div>
      <PlayerCard
        active={active}
        player={
          room.players.find((player) => {
            return player.username !== profile.username;
          }) ?? room.players[0]
        }
      />
      {ready ? (
        <Button variant="outline-primary" className={styles['button']} disabled>
          Esperando a tu rival...
        </Button>
      ) : (
        <Button
          variant="outline-primary"
          className={styles['button']}
          onClick={onClickReady}
        >
          Empezar
        </Button>
      )}
    </div>
  );
}

export { Presentation };
