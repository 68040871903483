import { useEffect, useRef, useState } from 'react';

import {
  Button,
  Card,
  CardPopup,
  List,
  ListSkeleton,
  Text,
} from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import { IoReaderOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { Empty } from '../../../components/core/Empty';
import { Loading } from '../../../components/core/Loading';
import { TheoryQuestionAnswerViewer } from '../../../components/features/theory/TheoryQuestionAnswerViewer';
import { OffsetPagination } from '../../../components/shared/offset-pagination/OffsetPagination';
import { Page } from '../../../components/shared/page/Page';
import { useProfile } from '../../../context/ProfileContext';
import { OffsetPaginationResponse } from '../../../services/api/hooks/usePageRequest';
import { TheoryQuestionDto } from '../../../services/api/hooks/useTheoryQuestion';
import { useUsers } from '../../../services/api/hooks/useUsers';
import { useAppActions } from '../../../state/appStore';

import styles from './TheoryBookmarkedQuestionsPage.module.scss';

function TheoryBookmarkedQuestionsPage() {
  const { getTheoryQuestionsBookmarked } = useUsers();
  const { setShowGetPremiumPopup } = useAppActions();
  const navigate = useNavigate();
  const { profile } = useProfile();
  const [paginationResponse, setPaginationResponse] =
    useState<OffsetPaginationResponse>();
  const [questions, setQuestions] = useState<TheoryQuestionDto[]>([]);
  const [current, setCurrent] = useState<string>();

  const [pageNumber, setPageNumber] = useState(1);

  const nodeRef = useRef<HTMLDivElement>(null);

  const {
    data: response,
    isSuccess,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['getTheoryQuestionsBookmarked', pageNumber],
    queryFn: () => getTheoryQuestionsBookmarked(pageNumber),
    gcTime: 5 * (60 * 1000), // 5 mins
  });

  useEffect(() => {
    if (response?.data !== undefined) {
      setPaginationResponse(response.data);
      setQuestions(response.data.data);
    }
  }, [response]);

  const handleSubmit = () => {
    if (profile.subscription?.isActive) {
      navigate({
        pathname: '/teoria/simulacro',
        search: `?guardadas=true`,
      });
    } else {
      setShowGetPremiumPopup(true);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isSuccess && response?.data?.data.length === 0) {
    return <Empty title="No tienes ninguna pregunta guardada" />;
  }

  return (
    <Page title="Mis preguntas guardadas">
      {current ? (
        <CardPopup
          className={styles['card-popup']}
          onClose={() => {
            refetch();
            setCurrent(undefined);
          }}
          refClose={nodeRef}
          closeable={false}
        >
          <TheoryQuestionAnswerViewer
            id={current}
            onClose={() => {
              nodeRef.current?.click();
            }}
          />
        </CardPopup>
      ) : null}
      <Card title="Listado de preguntas">
        {isLoading ? (
          <ListSkeleton items={15} />
        ) : (
          <>
            <List
              items={questions.map((item) => ({
                label:
                  item.lesson < 2000
                    ? {
                        value: `Tema ${item.lesson}`,
                      }
                    : {
                        value: `Examen ${item.lesson}`,
                      },
                text:
                  item.question.length > 150
                    ? item.question.substring(0, 150) + '…'
                    : item.question,
                info: item.question,
                icon: (
                  <IoReaderOutline
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (profile.subscription?.isActive) {
                        setCurrent(item.id);
                      } else {
                        setShowGetPremiumPopup(true);
                      }
                    }}
                  />
                ),
              }))}
            />
            <OffsetPagination
              pagination={paginationResponse}
              onPageChange={(page) => setPageNumber(page)}
            />
          </>
        )}
      </Card>
      <div className={styles['submit']}>
        <Button
          className={styles['submit__button']}
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Crear simulacro
        </Button>
        <Text className={styles['submit__label']} variety="annotation">
          Genera un simulacro con las preguntas que tienes guardadas (máximo las
          100 últimas guardadas)
        </Text>
      </div>
    </Page>
  );
}

export { TheoryBookmarkedQuestionsPage };
