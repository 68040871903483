import { useEffect, useState } from 'react';

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isWidthLessThan = (width: number | string) => {
    if (typeof width === 'string') {
      const widthNumber = parseInt(width.replace('px', ''), 10);
      return windowSize.width < widthNumber;
    }
    return windowSize.width < width;
  };

  const isWidthGreaterThan = (width: number | string) => {
    if (typeof width === 'string') {
      const widthNumber = parseInt(width.replace('px', ''), 10);
      return windowSize.width > widthNumber;
    }
    return windowSize.width > width;
  };

  return { ...windowSize, isWidthLessThan, isWidthGreaterThan };
}
