import { PlayerCard as PlayerCardComponent } from '@sealfye/ui-components';
import classnames from 'classnames';

import { Player } from '../../../../../services/api/hooks/useChallenge';
import { BaseComponentProps } from '../../../../../types/base-component.types';

import styles from './PlayerCard.module.scss';

export type ContainerProps = BaseComponentProps & {
  active?: boolean;
  player: Player;
};

function PlayerCard({
  className,
  active,
  player,
  testId = 'ui-challenge-player-card',
}: ContainerProps) {
  return (
    <PlayerCardComponent
      className={classnames(styles['main'], className)}
      testId={testId}
      username={player.username}
      points={player.points ?? 0}
      type={
        player.points > 1000
          ? 'gold'
          : player.points > 500
            ? 'silver'
            : 'bronze'
      }
      image={player.avatar ? player.avatar : undefined}
      active={active}
    />
  );
}

export { PlayerCard };
