import { Account } from '@sealfye/ui-components';
import classnames from 'classnames';

import { ReactComponent as AwardImg } from '../../../assets/images/award.svg';

import styles from './User.module.scss';

type ContainerProps = {
  className?: string;
  username: string;
  email: string;
  points?: number;
  image?: string;
  school?: string;
};

function User({ className, username, email, points, image }: ContainerProps) {
  return (
    <div className={classnames(styles['main'], className)}>
      <Account username={username} email={email} src={image} cropEmail />
      <div className={styles['subheader']}>
        <div className={styles['award']}>
          <AwardImg className={styles['icon']} />
          <span className={styles['points']}>{points ?? 0}</span>
        </div>
      </div>
    </div>
  );
}

export { User };
