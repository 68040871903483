import { useEffect } from 'react';

import { SimpleQuestion } from '@sealfye/ui-components';

import { useCountDownTimer } from '../../../../../hooks/useCountDownTimer';
import { QuestionType } from '../../../../../types/Question.types';
import { BaseComponentProps } from '../../../../../types/base-component.types';

export type ContainerProps = BaseComponentProps & {
  timeInSeconds: number;
  question: QuestionType;
  onBookmark?: (id: string) => Promise<void>;
  onUnbookmark?: (id: string) => Promise<void>;
  onSubmit: (question: QuestionType, timeInSeconds: number) => void;
  onTimeout: () => void;
};

function OfflineSimpleQuestion({
  className,
  timeInSeconds,
  question,
  onBookmark,
  onUnbookmark,
  onSubmit,
  onTimeout,
  testId = 'ui-challenge-offline-question',
}: ContainerProps) {
  const [, , _s] = useCountDownTimer(0, 0, timeInSeconds);

  useEffect(() => {
    if (_s === 0) {
      onTimeout();
    }
  }, [_s]);

  return (
    <SimpleQuestion
      testId={testId}
      className={className}
      timeInSeconds={timeInSeconds}
      question={question}
      onBookmark={onBookmark}
      onUnbookmark={onUnbookmark}
      onSubmit={onSubmit}
    />
  );
}

export { OfflineSimpleQuestion };
