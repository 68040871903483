import { useEffect, useState } from 'react';

import { SimpleQuestionnaire } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Loading } from '../../../../components/core/Loading';
import { useProfile } from '../../../../context/ProfileContext';
import { useAptitudeQuestion } from '../../../../services/api/hooks/useAptitudeQuestion';
import { useEditorActions } from '../../../../state/editorStore';
import { useFeedbackActions } from '../../../../state/feedbackStore';
import { useSidebarActions } from '../../../../state/sidebarStore';
import { QuestionAnswerType } from '../../../../types/Question.types';

import styles from './AptitudeSimpleQuestionnairePage.module.scss';

function AptitudeSimpleQuestionnairePage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setShowBtnToggle } = useSidebarActions();
  const { openFeedback } = useFeedbackActions();
  const { openEditor } = useEditorActions();
  const [questions, setQuestions] = useState<QuestionAnswerType[]>([]);
  const { profile } = useProfile();

  const {
    bookmarkAptitudeQuestion,
    unbookmarkAptitudeQuestion,
    getRandomAptitudeQuestions,
    reportAptitudeQuestionError,
  } = useAptitudeQuestion();

  useEffect(() => {
    setShowBtnToggle(false);
    return () => {
      setShowBtnToggle(true);
    };
  }, []);

  const {
    data: response,
    isLoading,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['getAptitudeSimpleQuestionnairePage', searchParams],
    queryFn: () => {
      if (searchParams.get('temas')) {
        return getRandomAptitudeQuestions(
          searchParams
            .get('temas')!
            .split(',')
            .map((item) => parseInt(item, 10)),
        );
      } else {
        return getRandomAptitudeQuestions();
      }
    },
  });

  useEffect(() => {
    if (response?.data) {
      setQuestions((prev) => {
        if (response.data) {
          return [
            ...prev,
            {
              id: response.data.id,
              text: response.data.question,
              picture: response.data.questionPayload,
              subject: response.data.categoryName,
              options: response.data.answers,
              selectedOption: 0,
              bookmarked: response.data.bookmarked ?? false,
              correctOption: response.data.correctAnswer ?? 0,
              reason: response.data.reason,
            },
          ];
        } else {
          return prev;
        }
      });
    }
  }, [response]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={styles['main']}>
      {questions.length > 0 && (
        <SimpleQuestionnaire
          className={styles['questionnaire']}
          questions={questions}
          loading={isFetching}
          onBookmark={async (id) => {
            const response = await bookmarkAptitudeQuestion(id);
            if (!response.success) {
              throw new Error("Couldn't bookmark question");
            }
          }}
          onUnbookmark={async (id) => {
            const response = await unbookmarkAptitudeQuestion(id);
            if (!response.success) {
              throw new Error("Couldn't unbookmark question");
            }
          }}
          onFeedback={(id) => {
            openFeedback(
              '¿Has visto algún error en esta pregunta?',
              async (feedback) => {
                const response = await reportAptitudeQuestionError(
                  id,
                  feedback,
                );

                if (!response.success) {
                  throw new Error('Error al enviar el feedback');
                }
              },
            );
          }}
          onEdit={
            profile.role === 'admin' ||
            profile.role === 'contributor' ||
            profile.role === 'contributor:aptitude'
              ? (id) => {
                  openEditor('aptitude', id, refetch, refetch);
                }
              : undefined
          }
          onNext={refetch}
          onClose={() => {
            navigate('/psicotecnicos');
          }}
        />
      )}
    </div>
  );
}

export { AptitudeSimpleQuestionnairePage };
