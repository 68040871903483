/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

export interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export type PwaProps = {
  prompt: IBeforeInstallPromptEvent | null;
  standalone: boolean;
  needUpdate: boolean;
  setPrompt: Dispatch<SetStateAction<IBeforeInstallPromptEvent | null>>;
  setStandalone: (payload: boolean) => void;
  setNeedUpdate: (payload: boolean) => void;
  promptToInstall: () => Promise<void>;
};

export const PwaContext = createContext<PwaProps | null>(null);

export const PwaContextProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const [prompt, setPrompt] = useState<IBeforeInstallPromptEvent | null>(null);
  const [standalone, setStandalone] = useState<boolean>(false);
  const [needUpdate, setNeedUpdate] = useState<boolean>(false);

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event',
      ),
    );
  };

  useEffect(() => {
    const ready = (event: IBeforeInstallPromptEvent) => {
      event.preventDefault();
      setPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', ready as any);

    return () => {
      window.removeEventListener('beforeinstallprompt', ready as any);
    };
  }, []);

  useEffect(() => {
    const ready = () => {
      setPrompt(null);
    };

    window.addEventListener('appinstalled', ready as any);

    return () => {
      window.removeEventListener('appinstalled', ready as any);
    };
  }, []);

  useEffect(() => {
    const ready = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if ('standalone' in window.navigator && window.navigator.standalone) {
        setStandalone(true);
      }

      if (window.matchMedia('(display-mode: standalone)').matches) {
        setStandalone(true);
      }
    };

    window.addEventListener('DOMContentLoaded', ready as any);

    return () => {
      window.removeEventListener('DOMContentLoaded', ready as any);
    };
  }, []);

  return (
    <PwaContext.Provider
      value={{
        prompt,
        standalone,
        needUpdate,
        setPrompt,
        setStandalone,
        setNeedUpdate,
        promptToInstall,
      }}
    >
      {children}
    </PwaContext.Provider>
  );
};

export const usePwa = () => {
  const context = useContext(PwaContext);

  if (!context) {
    throw new Error('usePwa must be used within a PwaContextProvider');
  }

  return context;
};
