import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import axios from 'axios';

import { Splash } from '../components/core/Splash';

export type Configuration = {
  environment?: string;
  firebaseConfig?: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId?: string;
  };
  firebaseCloudMessaging?: {
    vapidKey: string;
  };
  appCheck?: {
    siteKey: string;
    debugToken?: string;
  };
  app?: {
    apiUrl: string;
    pageUrl: string;
    appUrl: string;
  };
  sentry?: {
    dsn: string;
  };
};

interface ConfigurationProps {
  configuration: Configuration;
}

export const ConfigurationContext = createContext<ConfigurationProps | null>(
  null,
);

export const ConfigurationContextProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const [configuration, setConfiguration] = useState<Configuration>();

  const fetchConfiguration = useCallback(async () => {
    const response = await axios.get('/config/env.json');

    if (response && response.status === 200 && response.data !== undefined) {
      setConfiguration(response.data);
    }
  }, []);

  useEffect(() => {
    fetchConfiguration();
  }, [fetchConfiguration]);

  if (configuration === undefined) {
    return <Splash />;
  }

  return (
    <ConfigurationContext.Provider value={{ configuration }}>
      {children}
    </ConfigurationContext.Provider>
  );
};

export const useConfiguration = () => {
  const context = useContext(ConfigurationContext);

  if (!context) {
    throw new Error(
      'useConfiguration must be used within a ConfigurationContextProvider',
    );
  }

  return context;
};
