import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useShallow } from 'zustand/shallow';

export interface AppState {
  showGetPremiumPopup: boolean;
  showAppUpdatePopup: boolean;
  showServerErrorPopup: boolean;
  actions: {
    setShowGetPremiumPopup: (payload: boolean) => void;
    setShowAppUpdatePopup: (payload: boolean) => void;
    setShowServerErrorPopup: (payload: boolean) => void;
  };
}

export const useAppStore = create<AppState>()(
  devtools((set) => ({
    showGetPremiumPopup: false,
    showAppUpdatePopup: false,
    showServerErrorPopup: false,
    actions: {
      setShowGetPremiumPopup: (payload: boolean) =>
        set(() => ({
          showGetPremiumPopup: payload,
        })),
      setShowAppUpdatePopup: (payload: boolean) =>
        set(() => ({
          showAppUpdatePopup: payload,
        })),
      setShowServerErrorPopup: (payload: boolean) =>
        set(() => ({
          showServerErrorPopup: payload,
        })),
    },
  })),
);

export const useApp = () =>
  useAppStore(
    useShallow((state) => ({
      showGetPremiumPopup: state.showGetPremiumPopup,
      showAppUpdatePopup: state.showAppUpdatePopup,
      showServerErrorPopup: state.showServerErrorPopup,
    })),
  );

export const useAppActions = () => useAppStore((state) => state.actions);
