import { Card, ValueChart } from '@sealfye/ui-components';
import classnames from 'classnames';

import { ValueCardProps } from './ValueCard.types';

import styles from './ValueCard.module.scss';

function ValueCard({
  className,
  icon,
  title,
  value,
  trending,
  loading,
}: ValueCardProps) {
  return (
    <Card
      icon={icon}
      title={title}
      className={classnames(styles['main'], className)}
      loading={loading}
    >
      <ValueChart
        className={styles['value-chart']}
        value={value}
        trending={trending}
      />
    </Card>
  );
}

export { ValueCard };
