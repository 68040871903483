import { Logo } from '@sealfye/ui-components';
import classnames from 'classnames';

import { BaseComponentProps } from '../../types/base-component.types';

import styles from './Splash.module.scss';

function Splash({ className }: BaseComponentProps) {
  return (
    <div className={classnames(styles['main'], className)}>
      <Logo className={styles['logo']} />
    </div>
  );
}

export { Splash };
