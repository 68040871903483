/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAxios } from '../../../context/AxiosContext';
import { ApiResponse } from '../response';

export interface SubjectDto {
  id: string;
  name: string;
  units: UnitDto[];
}

export interface UnitDto {
  id: string;
  index: number;
  name: string;
  lessons: LessonDto[];
}

export interface LessonDto {
  id: string;
  index: number;
  name: string;
  outline?: string;
  resources: ResourceDto[];
}

export interface ResourceDto {
  id: string;
  name: string;
  url: string;
}

export function useSubjects() {
  const { instance } = useAxios();

  async function getTheorySubject(): Promise<ApiResponse<SubjectDto>> {
    try {
      const response = await instance.get<SubjectDto>(
        `/v2/subjects/cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      if (error.response.status === 404) {
        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  return {
    getTheorySubject,
  };
}
