/* eslint-disable @typescript-eslint/no-explicit-any */
export function equal(actual: any, expected: any) {
  if (actual === expected) return true;

  if (
    actual &&
    expected &&
    typeof actual == 'object' &&
    typeof expected == 'object'
  ) {
    if (actual.constructor !== expected.constructor) return false;

    let length, i;
    if (Array.isArray(actual)) {
      length = actual.length;
      if (length != expected.length) return false;
      for (i = length; i-- !== 0; )
        if (!equal(actual[i], expected[i])) return false;
      return true;
    }

    if (actual.constructor === RegExp)
      return (
        actual.source === expected.source && actual.flags === expected.flags
      );
    if (actual.valueOf !== Object.prototype.valueOf)
      return actual.valueOf() === expected.valueOf();
    if (actual.toString !== Object.prototype.toString)
      return actual.toString() === expected.toString();

    const keys = Object.keys(actual);
    length = keys.length;
    if (length !== Object.keys(expected).length) return false;

    for (i = length; i-- !== 0; )
      if (!Object.prototype.hasOwnProperty.call(expected, keys[i]))
        return false;

    for (i = length; i-- !== 0; ) {
      const key = keys[i];

      if (key === '_owner' && actual.$$typeof) {
        // React-specific: avoid traversing React elements' _owner.
        //  _owner contains circular references
        // and is not needed when comparing the actual elements
        // (and not their owners)
        continue;
      }

      if (!equal(actual[key], expected[key])) return false;
    }

    return true;
  }

  // true if both NaN, false otherwise
  return actual !== actual && expected !== expected;
}
