import {
  PropsWithChildren,
  createContext,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';

import { useLocalStorage } from '../hooks/useLocalStorage';

export type ColorScheme = 'light' | 'dark' | undefined;

export type ThemeProps = {
  theme: ColorScheme;
  setTheme: (value: ColorScheme) => void;
};

export const ThemeContext = createContext<ThemeProps | null>(null);

export const ThemeContextProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [userTheme, setUserTheme] = useLocalStorage<ColorScheme>(
    'themeMode',
    undefined,
  );

  function getColorScheme(): ColorScheme {
    if (userTheme === 'dark') {
      return 'dark';
    }

    if (userTheme === 'light') {
      return 'light';
    }

    if (prefersDark) {
      return 'dark';
    }

    return 'light';
  }

  const [theme, setTheme] = useState<ColorScheme>(getColorScheme());

  useLayoutEffect(() => {
    if (theme === 'dark') {
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute('content', '#222327');
      document.documentElement.classList.remove('light');
      document.documentElement.classList.add('dark');
      setUserTheme('dark');
    } else {
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute('content', '#f9f9f9');
      document.documentElement.classList.remove('dark');
      document.documentElement.classList.add('light');
      setUserTheme('light');
    }
  }, [setUserTheme, theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within a ThemeContextProvider');
  }

  return context;
};
