import { Button } from '@sealfye/ui-components';

import ContributeImg from '../../../../assets/images/contribute.svg';
import { Page } from '../../../../components/shared/page/Page';
import { SectionCard } from '../../../../components/shared/section-card/SectionCard';
import { useEditorActions } from '../../../../state/editorStore';

function TheorySchemasContributePage() {
  const { openEditor } = useEditorActions();

  return (
    <Page title="Esquemas de teoría">
      <SectionCard
        title="Sube nuevos esquemas de teoría"
        image={ContributeImg}
        button={
          <Button
            variant="outline-primary"
            onClick={() => {
              openEditor('schema', '00000000-0000-0000-0000-000000000000');
            }}
          >
            Nuevo esquema
          </Button>
        }
      >
        <></>
      </SectionCard>
    </Page>
  );
}

export { TheorySchemasContributePage };
