'use client';

import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { setUserId } from 'firebase/analytics';
import { Auth, User, getAuth, onAuthStateChanged } from 'firebase/auth';

import { Splash } from '../components/core/Splash';
import { useFirebase } from './FirebaseContext';

export type AuthProps = {
  auth: Auth;
  user: User | null;
};

export const AuthContext = createContext<AuthProps | null>(null);

export const AuthContextProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const [auth, setAuth] = useState<Auth>();
  const [user, setUser] = useState<User | null>();
  const { firebaseApp, analytics } = useFirebase();

  useEffect(() => {
    const auth = getAuth(firebaseApp);

    setAuth(auth);
    setUserId(analytics, auth.currentUser?.uid || null);

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [analytics, firebaseApp]);

  if (auth === undefined || user === undefined) {
    return <Splash />;
  }

  return (
    <AuthContext.Provider value={{ auth, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthContextProvider');
  }

  return context;
};
