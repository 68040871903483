import { useEffect, useState } from 'react';

import { Questionnaire } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Loading } from '../../../../components/core/Loading';
import { useAptitudeQuestion } from '../../../../services/api/hooks/useAptitudeQuestion';
import { useAptitudeTest } from '../../../../services/api/hooks/useAptitudeTest';
import { useSidebarActions } from '../../../../state/sidebarStore';
import { QuestionType } from '../../../../types/Question.types';

import styles from './AptitudeQuestionnairePage.module.scss';

function AptitudeQuestionnairePage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setShowBtnToggle } = useSidebarActions();

  const { bookmarkAptitudeQuestion, unbookmarkAptitudeQuestion } =
    useAptitudeQuestion();

  const {
    getBookmarkedAptitudeTest,
    getRandomAptitudeTest,
    markAptitudeTest,
    getCompetitiveAptitudeTest,
  } = useAptitudeTest();

  useEffect(() => {
    setShowBtnToggle(false);
    return () => {
      setShowBtnToggle(true);
    };
  }, []);

  const { data: response, isLoading } = useQuery({
    queryKey: ['getAptitudeQuestionnairePage', searchParams],
    queryFn: () => {
      if (searchParams.get('competitivo')) {
        return getCompetitiveAptitudeTest(searchParams.get('competitivo')!);
      } else if (searchParams.get('guardadas')) {
        return getBookmarkedAptitudeTest();
      } else if (searchParams.get('preguntas') && searchParams.get('temas')) {
        return getRandomAptitudeTest({
          size: +searchParams.get('preguntas')!,
          categories: searchParams
            .get('temas')!
            .split(',')
            .map((item) => parseInt(item, 10)),
        });
      }
    },
  });

  useEffect(() => {
    if (response && !response.success) {
      navigate('/');
    }
  }, [response]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={styles['main']}>
      {response && response.data && (
        <Questionnaire
          className={styles['questionnaire']}
          timeInSeconds={response.data.timeInSeconds}
          questions={response.data.questions.map((question) => ({
            id: question.id,
            text: question.question,
            picture: question.questionPayload,
            options: question.answers,
            selectedOption: question.selectedAnswer,
            bookmarked: question.bookmarked,
          }))}
          onBookmark={async (id) => {
            const response = await bookmarkAptitudeQuestion(id);
            if (!response.success) {
              throw new Error("Couldn't bookmark question");
            }
          }}
          onUnbookmark={async (id) => {
            const response = await unbookmarkAptitudeQuestion(id);
            if (!response.success) {
              throw new Error("Couldn't unbookmark question");
            }
          }}
          isSubmitting={isSubmitting}
          onSubmit={async (questions: QuestionType[]) => {
            setIsSubmitting(true);

            const markResponse = await markAptitudeTest({
              id: response!.data!.id,
              timeInSeconds: response!.data!.timeInSeconds,
              questions: questions.map((question) => ({
                id: question.id,
                selectedAnswer: question.selectedOption,
              })),
            });

            if (markResponse.success && markResponse.data !== undefined) {
              navigate(
                `/psicotecnicos/simulacros/resultados/${markResponse.data.id}`,
              );
            }

            setIsSubmitting(false);
          }}
          onClose={() => {
            navigate(`/psicotecnicos/`);
          }}
        />
      )}
    </div>
  );
}

export { AptitudeQuestionnairePage };
