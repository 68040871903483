import { useEffect, useRef, useState } from 'react';

import {
  Button,
  Card,
  CardPopup,
  List,
  Tooltip,
  ValueChart,
} from '@sealfye/ui-components';
import { useMutation, useQuery } from '@tanstack/react-query';
import classnames from 'classnames';
import {
  IoCheckmarkSharp,
  IoCloseSharp,
  IoReaderOutline,
} from 'react-icons/io5';

import ContributeImg from '../../../../assets/images/contribute.svg';
import { Empty } from '../../../../components/core/Empty';
import { Loading } from '../../../../components/core/Loading';
import { EnglishQuestionAnswerViewer } from '../../../../components/features/english/EnglishQuestionAnswerViewer';
import { OffsetPagination } from '../../../../components/shared/offset-pagination/OffsetPagination';
import { Page } from '../../../../components/shared/page/Page';
import { SectionCard } from '../../../../components/shared/section-card/SectionCard';
import {
  DetailedEnglishQuestionDto,
  useEnglishQuestion,
} from '../../../../services/api/hooks/useEnglishQuestion';
import { OffsetPaginationResponse } from '../../../../services/api/hooks/usePageRequest';

import styles from './EnglishReviewQuestionsPage.module.scss';

function EnglishReviewQuestionsPage() {
  const [current, setCurrent] = useState<string | undefined>(undefined);

  const { getEnglishQuestions, verifyEnglishQuestion, rejectEnglishQuestion } =
    useEnglishQuestion();

  const [paginationResponse, setPaginationResponse] =
    useState<OffsetPaginationResponse>();
  const [questions, setQuestions] = useState<DetailedEnglishQuestionDto[]>([]);
  const [pageNumber, setPageNumber] = useState(1);

  const nodeRef = useRef<HTMLDivElement>(null);
  const tooltipNodeRef = useRef<HTMLDivElement>(null);

  const {
    data: response,
    isSuccess,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['getEnglishQuestions', pageNumber],
    queryFn: () => getEnglishQuestions([0], pageNumber),
  });

  const verifyMutation = useMutation({
    mutationFn: (id: string) => verifyEnglishQuestion(id),
    onSuccess: () => {
      refetch();
      tooltipNodeRef.current?.click();
      nodeRef.current?.click();
    },
  });

  const rejectMutation = useMutation({
    mutationFn: (id: string) => rejectEnglishQuestion(id),
    onSuccess: () => {
      refetch();
      tooltipNodeRef.current?.click();
      nodeRef.current?.click();
    },
  });

  useEffect(() => {
    if (response?.data !== undefined) {
      setPaginationResponse(response.data);
      setQuestions(response.data.data);
    }
  }, [response]);

  if (isLoading) {
    return <Loading />;
  }

  if (isSuccess && response?.data?.data.length === 0) {
    return <Empty title="No hay preguntas de inglés por revisar 🎉" />;
  }

  return (
    <>
      <Page title="Revisión de inglés">
        <SectionCard
          title="Preguntas de inglés por revisar"
          image={ContributeImg}
        >
          <ValueChart
            value={paginationResponse?.totalRecords.toString() || '0'}
          />
        </SectionCard>
        <Card title="Preguntas creadas por los contribuidores">
          <List
            items={questions.map((item) => ({
              label: {
                value: `${item.categoryName}`,
              },
              text:
                item.question.length > 150
                  ? item.question.substring(0, 150) + '…'
                  : item.question,
              info: item.question,
              icon: (
                <>
                  <Tooltip
                    key={item.id + 'verify'}
                    icon={
                      <IoCheckmarkSharp
                        className={classnames(
                          styles['icon'],
                          styles['icon--verify'],
                        )}
                      />
                    }
                    placement="left"
                    behavior="click"
                    refClose={tooltipNodeRef}
                  >
                    <div className={styles['tooltip-payload']}>
                      <span>¿Marcar como revisada?</span>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          verifyMutation.mutate(item.id);
                        }}
                      >
                        Confirmar
                      </Button>
                    </div>
                  </Tooltip>
                  <Tooltip
                    key={item.id + 'reject'}
                    icon={
                      <IoCloseSharp
                        className={classnames(
                          styles['icon'],
                          styles['icon--reject'],
                        )}
                      />
                    }
                    placement="left"
                    behavior="click"
                    refClose={tooltipNodeRef}
                  >
                    <div className={styles['tooltip-payload']}>
                      <span>¿Descartar?</span>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                          rejectMutation.mutate(item.id);
                        }}
                      >
                        Confirmar
                      </Button>
                    </div>
                  </Tooltip>
                  <IoReaderOutline
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setCurrent(item.id);
                    }}
                  />
                </>
              ),
            }))}
          />
          <OffsetPagination
            pagination={paginationResponse}
            onPageChange={(page) => setPageNumber(page)}
          />
        </Card>
      </Page>
      {current && (
        <CardPopup
          className={styles['card-popup']}
          refClose={nodeRef}
          closeable={false}
        >
          <EnglishQuestionAnswerViewer
            id={current}
            onClose={() => {
              refetch();
              nodeRef.current?.click();
              setCurrent(undefined);
            }}
          />
        </CardPopup>
      )}
    </>
  );
}

export { EnglishReviewQuestionsPage };
