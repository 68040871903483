import { PropsWithChildren, useEffect } from 'react';

import { logEvent } from 'firebase/analytics';
import {
  onCLS,
  onFCP,
  onFID,
  onINP,
  onLCP,
  onTTFB,
} from 'web-vitals/attribution';

import { useFirebase } from '../context/FirebaseContext';

function WebVitals({ children }: PropsWithChildren): JSX.Element {
  const { analytics } = useFirebase();

  useEffect(() => {
    if (analytics === undefined) {
      return;
    }

    onLCP((metric) => {
      logEvent(analytics, 'largest_contentful_paint', {
        value: metric.value,
        id: metric.id,
      });
    });

    onFID((metric) => {
      logEvent(analytics, 'first_input_delay', {
        value: metric.value,
        id: metric.id,
      });
    });

    onCLS((metric) => {
      logEvent(analytics, 'cumulative_layout_shift', {
        value: metric.value,
        id: metric.id,
      });
    });

    onFCP((metric) => {
      logEvent(analytics, 'first_contentful_paint', {
        value: metric.value,
        id: metric.id,
      });
    });

    onINP((metric) => {
      logEvent(analytics, 'first_input_delay', {
        value: metric.value,
        id: metric.id,
      });
    });

    onTTFB((metric) => {
      logEvent(analytics, 'time_to_first_byte', {
        value: metric.value,
        id: metric.id,
      });
    });
  }, [analytics]);

  return children as JSX.Element;
}

export { WebVitals };
