import { useEffect, useState } from 'react';

import { QuestionAnswer } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';

import { useProfile } from '../../../context/ProfileContext';
import {
  TheoryQuestionDto,
  useTheoryQuestion,
} from '../../../services/api/hooks/useTheoryQuestion';
import { useEditorActions } from '../../../state/editorStore';
import { useFeedbackActions } from '../../../state/feedbackStore';
import { BaseComponentProps } from '../../../types/base-component.types';

export type ContainerProps = BaseComponentProps & {
  id: string;
  onClose: () => void;
};

function TheoryQuestionAnswerViewer({ id, onClose }: ContainerProps) {
  const [question, setQuestion] = useState<TheoryQuestionDto>();
  const { openFeedback } = useFeedbackActions();
  const { openEditor } = useEditorActions();
  const { profile } = useProfile();

  const {
    getTheoryQuestion,
    reportTheoryQuestionError,
    bookmarkTheoryQuestion,
    unbookmarkTheoryQuestion,
  } = useTheoryQuestion();

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['getTheoryQuestion', id],
    queryFn: () => getTheoryQuestion(id),
  });

  useEffect(() => {
    if (response && response.data) {
      setQuestion(response.data);
    }
  }, [response]);

  if (isLoading || question === undefined) {
    return null;
  }

  return (
    <QuestionAnswer
      question={{
        id: question.id,
        text: question.question,
        caption: question.status === 4 ? '(Pregunta obsoleta)' : undefined,
        options: question.answers,
        subject:
          question.lesson > 2000
            ? `Examen oficial de ${question.lesson}`
            : `Tema ${question.lesson}`,
        selectedOption: question.selectedAnswer,
        bookmarked: question.bookmarked ?? false,
        correctOption: question.correctAnswer ?? 0,
        reason: question.reason,
        correctAnswerRate: question.correctAnswerRate ?? 0,
        emptyAnswerRate: question.emptyAnswerRate ?? 0,
        wrongAnswerRate: question.wrongAnswerRate ?? 0,
      }}
      background="main"
      onBookmark={async (id) => {
        const response = await bookmarkTheoryQuestion(id);

        if (response.success) {
          setQuestion((prev) => {
            if (prev !== undefined) {
              return {
                ...prev,
                bookmarked: true,
              };
            } else {
              return prev;
            }
          });
        }
      }}
      onUnbookmark={async (id) => {
        const response = await unbookmarkTheoryQuestion(id);

        if (response.success) {
          setQuestion((prev) => {
            if (prev !== undefined) {
              return {
                ...prev,
                bookmarked: false,
              };
            } else {
              return prev;
            }
          });
        }
      }}
      onFeedback={
        profile.subscription?.isActive && !profile.subscription?.isTrial
          ? (id) => {
              openFeedback(
                '¿Has visto algún error en esta pregunta?',
                async (feedback) => {
                  const response = await reportTheoryQuestionError(
                    id,
                    feedback,
                  );

                  if (!response.success) {
                    throw new Error('Error al enviar el feedback');
                  }
                },
              );
            }
          : undefined
      }
      onEdit={
        profile.role === 'admin' ||
        profile.role === 'contributor' ||
        profile.role === 'contributor:theory'
          ? () => {
              openEditor('theory', question.id, refetch, refetch);
            }
          : undefined
      }
      onClose={onClose}
    />
  );
}

export { TheoryQuestionAnswerViewer };
