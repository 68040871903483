import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface Message {
  variant?: 'success' | 'warning' | 'danger' | 'neutral';
  id: string;
  title: string;
  message: string;
  timestamp: number;
}

export interface ToasterState {
  messages: Message[];
  actions: {
    sendMessage: (
      title: string,
      message: string,
      variant?: 'success' | 'warning' | 'danger' | 'neutral',
    ) => void;
    deleteMessage: (id: string) => void;
  };
}

export const useToasterStore = create<ToasterState>()(
  devtools((set) => ({
    messages: [],
    actions: {
      sendMessage: (
        title: string,
        message: string,
        variant?: 'success' | 'warning' | 'danger' | 'neutral',
      ) =>
        set((state: ToasterState) => {
          const timestamp = Date.now();
          const lastItem = state.messages[state.messages.length - 1];

          if (
            lastItem &&
            lastItem.title === title &&
            lastItem.message === message &&
            timestamp - lastItem.timestamp < 1000
          ) {
            return state;
          }

          return {
            messages: [
              ...state.messages,
              {
                id: uuidv4(),
                title,
                message,
                variant,
                timestamp,
              },
            ],
          };
        }),
      deleteMessage: (id: string) =>
        set((state: ToasterState) => ({
          messages: state.messages.filter((message) => message.id !== id),
        })),
    },
  })),
);

export const useToaster = () => useToasterStore((state) => state.messages);

export const useToasterActions = () =>
  useToasterStore((state) => state.actions);
