import { useAxios } from '../../../context/AxiosContext';
import { TestResult } from '../questions';
import { ApiResponse } from '../response';

export interface StatsMarkDetailsDto {
  mean: number;
}

export interface StatsMarkDto {
  date: Date;
  theory: StatsMarkDetailsDto;
  orthography: StatsMarkDetailsDto;
  aptitude: StatsMarkDetailsDto;
  english: StatsMarkDetailsDto;
}

export interface StatsDto {
  averageCorrectAnswers: number;
  averageElapsedTime: number;
  averageEmptyAnswers: number;
  averageScore: number;
  averageWrongAnswers: number;
  maxScore: number;
  lastResults: TestResult[];
  totalCount: number;
  minScore: number;
}

export function useStats() {
  const { instance } = useAxios();

  async function getStatsMarks(): Promise<ApiResponse<StatsMarkDto[]>> {
    try {
      const response = await instance.get<StatsMarkDto[]>(`/stats/marks`);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  async function getTheoryStats(): Promise<ApiResponse<StatsDto>> {
    try {
      const response = await instance.get<StatsDto>(`/stats/theory`);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  async function getOrthographyStats(): Promise<ApiResponse<StatsDto>> {
    try {
      const response = await instance.get<StatsDto>(`/stats/orthography`);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  async function getAptitudeStats(): Promise<ApiResponse<StatsDto>> {
    try {
      const response = await instance.get<StatsDto>(`/stats/aptitude`);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  async function getEnglishStats(): Promise<ApiResponse<StatsDto>> {
    try {
      const response = await instance.get<StatsDto>(`/stats/english`);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  return {
    getStatsMarks,
    getTheoryStats,
    getOrthographyStats,
    getAptitudeStats,
    getEnglishStats,
  };
}
