import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useProfile } from '../../../context/ProfileContext';
import {
  TheoryLessonDto,
  useTheoryLesson,
} from '../../../services/api/hooks/useTheoryLesson';
import { useAppActions } from '../../../state/appStore';
import { BaseComponentProps } from '../../../types/base-component.types';
import { getLessonColorByName } from '../../../utils/colors';
import { SelectableListPopup } from '../../shared/selectable-list-popup/SelectableListPopup';

interface LessonTableItem extends TheoryLessonDto {
  active: boolean;
}

export type ContainerProps = BaseComponentProps & {
  onSelect: (id: number[]) => void;
  onClose: () => void;
};

const BLOCK_1 = 'Ciencias jurídicas';
const BLOCK_2 = 'Ciencias sociales';
const BLOCK_3 = 'Materias técnico-científicas';

function Lessons({ className, onSelect, onClose }: ContainerProps) {
  const { getTheoryLessons } = useTheoryLesson();
  const { profile } = useProfile();
  const { setShowGetPremiumPopup } = useAppActions();

  const [lessons, setLessons] = useState<LessonTableItem[]>([]);

  const { data: response, isLoading } = useQuery({
    queryKey: ['getTheoryLessons'],
    queryFn: () => getTheoryLessons(),
    gcTime: 5 * (60 * 1000), // 5 mins
  });

  useEffect(() => {
    if (response && response.data) {
      setLessons(
        response.data.map((lesson) => ({
          id: lesson.id,
          title: lesson.title,
          block: lesson.block,
          description: lesson.description,
          active: true,
        })),
      );
    }
  }, [response]);

  const handleSelect = (id: number, active: boolean) => {
    const array = [...lessons];
    array.forEach((item) => {
      if (item.id === id) {
        item.active = active;
      }
    });
    setLessons(array);
  };

  const handleSelectAll = (active: boolean) => {
    const array = [...lessons];
    array.forEach((item) => {
      item.active = active;
    });
    setLessons(array);
  };

  return (
    <SelectableListPopup
      className={className}
      title="Selecciona los temas para las preguntas"
      onClose={onClose}
      loading={isLoading}
      loadingItems={44}
      blocks={[
        {
          title: BLOCK_1,
          items:
            lessons
              .filter((lesson) => lesson.block === BLOCK_1)
              .map((lesson) => ({
                id: lesson.id,
                text: lesson.title,
                label: {
                  value: ('0' + lesson.id).slice(-2),
                  backgroundColor: getLessonColorByName(BLOCK_1),
                },
                info: lesson.description,
                active: lesson.active,
              })) ?? [],
        },
        {
          title: BLOCK_2,
          items:
            lessons
              .filter((lesson) => lesson.block === BLOCK_2)
              .map((lesson) => ({
                id: lesson.id,
                text: lesson.title,
                label: {
                  value: lesson.id,
                  backgroundColor: getLessonColorByName(BLOCK_2),
                },
                info: lesson.description,
                active: lesson.active,
              })) ?? [],
        },
        {
          title: BLOCK_3,
          items:
            lessons
              .filter((lesson) => lesson.block === BLOCK_3)
              .map((lesson) => ({
                id: lesson.id,
                text: lesson.title,
                label: {
                  value: lesson.id,
                  backgroundColor: getLessonColorByName(BLOCK_3),
                },
                info: lesson.description,
                active: lesson.active,
              })) ?? [],
        },
      ]}
      onSelect={(id: number, active: boolean) => {
        if (profile.subscription?.isActive && !profile.subscription?.isTrial) {
          handleSelect(id, active);
        } else {
          setShowGetPremiumPopup(true);
        }
      }}
      multiblock={{
        title: 'Todos los temas',
        onSelectAll: handleSelectAll,
      }}
      onContinue={() => {
        if (profile.subscription?.isActive) {
          onSelect(
            lessons
              .filter((lesson) => lesson.active)
              .map((lesson) => lesson.id),
          );
        } else {
          setShowGetPremiumPopup(true);
        }
      }}
    />
  );
}

export { Lessons };
