import { useEffect, useState } from 'react';

import { Questionnaire } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Loading } from '../../../../components/core/Loading';
import { useProfile } from '../../../../context/ProfileContext';
import { useTheoryOfficialExam } from '../../../../services/api/hooks/useTheoryOfficialExam';
import { useTheoryQuestion } from '../../../../services/api/hooks/useTheoryQuestion';
import { useTheoryTest } from '../../../../services/api/hooks/useTheoryTest';
import { useSidebarActions } from '../../../../state/sidebarStore';
import { QuestionType } from '../../../../types/Question.types';

import styles from './TheoryQuestionnairePage.module.scss';

function TheoryQuestionnairePage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { profile } = useProfile();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setShowBtnToggle } = useSidebarActions();

  const { bookmarkTheoryQuestion, unbookmarkTheoryQuestion } =
    useTheoryQuestion();

  const {
    getCurrentCompetitiveTheoryTest,
    getBookmarkedTheoryTest,
    getRandomTheoryTest,
    markTheoryTest,
    getCompetitiveTheoryTest,
  } = useTheoryTest();

  const { getOfficialExam } = useTheoryOfficialExam();

  useEffect(() => {
    setShowBtnToggle(false);
    return () => {
      setShowBtnToggle(true);
    };
  }, []);

  const { data: response, isLoading } = useQuery({
    queryKey: ['getTheoryQuestionnairePage', searchParams],
    queryFn: () => {
      if (searchParams.get('examen')) {
        return getOfficialExam(searchParams.get('examen')!);
      } else if (searchParams.get('competitivo')) {
        return getCompetitiveTheoryTest(searchParams.get('competitivo')!);
      } else if (searchParams.get('mensual')) {
        return getCurrentCompetitiveTheoryTest();
      } else if (searchParams.get('guardadas')) {
        return getBookmarkedTheoryTest();
      } else if (searchParams.get('preguntas') && searchParams.get('temas')) {
        return getRandomTheoryTest({
          size: +searchParams.get('preguntas')!,
          lessons: searchParams
            .get('temas')!
            .split(',')
            .map((item) => parseInt(item, 10)),
        });
      }
    },
  });

  useEffect(() => {
    if (response && !response.success) {
      navigate('/');
    }

    if (
      response &&
      !profile.subscription?.isActive &&
      response?.data &&
      !response.data.free
    ) {
      navigate('/');
    }
  }, [response]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={styles['main']}>
      {response && response.data && (
        <Questionnaire
          className={styles['questionnaire']}
          timeInSeconds={response.data.timeInSeconds}
          questions={response.data.questions.map((question) => ({
            id: question.id,
            text: question.question,
            options: question.answers,
            selectedOption: question.selectedAnswer,
            bookmarked: question.bookmarked,
          }))}
          onBookmark={async (id) => {
            const response = await bookmarkTheoryQuestion(id);
            if (!response.success) {
              throw new Error("Couldn't bookmark question");
            }
          }}
          onUnbookmark={async (id) => {
            const response = await unbookmarkTheoryQuestion(id);
            if (!response.success) {
              throw new Error("Couldn't unbookmark question");
            }
          }}
          isSubmitting={isSubmitting}
          onSubmit={async (questions: QuestionType[]) => {
            setIsSubmitting(true);

            const markResponse = await markTheoryTest({
              id: response!.data!.id,
              timeInSeconds: response!.data!.timeInSeconds,
              questions: questions.map((question) => ({
                id: question.id,
                selectedAnswer: question.selectedOption,
              })),
            });

            if (markResponse.success && markResponse.data !== undefined) {
              navigate(`/teoria/simulacros/resultados/${markResponse.data.id}`);
            }

            setIsSubmitting(false);
          }}
          onClose={() => {
            navigate(`/teoria/`);
          }}
        />
      )}
    </div>
  );
}

export { TheoryQuestionnairePage };
