import { useEffect, useRef, useState } from 'react';

import {
  Badge,
  Button,
  Card,
  CardPopup,
  List,
  ListSkeleton,
  ValueChart,
} from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import { IoReaderOutline } from 'react-icons/io5';

import ContributeImg from '../../../assets/images/contribute.svg';
import { TheoryQuestionAnswerViewer } from '../../../components/features/theory/TheoryQuestionAnswerViewer';
import { OffsetPagination } from '../../../components/shared/offset-pagination/OffsetPagination';
import { Page } from '../../../components/shared/page/Page';
import { SectionCard } from '../../../components/shared/section-card/SectionCard';
import { OffsetPaginationResponse } from '../../../services/api/hooks/usePageRequest';
import { DetailedTheoryQuestionDto } from '../../../services/api/hooks/useTheoryQuestion';
import { useUsers } from '../../../services/api/hooks/useUsers';
import { useEditorActions } from '../../../state/editorStore';
import { getMonthName } from '../../../utils/date';

import styles from './TheoryContributePage.module.scss';

function TheoryContributePage() {
  const { openEditor } = useEditorActions();

  const [current, setCurrent] = useState<string | undefined>(undefined);

  const { getCreatedTheoryQuestions } = useUsers();

  const [paginationResponse, setPaginationResponse] =
    useState<OffsetPaginationResponse>();
  const [questions, setQuestions] = useState<DetailedTheoryQuestionDto[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const nodeRef = useRef<HTMLDivElement>(null);

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['getCreatedTheoryQuestions', pageNumber],
    queryFn: () => getCreatedTheoryQuestions(pageNumber),
  });

  useEffect(() => {
    if (response?.data !== undefined) {
      setPaginationResponse(response.data);
      setQuestions(response.data.data);
    }
  }, [response]);

  return (
    <>
      <Page title="Preguntas de teoría">
        <SectionCard
          title="Crea nuevas preguntas de teoría"
          image={ContributeImg}
          button={
            <Button
              variant="outline-primary"
              onClick={() => {
                openEditor(
                  'theory',
                  '00000000-0000-0000-0000-000000000000',
                  refetch,
                );
              }}
            >
              Nueva pregunta
            </Button>
          }
        >
          <ValueChart
            value={paginationResponse?.totalRecords.toString() || '0'}
          />
        </SectionCard>
        <Card title="Listado de preguntas">
          {isLoading ? (
            <ListSkeleton items={15} />
          ) : (
            <>
              <List
                items={questions.map((item) => ({
                  label:
                    item.lesson < 2000
                      ? {
                          value: `Tema ${item.lesson}`,
                        }
                      : undefined,
                  text:
                    item.question.length > 150
                      ? item.question.substring(0, 150) + '…'
                      : item.question,
                  info: item.question,
                  tag:
                    item.competitiveTest && item.competitiveTestMonth ? (
                      <Badge bg="warning">
                        {getMonthName(item.competitiveTestMonth - 1) +
                          ' ' +
                          item.competitiveTestYear}
                      </Badge>
                    ) : item.officialExam ? (
                      <Badge bg="featured">
                        Examen oficial {item.officialExamYear}
                      </Badge>
                    ) : undefined,
                  icon: (
                    <IoReaderOutline
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setCurrent(item.id);
                      }}
                    />
                  ),
                }))}
              />
              <OffsetPagination
                pagination={paginationResponse}
                onPageChange={(page) => setPageNumber(page)}
              />
            </>
          )}
        </Card>
      </Page>
      {current && (
        <CardPopup
          className={styles['card-popup']}
          refClose={nodeRef}
          closeable={false}
        >
          <TheoryQuestionAnswerViewer
            id={current}
            onClose={() => {
              refetch();
              nodeRef.current?.click();
              setCurrent(undefined);
            }}
          />
        </CardPopup>
      )}
    </>
  );
}

export { TheoryContributePage };
