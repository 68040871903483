import { useRef } from 'react';

import {
  Button,
  CardPopup,
  FormFeedback,
  FormGroup,
  FormLabel,
  Textarea,
} from '@sealfye/ui-components';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useFeedback, useFeedbackActions } from '../../../state/feedbackStore';
import { BaseComponentProps } from '../../../types/base-component.types';

import styles from './FeedbackPopup.module.scss';

function FeedbackPopup({
  className,
  testId = 'ui-feedback-popup',
}: BaseComponentProps) {
  const nodeRef = useRef<HTMLDivElement>(null);
  const feedback = useFeedback();
  const { closeFeedback } = useFeedbackActions();

  if (feedback === undefined) {
    return null;
  }

  return (
    <CardPopup
      className={className}
      refClose={nodeRef}
      onClose={closeFeedback}
      animation="bounceInUp"
      testId={testId}
    >
      <div className={styles['icon']}>{<span>🐛</span>}</div>
      <Formik
        enableReinitialize
        validationSchema={Yup.object().shape({
          description: Yup.string()
            .max(2000, 'La descripción debe contener menos de 2000 caracteres.')
            .required('La descripción no puede estar vacía.'),
        })}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          setSubmitting(true);

          try {
            feedback.onFeedback(values.description);
            nodeRef.current?.click();
            resetForm();

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            throw new Error(error);
          }

          setSubmitting(false);
        }}
        initialValues={{
          description: '',
        }}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit,
        }) => (
          <Form className={styles['form']} noValidate onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>
                {feedback.title || '¿Has encontrado algún error?'}
              </FormLabel>
              <Textarea
                id="description"
                value={values.description}
                rows={7}
                onChange={handleChange}
                error={touched.description && !!errors.description}
              />
              {touched.description && errors.description && (
                <FormFeedback variant="danger">
                  {errors.description}
                </FormFeedback>
              )}
              <FormFeedback>
                Escríbenos qué error has encontrado, con esto nos ayudas a
                mejorar cada día. Gracias compañerx! 💙
              </FormFeedback>
            </FormGroup>
            <Button
              className={styles['button']}
              loading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
            >
              Enviar feedback
            </Button>
          </Form>
        )}
      </Formik>
    </CardPopup>
  );
}

export { FeedbackPopup };
