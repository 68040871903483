import { Loading } from '@sealfye/ui-components';
import classnames from 'classnames';

import { PageProps } from './Page.types';

import styles from './Page.module.scss';

function Page({ className, title, children, loading }: PageProps) {
  return (
    <div className={styles['wrapper']}>
      <div className={classnames(styles['content'], className)}>
        {title && <h1 className={styles['title']}>{title}</h1>}
        {loading ? (
          <div className={styles['loading']}>
            <Loading />
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
}

export { Page };
