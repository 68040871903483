import { useEffect, useState } from 'react';

import {
  Button,
  FormFeedback,
  FormGroup,
  FormLabel,
  Input,
  Loading,
} from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import {
  Form,
  Formik,
  FormikErrors,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import * as Yup from 'yup';

import {
  SampleMarkRankingDto,
  useMark,
} from '../../../../services/api/hooks/useMark';
import { MarksRanking } from '../../../shared/marks-ranking/MarksRanking';

import styles from './AptitudeSampleMarks.module.scss';

interface FormValues {
  mark: number;
}

function AptitudeSampleMarks() {
  const { getAptitudeSampleMarkRanking, addAptitudeSampleMark } = useMark();

  const [ranking, setRanking] = useState<SampleMarkRankingDto | undefined>(
    undefined,
  );

  const { data: response, isLoading } = useQuery({
    queryKey: ['getAptitudeSampleMarkRanking'],
    queryFn: () => getAptitudeSampleMarkRanking(),
  });

  useEffect(() => {
    if (response && response.data !== undefined) {
      setRanking(response.data);
    }
  }, [response]);

  return (
    <div className={styles['wrapper']}>
      <div className={styles['icon']}>{<span>📑</span>}</div>
      {isLoading && ranking === undefined ? (
        <div className={styles['loading']}>
          <Loading />
        </div>
      ) : (
        <>
          {ranking !== undefined ? (
            <div className={styles['ranking']}>
              <div className={styles['ranking__title']}>
                <h2>Psicotécnico</h2>
                <span>
                  Tu nota es: <strong>{ranking.mark}</strong>
                </span>
                <span>
                  Estás en la posición: <strong>{ranking.position}</strong> de{' '}
                  <strong>{ranking.total}</strong>
                </span>
              </div>
              <MarksRanking
                columns={['Usuario', 'Nota']}
                rows={ranking.userScores?.map((userScore) => ({
                  cells: [
                    userScore.username.length > 20
                      ? userScore.username.substring(0, 20) + '…'
                      : userScore.username,
                    userScore.mark.toString(),
                  ],
                }))}
              />
            </div>
          ) : (
            <Formik
              enableReinitialize
              validate={async (values: FormValues) => {
                const errors: FormikErrors<FormValues> = {};

                const validationSchema = Yup.object().shape({
                  mark: Yup.number()
                    .typeError('Este valor debe ser numérico.')
                    .min(0, 'Este valor no puede ser negativo.')
                    .max(10, 'Este valor no puede ser mayor que diez.'),
                });

                try {
                  validateYupSchema<FormValues>(values, validationSchema, true);
                } catch (err) {
                  return yupToFormErrors(err);
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);

                await addAptitudeSampleMark({
                  mark: values.mark,
                });

                const response = await getAptitudeSampleMarkRanking();

                if (response.success) {
                  setRanking(response.data);
                }

                setSubmitting(false);
              }}
              initialValues={{
                mark: 0,
              }}
            >
              {({
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleSubmit,
              }) => (
                <Form
                  className={styles['form']}
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <FormGroup>
                    <FormLabel>
                      Introduce la nota estimada del examen psicotécnico
                    </FormLabel>
                    <Input
                      id="mark"
                      value={values.mark}
                      onChange={handleChange}
                      error={touched.mark && !!errors.mark}
                    />
                    {touched.mark && errors.mark && (
                      <FormFeedback variant="danger">
                        {errors.mark}
                      </FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormFeedback>
                      Introduce la nota que hayas calculado con tu plantilla de
                      preguntas del examen oficial psicotécnico de Escala Básica
                      (promoción XL) de la convocatoria de 2024.
                    </FormFeedback>
                    <FormFeedback>
                      Puedes utilizar nuestra calculadora para obtener la nota.
                      Recuerda separar los decimales con un punto.
                    </FormFeedback>
                  </FormGroup>
                  <div className={styles['footer']}>
                    <Button
                      className={styles['button']}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      type="submit"
                    >
                      Enviar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </>
      )}
    </div>
  );
}

export { AptitudeSampleMarks };
