// sort-imports-ignore
import { memo, useState } from 'react';

import classnames from 'classnames';
import { convertToHTML } from 'draft-convert';
import {
  ContentState,
  RawDraftContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import { Editor as ReactDraftEditor } from 'react-draft-wysiwyg';

import { EditorProps } from './Editor.types';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './Editor.module.scss';

function InnerEditor({
  className,
  content,
  disabled = false,
  error = false,
  onContentChange,
  testId = 'ui-editor',
}: EditorProps) {
  const blocksFromHTML = convertFromHTML(content ?? '');
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
  const [contentState, setContentState] = useState<RawDraftContentState>(
    convertToRaw(state),
  );

  const onChange = (editorState: RawDraftContentState) => {
    setContentState(editorState);

    if (editorState.blocks.length === 1 && editorState.blocks[0].text === '') {
      onContentChange('');
    } else {
      const htmlContent = convertToHTML(convertFromRaw(editorState));
      onContentChange(htmlContent.toString());
    }
  };

  return (
    <div className={className} data-test-id={testId}>
      <ReactDraftEditor
        defaultContentState={contentState}
        onContentStateChange={onChange}
        readOnly={disabled}
        handlePastedText={() => false}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'list', 'history'],
          inline: {
            inDropdown: false,
            options: [
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'monospace',
            ],
          },
          blockType: {
            inDropdown: true,
            options: [
              'Normal',
              'H1',
              'H2',
              'H3',
              'H4',
              'H5',
              'H6',
              'Blockquote',
              'Code',
            ],
          },
          fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24],
          },
          list: {
            inDropdown: false,
          },
          link: {
            // TODO: Link not converted to HTML
            inDropdown: false,
            defaultTargetOption: '_blank',
          },
          history: {
            inDropdown: false,
          },
        }}
        wrapperClassName={classnames(styles['wrapper'], {
          [styles['wrapper--disabled']]: disabled,
          [styles['wrapper--error']]: error,
        })}
        editorClassName={classnames(styles['editor'], {
          [styles['editor--disabled']]: disabled,
          [styles['editor--error']]: error,
        })}
        toolbarClassName={classnames(styles['toolbar'], {
          [styles['toolbar--disabled']]: disabled,
          [styles['toolbar--error']]: error,
        })}
      />
    </div>
  );
}

function propsAreEqual(prev: EditorProps, next: EditorProps) {
  return (
    prev.content === next.content &&
    prev.disabled === next.disabled &&
    prev.error === next.error
  );
}

export const Editor = memo(InnerEditor, propsAreEqual);
