import { PropsWithChildren, useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';

import { useConfiguration } from '../context/ConfigurationContext';

function Monitoring({ children }: PropsWithChildren): JSX.Element {
  const { configuration } = useConfiguration();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (configuration === undefined || initialized) {
      return;
    }

    Sentry.init({
      dsn: configuration.sentry?.dsn,
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [
            configuration.app?.appUrl ?? '',
            configuration.app?.apiUrl ?? '',
          ],
        }),
      ],
      environment: configuration.environment,
      debug: configuration.environment === 'local',
    });

    setInitialized(true);
  }, [configuration, initialized]);

  return children as JSX.Element;
}

export { Monitoring };
