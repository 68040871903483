import { Loading as Icon } from '@sealfye/ui-components';
import classnames from 'classnames';

import { BaseComponentProps } from '../../types/base-component.types';

import styles from './Loading.module.scss';

function Loading({ className }: BaseComponentProps) {
  return (
    <div className={classnames(styles['main'], className)}>
      <Icon />
    </div>
  );
}

export { Loading };
