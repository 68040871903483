import { Accordion, AccordionSkeleton, Badge } from '@sealfye/ui-components';
import { IoEaselOutline } from 'react-icons/io5';

import { useProfile } from '../../../context/ProfileContext';
import { TheorySchemaSummaryDto } from '../../../services/api/hooks/useTheorySchema';
import { useAppActions } from '../../../state/appStore';
import { BaseComponentProps } from '../../../types/base-component.types';
import { getLessonColor } from '../../../utils/colors';
import { dateDiffInDays } from '../../../utils/date';

import styles from './SchemasTable.module.scss';

type ContainerProps = BaseComponentProps & {
  items: TheorySchemaSummaryDto[];
  loading?: boolean;
  onSelect: (id: string) => void;
};

const DAYS_TO_SHOW_BADGE = 5;

function SchemasTable({ className, items, loading, onSelect }: ContainerProps) {
  const { profile } = useProfile();
  const { setShowGetPremiumPopup } = useAppActions();

  const date = new Date();

  return (
    <div className={className}>
      <div className={styles['title']}>Ciencias jurídicas</div>
      {loading ? (
        <AccordionSkeleton items={26} />
      ) : (
        <Accordion
          items={items
            .filter((item) => item.lesson <= 26)
            .map((item) => ({
              label: {
                value: ('0' + item.lesson).slice(-2),
                backgroundColor: getLessonColor(item.lesson),
              },
              tag:
                item.schemas.filter(
                  (schema) =>
                    dateDiffInDays(new Date(schema.creationDate), date) <
                    DAYS_TO_SHOW_BADGE,
                ).length > 0 ? (
                  <Badge bg="success">Nuevos</Badge>
                ) : item.schemas.filter(
                    (schema) =>
                      dateDiffInDays(new Date(schema.lastUpdate), date) <
                      DAYS_TO_SHOW_BADGE,
                  ).length > 0 ? (
                  <Badge bg="featured">Actualizados</Badge>
                ) : null,
              text: item.title,
              items: item.schemas.map((schema) => ({
                text: schema.description,
                tag:
                  dateDiffInDays(new Date(schema.creationDate), date) <
                  DAYS_TO_SHOW_BADGE ? (
                    <Badge bg="success">Nuevo</Badge>
                  ) : dateDiffInDays(new Date(schema.lastUpdate), date) <
                    DAYS_TO_SHOW_BADGE ? (
                    <Badge bg="featured">Actualizado</Badge>
                  ) : null,
                icon:
                  (profile.subscription?.isActive &&
                    !profile.subscription?.isTrial) ||
                  (profile.subscription?.isTrial && schema.trial) ? (
                    <IoEaselOutline
                      style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        minWidth: '20px',
                        margin: '0px',
                      }}
                      onClick={() => onSelect(schema.id)}
                    />
                  ) : (
                    <IoEaselOutline
                      style={{
                        opacity: '0.3',
                        cursor: 'pointer',
                        fontSize: '20px',
                        minWidth: '20px',
                        margin: '0px',
                      }}
                      onClick={() => {
                        setShowGetPremiumPopup(true);
                      }}
                    />
                  ),
              })),
            }))}
        />
      )}
      <div className={styles['title']}>Ciencias sociales</div>
      {loading ? (
        <AccordionSkeleton items={11} />
      ) : (
        <Accordion
          items={items
            .filter((item) => item.lesson > 26 && item.lesson <= 37)
            .map((item) => ({
              label: {
                value: item.lesson,
                backgroundColor: getLessonColor(item.lesson),
              },
              tag:
                item.schemas.filter(
                  (schema) =>
                    dateDiffInDays(new Date(schema.creationDate), date) <
                    DAYS_TO_SHOW_BADGE,
                ).length > 0 ? (
                  <Badge bg="success">Nuevos</Badge>
                ) : item.schemas.filter(
                    (schema) =>
                      dateDiffInDays(new Date(schema.lastUpdate), date) <
                      DAYS_TO_SHOW_BADGE,
                  ).length > 0 ? (
                  <Badge bg="featured">Actualizados</Badge>
                ) : null,
              text: item.title,
              items: item.schemas.map((schema) => ({
                text: schema.description,
                tag:
                  dateDiffInDays(new Date(schema.creationDate), date) <
                  DAYS_TO_SHOW_BADGE ? (
                    <Badge bg="success">Nuevo</Badge>
                  ) : dateDiffInDays(new Date(schema.lastUpdate), date) <
                    DAYS_TO_SHOW_BADGE ? (
                    <Badge bg="featured">Actualizado</Badge>
                  ) : null,
                icon:
                  (profile.subscription?.isActive &&
                    !profile.subscription?.isTrial) ||
                  (profile.subscription?.isTrial && schema.trial) ? (
                    <IoEaselOutline
                      style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        minWidth: '20px',
                        margin: '0px',
                      }}
                      onClick={() => onSelect(schema.id)}
                    />
                  ) : (
                    <IoEaselOutline
                      style={{
                        opacity: '0.3',
                        cursor: 'pointer',
                        fontSize: '20px',
                        minWidth: '20px',
                        margin: '0px',
                      }}
                      onClick={() => {
                        setShowGetPremiumPopup(true);
                      }}
                    />
                  ),
              })),
            }))}
        />
      )}
      <div className={styles['title']}>Materias técnico-científicas</div>
      {loading ? (
        <AccordionSkeleton items={8} />
      ) : (
        <Accordion
          items={items
            .filter((item) => item.lesson > 37 && item.lesson <= 45)
            .map((item) => ({
              label: {
                value: item.lesson,
                backgroundColor: getLessonColor(item.lesson),
              },
              tag:
                item.schemas.filter(
                  (schema) =>
                    dateDiffInDays(new Date(schema.creationDate), date) <
                    DAYS_TO_SHOW_BADGE,
                ).length > 0 ? (
                  <Badge bg="success">Nuevos</Badge>
                ) : item.schemas.filter(
                    (schema) =>
                      dateDiffInDays(new Date(schema.lastUpdate), date) <
                      DAYS_TO_SHOW_BADGE,
                  ).length > 0 ? (
                  <Badge bg="featured">Actualizados</Badge>
                ) : null,
              text: item.title,
              items: item.schemas.map((schema) => ({
                text: schema.description,
                tag:
                  dateDiffInDays(new Date(schema.creationDate), date) <
                  DAYS_TO_SHOW_BADGE ? (
                    <Badge bg="success">Nuevo</Badge>
                  ) : dateDiffInDays(new Date(schema.lastUpdate), date) <
                    DAYS_TO_SHOW_BADGE ? (
                    <Badge bg="featured">Actualizado</Badge>
                  ) : null,
                icon:
                  (profile.subscription?.isActive &&
                    !profile.subscription?.isTrial) ||
                  (profile.subscription?.isTrial && schema.trial) ? (
                    <IoEaselOutline
                      style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        minWidth: '20px',
                        margin: '0px',
                      }}
                      onClick={() => onSelect(schema.id)}
                    />
                  ) : (
                    <IoEaselOutline
                      style={{
                        opacity: '0.3',
                        cursor: 'pointer',
                        fontSize: '20px',
                        minWidth: '20px',
                        margin: '0px',
                      }}
                      onClick={() => {
                        setShowGetPremiumPopup(true);
                      }}
                    />
                  ),
              })),
            }))}
        />
      )}
    </div>
  );
}

export { SchemasTable };
