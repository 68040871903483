export function convertToFileName(text: string) {
  let formattedText = text.toLowerCase();
  formattedText = formattedText
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  return formattedText.replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');
}

export function getImageExtension(base64Image: string) {
  const match = base64Image.match(/^data:image\/(\w+);base64,/);
  if (!match) {
    throw new Error('Base64 image is invalid or not supported');
  }

  const extension = match[1];
  return extension;
}

export function dataURItoBlob(dataURI: string) {
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}
