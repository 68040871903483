/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAxios } from '../../../context/AxiosContext';
import { useToasterActions } from '../../../state/toasterStore';
import { QuestionDto, TestDto, TestResult, TestResultDto } from '../questions';
import { ApiResponse } from '../response';
import { PagedResponse } from './usePageRequest';
import { TheoryQuestionDto } from './useTheoryQuestion';

export interface TheoryTestRequest {
  size: number;
  lessons: number[];
}

export interface UserScoreDto {
  username: string;
  school: string;
  mark: number;
  elapsedTime: number;
}

export interface RankingDto {
  mark: number;
  averageScore: number;
  position: number;
  total: number;
  elapsedTime: number;
  userScores: UserScoreDto[];
}

export interface CompetitiveTestMetadataDto {
  id: string;
  date: Date;
  averageScore: number;
}

export function useTheoryTest() {
  const { instance } = useAxios();
  const { sendMessage } = useToasterActions();

  async function getRandomTheoryTest(
    request: TheoryTestRequest,
  ): Promise<ApiResponse<TestDto<TheoryQuestionDto>>> {
    try {
      const response = await instance.post<TestDto<TheoryQuestionDto>>(
        `/theory/tests/random`,
        request,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  async function getCurrentCompetitiveTheoryTest(): Promise<
    ApiResponse<TestDto<TheoryQuestionDto>>
  > {
    try {
      const response = await instance.get<TestDto<TheoryQuestionDto>>(
        `/theory/tests/competitive`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  async function getCompetitiveTheoryTest(
    id: string,
  ): Promise<ApiResponse<TestDto<TheoryQuestionDto>>> {
    try {
      const response = await instance.get<TestDto<TheoryQuestionDto>>(
        `/theory/tests/competitive/${id}`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  async function getBookmarkedTheoryTest(): Promise<
    ApiResponse<TestDto<TheoryQuestionDto>>
  > {
    try {
      const response = await instance.get<TestDto<TheoryQuestionDto>>(
        `/theory/tests/bookmarked`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  async function markTheoryTest(
    test: TestDto<QuestionDto>,
  ): Promise<ApiResponse<TestResultDto<TheoryQuestionDto>>> {
    try {
      const response = await instance.post(
        `/theory/tests/${test.id}/mark`,
        test,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  async function getCurrentRanking(): Promise<ApiResponse<RankingDto>> {
    try {
      const response = await instance.get<RankingDto>(`/theory/tests/ranking`);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  async function getCompetitiveTestsList(
    pageNumber = 1,
    pageSize = 20,
  ): Promise<ApiResponse<PagedResponse<CompetitiveTestMetadataDto>>> {
    const config = {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    };

    try {
      const response = await instance.get<
        PagedResponse<CompetitiveTestMetadataDto>
      >(`/theory/tests/competitive/list`, config);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  async function getTheoryTestResults(
    pageNumber = 1,
    pageSize = 10,
  ): Promise<ApiResponse<PagedResponse<TestResult>>> {
    const config = {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    };

    try {
      const response = await instance.get<PagedResponse<TestResult>>(
        `/theory/tests/results`,
        config,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  async function getTheoryTestResult(
    id: string,
  ): Promise<ApiResponse<TestResultDto<TheoryQuestionDto>>> {
    try {
      const response = await instance.get<TestResultDto<TheoryQuestionDto>>(
        `/theory/tests/results/${id}`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      if (error.response.status === 404) {
        sendMessage(
          'Respuestas no disponibles',
          'El contenido de los simulacros más antiguos no está disponible 😔',
          'warning',
        );

        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  return {
    getCurrentCompetitiveTheoryTest,
    getRandomTheoryTest,
    getCompetitiveTheoryTest,
    getBookmarkedTheoryTest,
    markTheoryTest,
    getCurrentRanking,
    getCompetitiveTestsList,
    getTheoryTestResults,
    getTheoryTestResult,
  };
}
