import { useEffect } from 'react';

import classnames from 'classnames';

import { useCountDownTimer } from '../../../../../hooks/useCountDownTimer';
import { BaseComponentProps } from '../../../../../types/base-component.types';

import styles from './Countdown.module.scss';

export type ContainerProps = BaseComponentProps & {
  index: number;
  seconds: number;
  onTimeout: () => void;
};

function Countdown({
  className,
  index,
  seconds,
  onTimeout,
  testId = 'ui-challenge-countdown',
}: ContainerProps) {
  const [, , _s] = useCountDownTimer(0, 0, seconds);

  useEffect(() => {
    if (_s === 0) {
      onTimeout();
    }
  }, [_s]);

  return (
    <div
      className={classnames(styles['main'], className)}
      data-test-id={testId}
    >
      <span className={styles['index']}>Pregunta {index}</span>
      <span className={styles['timer']}>{_s}</span>
    </div>
  );
}

export { Countdown };
