export function getLessonColorByName(block: string): string {
  switch (true) {
    case block === 'Ciencias jurídicas':
      return '#fabc99';
    case block === 'Ciencias sociales':
      return '#8AC8E0';
    case block === 'Materias técnico-científicas':
      return '#9DA5CD';
    default:
      return '#000000';
  }
}

export function getLessonColor(id: number): string {
  switch (true) {
    case id <= 26:
      return '#fabc99';
    case id <= 37:
      return '#8AC8E0';
    case id <= 45:
      return '#9DA5CD';
    default:
      return '#000000';
  }
}

export function getStatusColor(id: string): string {
  switch (true) {
    case id === 'new':
      return 'var(--color-primary-hover)';
    case id === 'verified':
      return 'var(--color-success)';
    case id === 'rejected':
      return 'var(--color-danger)';
    case id === 'hidden':
      return 'var(--color-neutral)';
    default:
      return 'var(--color-primary)';
  }
}
