import { PropsWithChildren } from 'react';

import { useAuth } from '../context/AuthContext';

function Auth({
  children,
  fallback,
}: PropsWithChildren<{ fallback: JSX.Element }>): JSX.Element {
  const { user } = useAuth();

  if (user !== null) {
    return children as JSX.Element;
  }

  return fallback;
}

export { Auth };
