import { useEffect, useState } from 'react';

import { SimpleQuestionnaire } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Loading } from '../../../../components/core/Loading';
import { useProfile } from '../../../../context/ProfileContext';
import { useTheoryQuestion } from '../../../../services/api/hooks/useTheoryQuestion';
import { useEditorActions } from '../../../../state/editorStore';
import { useFeedbackActions } from '../../../../state/feedbackStore';
import { useSidebarActions } from '../../../../state/sidebarStore';
import { QuestionAnswerType } from '../../../../types/Question.types';

import styles from './TheorySimpleQuestionnairePage.module.scss';

function TheorySimpleQuestionnairePage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setShowBtnToggle } = useSidebarActions();
  const { openFeedback } = useFeedbackActions();
  const { openEditor } = useEditorActions();
  const [questions, setQuestions] = useState<QuestionAnswerType[]>([]);
  const { profile } = useProfile();

  const {
    bookmarkTheoryQuestion,
    unbookmarkTheoryQuestion,
    getRandomTheoryQuestions,
    reportTheoryQuestionError,
  } = useTheoryQuestion();

  useEffect(() => {
    setShowBtnToggle(false);
    return () => {
      setShowBtnToggle(true);
    };
  }, []);

  const {
    data: response,
    isLoading,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['getTheorySimpleQuestionnairePage', searchParams],
    queryFn: () => {
      if (searchParams.get('temas')) {
        return getRandomTheoryQuestions(
          searchParams
            .get('temas')!
            .split(',')
            .map((item) => parseInt(item, 10)),
        );
      } else {
        return getRandomTheoryQuestions();
      }
    },
  });

  useEffect(() => {
    if (response?.data) {
      setQuestions((prev) => {
        if (response.data) {
          return [
            ...prev,
            {
              id: response.data.id,
              text: response.data.question,
              subject: 'Tema ' + response.data.lesson?.toString(),
              options: response.data.answers,
              selectedOption: 0,
              bookmarked: response.data.bookmarked ?? false,
              correctOption: response.data.correctAnswer ?? 0,
              reason: response.data.reason,
              correctAnswerRate: response.data.correctAnswerRate ?? 0,
              emptyAnswerRate: response.data.emptyAnswerRate ?? 0,
              wrongAnswerRate: response.data.wrongAnswerRate ?? 0,
            },
          ];
        } else {
          return prev;
        }
      });
    }
  }, [response]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={styles['main']}>
      {questions.length > 0 && (
        <SimpleQuestionnaire
          className={styles['questionnaire']}
          questions={questions}
          loading={isFetching}
          onBookmark={async (id) => {
            const response = await bookmarkTheoryQuestion(id);
            if (!response.success) {
              throw new Error("Couldn't bookmark question");
            }
          }}
          onUnbookmark={async (id) => {
            const response = await unbookmarkTheoryQuestion(id);
            if (!response.success) {
              throw new Error("Couldn't unbookmark question");
            }
          }}
          onFeedback={(id) => {
            openFeedback(
              '¿Has visto algún error en esta pregunta?',
              async (feedback) => {
                const response = await reportTheoryQuestionError(id, feedback);

                if (!response.success) {
                  throw new Error('Error al enviar el feedback');
                }
              },
            );
          }}
          onEdit={
            profile.role === 'admin' ||
            profile.role === 'contributor' ||
            profile.role === 'contributor:theory'
              ? (id) => {
                  openEditor('theory', id, refetch, refetch);
                }
              : undefined
          }
          onNext={refetch}
          onClose={() => {
            navigate('/teoria');
          }}
        />
      )}
    </div>
  );
}

export { TheorySimpleQuestionnairePage };
