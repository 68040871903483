import { useEffect, useState } from 'react';

import { Card, List, ListSkeleton } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import { IoArrowForwardCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { Page } from '../../../components/shared/page/Page';
import { useProfile } from '../../../context/ProfileContext';
import {
  TheoryOfficialExamDto,
  useTheoryOfficialExam,
} from '../../../services/api/hooks/useTheoryOfficialExam';
import { useAppActions } from '../../../state/appStore';

function TheoryOfficialExamsPage() {
  const { getTheoryOfficialExams } = useTheoryOfficialExam();
  const { setShowGetPremiumPopup } = useAppActions();
  const navigate = useNavigate();
  const { profile } = useProfile();
  const [exams, setExams] = useState<TheoryOfficialExamDto[]>([]);

  const { data: response, isLoading } = useQuery({
    queryKey: ['getTheoryOfficialExams'],
    queryFn: () => getTheoryOfficialExams(),
    gcTime: 5 * (60 * 1000), // 5 mins
  });

  useEffect(() => {
    if (response?.data !== undefined) {
      setExams(response.data);
    }
  }, [response]);

  const onSelect = (id: string) => {
    if (profile.subscription?.isActive) {
      navigate({
        pathname: '/teoria/simulacro',
        search: `?examen=${id}`,
      });
    } else {
      setShowGetPremiumPopup(true);
    }
  };

  return (
    <Page title="Exámenes oficiales">
      <Card title="Exámenes oficiales Escala Básica">
        {isLoading ? (
          <ListSkeleton items={15} />
        ) : (
          exams &&
          exams.length > 0 && (
            <List
              items={exams.map((item) => ({
                label: {
                  value: item.year,
                },
                text: item.name + ' | Convocatoria ' + item.examination,
                icon: (
                  <IoArrowForwardCircleOutline
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      onSelect(item.id);
                    }}
                  />
                ),
              }))}
            />
          )
        )}
      </Card>
    </Page>
  );
}

export { TheoryOfficialExamsPage };
