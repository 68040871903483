import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface FeedbackPopup {
  title: string;
  onFeedback: (feedback: string) => void;
}

export interface FeedbackState {
  feedback?: FeedbackPopup;
  actions: {
    openFeedback: (
      title: string,
      onFeedback: (feedback: string) => void,
    ) => void;
    closeFeedback: () => void;
  };
}

export const useFeedbackStore = create<FeedbackState>()(
  devtools((set) => ({
    feedback: undefined,
    actions: {
      openFeedback: (title: string, onFeedback: (feedback: string) => void) =>
        set(() => {
          return {
            feedback: {
              title,
              onFeedback,
            },
          };
        }),
      closeFeedback: () =>
        set(() => ({
          feedback: undefined,
        })),
    },
  })),
);

export const useFeedback = () => useFeedbackStore((state) => state.feedback);

export const useFeedbackActions = () =>
  useFeedbackStore((state) => state.actions);
