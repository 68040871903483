import { Button, FormFeedback, FormGroup, Input } from '@sealfye/ui-components';
import classnames from 'classnames';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useAuth } from '../../../context/AuthContext';
import { useToasterActions } from '../../../state/toasterStore';
import { BaseComponentProps } from '../../../types/base-component.types';
import { MessagePopup } from '../../shared/message-popup/MessagePopup';

import styles from './ResetPasswordPopup.module.scss';

type ContainerProps = BaseComponentProps & {
  onClose: () => void;
};

function ResetPasswordPopup({
  className,
  onClose,
  testId = 'ui-reset-passwor-popup',
}: ContainerProps) {
  const { sendMessage } = useToasterActions();
  const { auth } = useAuth();

  return (
    <MessagePopup
      icon={<span>📬</span>}
      title="¿Has olvidado tu contraseña?"
      onClose={onClose}
      className={classnames(styles['main'], className)}
      testId={testId}
    >
      <Formik
        enableReinitialize
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required('El formato del email introducido no es válido.')
            .email('Tu email no puede estar vacío.'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);

          if (auth === undefined) {
            sendMessage(
              '¡Ups!',
              'Ha ocurrido un error inesperado, intentalo más tarde.',
              'danger',
            );

            return;
          }

          try {
            await sendPasswordResetEmail(auth, values.email);

            sendMessage(
              '¡Enviado!',
              'Comprueba tu bandeja de entrada.',
              'success',
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            if (error.code === 'auth/user-not-found') {
              sendMessage(
                '¡Vaya!',
                `No existe ningún usuario con el email introducido: ${values.email}.`,
                'danger',
              );
            } else {
              sendMessage(
                '¡Ups!',
                'Ha ocurrido un error inesperado, intentalo más tarde.',
                'danger',
              );
            }
          }

          setSubmitting(false);
        }}
        initialValues={{
          email: '',
        }}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit,
        }) => (
          <Form className={styles['form']} noValidate onSubmit={handleSubmit}>
            <FormGroup className={styles['form__group']}>
              <Input
                id="email"
                type="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                error={touched.email && !!errors.email}
              />
              {touched.email && errors.email && (
                <FormFeedback variant="danger">{errors.email}</FormFeedback>
              )}
              <FormFeedback>
                Te enviaremos un email para que la recuperes.
              </FormFeedback>
            </FormGroup>
            <Button
              className={styles['button']}
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Recuperar contraseña
            </Button>
          </Form>
        )}
      </Formik>
    </MessagePopup>
  );
}

export { ResetPasswordPopup };
