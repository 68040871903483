const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export function formatLongDate(date: Date) {
  return date.toLocaleDateString('es-ES', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export function formatDayDate(date: Date) {
  return date.toLocaleDateString('es-ES', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export function formatMonthDate(date: Date) {
  return date.toLocaleDateString('es-ES', {
    year: 'numeric',
    month: 'long',
  });
}

export function formatSortDate(date: Date) {
  return date.toLocaleDateString('es-ES', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

export function formatSortDayDate(date: Date) {
  return date.toLocaleDateString('es-ES', {
    month: '2-digit',
    day: '2-digit',
  });
}

export function formatHourDate(date: Date) {
  return date.toLocaleDateString('es-ES', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
}

export function formatTimestamp(timestamp: number) {
  const h = Math.floor(timestamp / 3600);
  const m = Math.floor(timestamp / 60) % 60;
  const s = timestamp % 60;

  return [h, m, s]
    .map((v) => (v < 10 ? '0' + v : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
}

export function formatFromSeconds(seconds: number) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor(seconds / 60) % 60;
  const s = seconds % 60;

  return [h, m, s];
}

export function getMonthName(month: number) {
  switch (month) {
    case 0:
      return 'Enero';
    case 1:
      return 'Febrero';
    case 2:
      return 'Marzo';
    case 3:
      return 'Abril';
    case 4:
      return 'Mayo';
    case 5:
      return 'Junio';
    case 6:
      return 'Julio';
    case 7:
      return 'Agosto';
    case 8:
      return 'Septiembre';
    case 9:
      return 'Octubre';
    case 10:
      return 'Noviembre';
    case 11:
      return 'Diciembre';
    default:
      return '';
  }
}

export function dateDiffInDays(a: Date, b: Date) {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function addHours(date: Date, hours: number) {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return date;
}

export function subtractHours(date: Date, hours: number) {
  date.setTime(date.getTime() - hours * 60 * 60 * 1000);
  return date;
}

export function subtractDays(date: Date, days: number) {
  date.setDate(date.getDate() - days);
  return date;
}

export function datetimesAreEqual(a: Date, b: Date) {
  return a.getTime() === b.getTime();
}
