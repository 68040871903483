import { useEffect } from 'react';

import { Button, Radar } from '@sealfye/ui-components';
import classnames from 'classnames';

import { useCountDownTimer } from '../../../../../hooks/useCountDownTimer';
import { BaseComponentProps } from '../../../../../types/base-component.types';

import styles from './Searching.module.scss';

export type ContainerProps = BaseComponentProps & {
  onTimeout: () => void;
  onQuit: () => void;
  seconds: number;
};

function Searching({
  className,
  seconds,
  onTimeout,
  onQuit,
  testId = 'ui-challenge-searching',
}: ContainerProps) {
  const [, , _s] = useCountDownTimer(0, 0, seconds);

  useEffect(() => {
    if (_s === 0) {
      onTimeout();
    }
  }, [_s]);

  return (
    <div
      className={classnames(styles['main'], className)}
      data-test-id={testId}
    >
      <span className={styles['title']}>Buscando oponente...</span>
      <Radar />
      <Button
        variant="outline-primary"
        className={styles['button']}
        onClick={onQuit}
      >
        Volver al inicio
      </Button>
    </div>
  );
}

export { Searching };
