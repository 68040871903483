import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Analytics, getAnalytics } from 'firebase/analytics';
import {
  FirebaseApp,
  FirebaseOptions,
  getApps,
  initializeApp,
} from 'firebase/app';
import {
  AppCheck,
  ReCaptchaV3Provider,
  initializeAppCheck,
} from 'firebase/app-check';

import { Splash } from '../components/core/Splash';
import { useConfiguration } from './ConfigurationContext';

export type FirebaseProps = {
  firebaseApp: FirebaseApp;
  appCheck: AppCheck;
  analytics: Analytics;
};

export const FirebaseContext = createContext<FirebaseProps | null>(null);

export const FirebaseContextProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const [firebaseApp, setFirebaseApp] = useState<FirebaseApp>();
  const [appCheck, setAppCheck] = useState<AppCheck>();
  const [analytics, setAnalytics] = useState<Analytics>();

  const { configuration } = useConfiguration();

  useEffect(() => {
    const app =
      getApps().length === 0
        ? initializeApp(configuration.firebaseConfig as FirebaseOptions)
        : getApps()[0] || undefined;

    setFirebaseApp(app);

    if (configuration.environment === 'local') {
      const token = configuration.appCheck?.debugToken;

      Object.assign(window, {
        FIREBASE_APPCHECK_DEBUG_TOKEN: token,
      });
    }

    const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(
        configuration.appCheck?.siteKey as string,
      ),

      isTokenAutoRefreshEnabled: true,
    });

    setAppCheck(appCheck);
    setAnalytics(getAnalytics(app));
  }, [configuration]);

  if (
    firebaseApp === undefined ||
    appCheck === undefined ||
    analytics === undefined
  ) {
    return <Splash />;
  }

  return (
    <FirebaseContext.Provider value={{ firebaseApp, appCheck, analytics }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => {
  const context = useContext(FirebaseContext);

  if (!context) {
    throw new Error(
      'useFirebase must be used within a FirebaseContextProvider',
    );
  }

  return context;
};
