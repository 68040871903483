import { PropsWithChildren } from 'react';

import { Badge } from '@sealfye/ui-components';

import { useConfiguration } from '../../../context/ConfigurationContext';

import styles from './Environment.module.scss';

function Environment({ children }: PropsWithChildren): JSX.Element {
  const { configuration } = useConfiguration();

  if (configuration.environment === 'production') {
    return children as JSX.Element;
  }

  return (
    <>
      {configuration.environment === 'local' && (
        <Badge className={styles['environment']} bg="featured">
          DEV MODE
        </Badge>
      )}
      {configuration.environment === 'test' && (
        <Badge className={styles['environment']} bg="warning">
          TEST MODE
        </Badge>
      )}
      {children}
    </>
  );
}

export { Environment };
