import { CardPopup } from '@sealfye/ui-components';
import classnames from 'classnames';
import { IoInformation } from 'react-icons/io5';

import { MessagePopupProps } from './MessagePopup.types';

import styles from './MessagePopup.module.scss';

function MessagePopup({
  className,
  icon,
  title,
  text,
  children,
  testId = 'ui-message-popup',
  onClose,
}: MessagePopupProps) {
  return (
    <CardPopup
      className={classnames(styles['main'], className)}
      onClose={onClose}
      testId={testId}
    >
      <div className={styles['icon']}>{icon ?? <IoInformation />}</div>
      {title && <h5 className={styles['title']}>{title}</h5>}
      {text && <span className={styles['text']}>{text}</span>}
      {children}
    </CardPopup>
  );
}

export { MessagePopup };
