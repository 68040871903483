import { useEffect, useRef, useState } from 'react';

import {
  Button,
  Card,
  CardPopup,
  List,
  ListSkeleton,
  ValueChart,
} from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import { IoReaderOutline } from 'react-icons/io5';

import ContributeImg from '../../../assets/images/contribute.svg';
import { EnglishQuestionAnswerViewer } from '../../../components/features/english/EnglishQuestionAnswerViewer';
import { OffsetPagination } from '../../../components/shared/offset-pagination/OffsetPagination';
import { Page } from '../../../components/shared/page/Page';
import { SectionCard } from '../../../components/shared/section-card/SectionCard';
import { DetailedEnglishQuestionDto } from '../../../services/api/hooks/useEnglishQuestion';
import { OffsetPaginationResponse } from '../../../services/api/hooks/usePageRequest';
import { useUsers } from '../../../services/api/hooks/useUsers';
import { useEditorActions } from '../../../state/editorStore';

import styles from './EnglishContributePage.module.scss';

function EnglishContributePage() {
  const { openEditor } = useEditorActions();

  const [current, setCurrent] = useState<string | undefined>(undefined);

  const { getCreatedEnglishQuestions } = useUsers();

  const [paginationResponse, setPaginationResponse] =
    useState<OffsetPaginationResponse>();
  const [questions, setQuestions] = useState<DetailedEnglishQuestionDto[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const nodeRef = useRef<HTMLDivElement>(null);

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['getCreatedEnglishQuestions', pageNumber],
    queryFn: () => getCreatedEnglishQuestions(pageNumber),
  });

  useEffect(() => {
    if (response?.data !== undefined) {
      setPaginationResponse(response.data);
      setQuestions(response.data.data);
    }
  }, [response]);

  return (
    <>
      <Page title="Preguntas de inglés">
        <SectionCard
          title="Crea nuevas preguntas de inglés"
          image={ContributeImg}
          button={
            <Button
              variant="outline-primary"
              onClick={() => {
                openEditor(
                  'english',
                  '00000000-0000-0000-0000-000000000000',
                  refetch,
                );
              }}
            >
              Nueva pregunta
            </Button>
          }
        >
          <ValueChart
            value={paginationResponse?.totalRecords.toString() || '0'}
          />
        </SectionCard>
        <Card title="Listado de preguntas">
          {isLoading ? (
            <ListSkeleton items={15} />
          ) : (
            <>
              <List
                items={questions.map((item) => ({
                  label: {
                    value: `${item.categoryName}`,
                  },
                  text:
                    item.question.length > 150
                      ? item.question.substring(0, 150) + '…'
                      : item.question,
                  info: item.question,
                  icon: (
                    <IoReaderOutline
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setCurrent(item.id);
                      }}
                    />
                  ),
                }))}
              />
              <OffsetPagination
                pagination={paginationResponse}
                onPageChange={(page) => setPageNumber(page)}
              />
            </>
          )}
        </Card>
      </Page>
      {current && (
        <CardPopup
          className={styles['card-popup']}
          refClose={nodeRef}
          closeable={false}
        >
          <EnglishQuestionAnswerViewer
            id={current}
            onClose={() => {
              refetch();
              nodeRef.current?.click();
              setCurrent(undefined);
            }}
          />
        </CardPopup>
      )}
    </>
  );
}

export { EnglishContributePage };
