import { useState } from 'react';

import { Button, ButtonGroup } from '@sealfye/ui-components';
import { useNavigate } from 'react-router-dom';

import AptitudeImg from '../../../assets/images/aptitude-woman.svg';
import TestImg from '../../../assets/images/exam.svg';
import { Categories } from '../../../components/features/aptitude/Categories';
import { Page } from '../../../components/shared/page/Page';
import { SectionCard } from '../../../components/shared/section-card/SectionCard';

function AptitudeQuestionnairesPage() {
  const [questions, setQuestions] = useState(15);
  const [questionnaireType, setQuestionnaireType] = useState<
    'simple-questionnaire' | 'questionnaire'
  >('simple-questionnaire');
  const [showLessons, setShowLessons] = useState(false);

  const navigate = useNavigate();

  return (
    <Page title="Psicotécnicos">
      <SectionCard
        title="Preguntas de psicotécnicos sueltas"
        image={AptitudeImg}
        button={
          <Button
            variant="outline-primary"
            onClick={() => {
              setQuestionnaireType('simple-questionnaire');
              setShowLessons(true);
            }}
          >
            Preguntas sueltas
          </Button>
        }
      >
        <p>
          Estos test evaluarán tus capacidades y aptitudes intelectuales y
          profesionales de distinto tipo, como el cálculo mental, la memoria
          verbal y visual o la visión espacial.
        </p>
      </SectionCard>
      <SectionCard
        title="Simulacro"
        image={TestImg}
        button={
          <Button
            variant="outline-primary"
            onClick={() => {
              setQuestionnaireType('questionnaire');
              setShowLessons(true);
            }}
          >
            Simulacro
          </Button>
        }
      >
        <p>
          Simulacro para el ejercicio número uno de la prueba de aptitudes
          psicotécnicas. En este simulacro de tipo ómnibus se evaluará la
          capacidad de razonamiento, capacidad de comprensión, la aptitud
          numérica, verbal y espacial.
        </p>
        <p>
          El número de preguntas totales y tiempo asignado al psicotécnico de
          policía nacional ha ido variando con cada edición. Cada pregunta tiene
          cuatro alternativas de respuesta de las cuales solo una es correcta.
        </p>
        <p>
          Si seleccionas menos preguntas el tiempo se ajustará
          proporcionalmente.
        </p>
        <ButtonGroup
          onSelect={(value) => setQuestions(+value)}
          options={[
            {
              value: 15,
              label: '15',
              selected: true,
            },
            {
              value: 25,
              label: '25',
            },
            {
              value: 50,
              label: '50',
            },
          ]}
        />
      </SectionCard>
      {showLessons && (
        <Categories
          onSelect={(ids) => {
            if (questionnaireType === 'simple-questionnaire') {
              navigate({
                pathname: '/psicotecnicos/test',
                search: `?${ids.length > 0 ? `temas=${ids.join(',')}` : ''}`,
              });
            } else {
              navigate({
                pathname: '/psicotecnicos/simulacro',
                search: `?temas=${ids.join(',')}` + `&preguntas=${questions}`,
              });
            }
          }}
          onClose={() => {
            setShowLessons(false);
          }}
        />
      )}
    </Page>
  );
}

export { AptitudeQuestionnairesPage };
