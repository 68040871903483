import { useState } from 'react';

import { Button, ButtonGroup } from '@sealfye/ui-components';
import { useNavigate } from 'react-router-dom';

import EnglishImg from '../../../assets/images/english.svg';
import TestImg from '../../../assets/images/exam.svg';
import { Categories } from '../../../components/features/english/Categories';
import { Page } from '../../../components/shared/page/Page';
import { SectionCard } from '../../../components/shared/section-card/SectionCard';

function EnglishQuestionnairesPage() {
  const [questions, setQuestions] = useState(15);
  const [questionnaireType, setQuestionnaireType] = useState<
    'simple-questionnaire' | 'questionnaire'
  >('simple-questionnaire');
  const [showLessons, setShowLessons] = useState(false);

  const navigate = useNavigate();

  return (
    <Page title="Inglés">
      <SectionCard
        title="Preguntas de inglés sueltas"
        image={EnglishImg}
        button={
          <Button
            variant="outline-primary"
            onClick={() => {
              setQuestionnaireType('simple-questionnaire');
              setShowLessons(true);
            }}
          >
            Preguntas sueltas
          </Button>
        }
      >
        <p>
          Estos test evaluarán tu nivel de inglés con preguntas específicas
          sobre comprensión de texto y gramática inglesa.
        </p>
      </SectionCard>
      <SectionCard
        title="Simulacro"
        image={TestImg}
        button={
          <Button
            variant="outline-primary"
            onClick={() => {
              setQuestionnaireType('questionnaire');
              setShowLessons(true);
            }}
          >
            Simulacro
          </Button>
        }
      >
        <p>
          Simulacro para la prueba de inglés, los aspirantes a Escala Básica
          deberán acreditar un nivel B1 de inglés. En este test se evaluará la
          comprensión de texto, relación de ideas entre textos, tiempos
          verbales, derivación de palabras, corrección de errores gramaticales.
        </p>
        <p>
          Cada pregunta tiene cuatro alternativas de respuesta de las cuales
          solo una es correcta.
        </p>
        <p>
          Si seleccionas menos preguntas el tiempo se ajustará
          proporcionalmente.
        </p>
        <ButtonGroup
          onSelect={(value) => setQuestions(+value)}
          options={[
            {
              value: 15,
              label: '15',
              selected: true,
            },
            {
              value: 25,
              label: '25',
            },
            {
              value: 50,
              label: '50',
            },
          ]}
        />
      </SectionCard>
      {showLessons && (
        <Categories
          onSelect={(ids) => {
            if (questionnaireType === 'simple-questionnaire') {
              navigate({
                pathname: '/ingles/test',
                search: `?${ids.length > 0 ? `temas=${ids.join(',')}` : ''}`,
              });
            } else {
              navigate({
                pathname: '/ingles/simulacro',
                search: `?temas=${ids.join(',')}` + `&preguntas=${questions}`,
              });
            }
          }}
          onClose={() => {
            setShowLessons(false);
          }}
        />
      )}
    </Page>
  );
}

export { EnglishQuestionnairesPage };
