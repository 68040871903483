import { useRef, useState } from 'react';

import {
  Button,
  CardPopup,
  FormFeedback,
  FormGroup,
  FormLabel,
  ImageUpload,
  ImageViewer,
  Input,
  SelectDropdown,
  SwitchCheck,
  Tooltip,
} from '@sealfye/ui-components';
import { useIsMutating, useMutation, useQueries } from '@tanstack/react-query';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import { IoTrashOutline } from 'react-icons/io5';
import * as Yup from 'yup';

import { useTheoryLesson } from '../../../../services/api/hooks/useTheoryLesson';
import {
  TheorySchemaPostDto,
  useTheorySchema,
} from '../../../../services/api/hooks/useTheorySchema';
import { useEditor } from '../../../../state/editorStore';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { Loading } from '../../../core/Loading';

import styles from './TheorySchemaEditor.module.scss';

type ContainerProps = BaseComponentProps & {
  id?: string;
  onClose: () => void;
};

function TheorySchemaEditor({
  className,
  id = '00000000-0000-0000-0000-000000000000',
  onClose,
  testId = 'ui-schema-editor',
}: ContainerProps) {
  const nodeRef = useRef<HTMLDivElement>(null);
  const nodeRefPreview = useRef<HTMLDivElement>(null);

  const editor = useEditor();

  const [showPreview, setShowPreview] = useState(false);

  const [payload, setPayload] = useState<TheorySchemaPostDto>();

  const { getTheoryLessons } = useTheoryLesson();
  const { getSchema, updateTheorySchema, addTheorySchema, deleteTheorySchema } =
    useTheorySchema();

  const [lessonsQuery, payloadQuery] = useQueries({
    queries: [
      {
        queryKey: ['getTheoryLessons'],
        queryFn: () => getTheoryLessons(),
      },
      {
        queryKey: ['getSchema', id],
        queryFn: () => getSchema(id),
      },
    ],
  });

  const updateMutation = useMutation({
    mutationKey: ['updateTheorySchema', id],
    mutationFn: (payload: TheorySchemaPostDto) =>
      updateTheorySchema(id, payload),
    onSuccess: () => {
      if (editor?.onEdit) {
        editor.onEdit();
      }
      nodeRef.current?.click();
    },
  });

  const addMutation = useMutation({
    mutationKey: ['addTheorySchema'],
    mutationFn: (payload: TheorySchemaPostDto) => addTheorySchema(payload),
    onSuccess: () => {
      if (editor?.onEdit) {
        editor.onEdit();
      }
      nodeRef.current?.click();
    },
  });

  const isMutating = Boolean(
    useIsMutating({ mutationKey: ['addTheorySchema'] }),
  );

  const deleteMutation = useMutation({
    mutationKey: ['deleteTheorySchema'],
    mutationFn: (id: string) => deleteTheorySchema(id),
    onSuccess: () => {
      if (editor?.onDelete) {
        editor.onDelete();
      }
      nodeRef.current?.click();
    },
  });

  return (
    <>
      <CardPopup
        className={classnames(styles['main'], className)}
        refClose={nodeRef}
        onClose={onClose}
        closeOnOutsideClick={false}
        testId={testId}
      >
        <div className={styles['wrapper']}>
          {lessonsQuery.isLoading || payloadQuery.isLoading ? (
            <Loading />
          ) : (
            <Formik
              enableReinitialize
              validationSchema={Yup.object().shape({
                lesson: Yup.string().required(
                  'Especifica el tema del esquema.',
                ),
                description: Yup.string()
                  .min(5, 'El título debe tener al menos 5 caracteres.')
                  .max(2000, 'El título no debe superar los 2000 caracteres.')
                  .required('El título no puede estar vacío.'),
                schemaPayload: Yup.string().required(
                  'El esquema no puede estar vacío.',
                ),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);

                try {
                  if (values.preview) {
                    setPayload({
                      ...payload,
                      lesson: +values.lesson,
                      description: values.description,
                      schemaPayload: values.schemaPayload ?? '',
                      trial: values.trial,
                    });

                    setShowPreview(true);
                  } else {
                    const payloadToSubmit = {
                      lesson: +values.lesson,
                      description: values.description,
                      schemaPayload: values.schemaPayload ?? '',
                      trial: values.trial,
                    };

                    if (id === '00000000-0000-0000-0000-000000000000') {
                      addMutation.mutate(payloadToSubmit);
                    } else {
                      updateMutation.mutate(payloadToSubmit);
                    }
                  }
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (error: any) {
                  throw new Error(error);
                }

                setSubmitting(false);
              }}
              initialValues={{
                lesson: payloadQuery.data?.data?.lesson || 1,
                description: payloadQuery.data?.data?.description || '',
                schemaPayload: payloadQuery.data?.data?.schemaPayload || '',
                trial: payloadQuery.data?.data?.trial || false,
                preview: false,
              }}
            >
              {({
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleSubmit,
                setFieldTouched,
                setFieldValue,
                setErrors,
              }) => (
                <Form
                  className={styles['form']}
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <div className={styles['icon']}>{<span>✏️</span>}</div>
                  <FormGroup>
                    <FormLabel>Tema</FormLabel>
                    <SelectDropdown
                      onSelect={(value) => {
                        handleChange({
                          target: {
                            name: 'lesson',
                            value,
                          },
                        });
                      }}
                      error={touched.lesson && !!errors.lesson}
                      options={
                        lessonsQuery.data?.data?.map((lesson) => ({
                          value: lesson.id,
                          label: lesson.id + ' - ' + lesson.title,
                          selected: values.lesson === lesson.id,
                        })) || []
                      }
                    />
                    <FormFeedback>
                      El temario de las oposiciones para Policía Nacional consta
                      45 temas divididos en tres bloques: ciencias jurídicas (1
                      al 26), ciencias sociales (27 al 36), materias
                      técnico-científicas (37 al 44).
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Título</FormLabel>
                    <Input
                      id="description"
                      value={values.description}
                      onChange={handleChange}
                      error={touched.description && !!errors.description}
                    />
                    {touched.description && errors.description && (
                      <FormFeedback variant="danger">
                        {errors.description}
                      </FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Imagen del esquema</FormLabel>
                    <ImageUpload
                      dataURL={values.schemaPayload}
                      onChange={(image) => {
                        setFieldValue('schemaPayload', image, false);
                        setFieldTouched('schemaPayload', true, false);
                        setErrors({ ...errors, schemaPayload: undefined });
                      }}
                      compressFormat="png"
                      maxWidth={4000}
                      maxHeight={4000}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onError={(error?: any) => {
                        setFieldValue('schemaPayload', '', false);
                        setFieldTouched('schemaPayload', true, false);

                        if (error?.maxFileSize) {
                          setErrors({
                            ...errors,
                            schemaPayload: 'La imagen no puede superar los 5MB',
                          });
                        }

                        if (error?.maxNumber) {
                          setErrors({
                            ...errors,
                            schemaPayload: 'No puedes subir más de 1 imagen',
                          });
                        }

                        if (error?.acceptType) {
                          setErrors({
                            ...errors,
                            schemaPayload:
                              'El formato de la imagen no es válido',
                          });
                        }

                        if (error?.resolution) {
                          setErrors({
                            ...errors,
                            schemaPayload:
                              'La resolución de la imagen es muy alta',
                          });
                        }
                      }}
                    />
                    {touched.schemaPayload && errors.schemaPayload && (
                      <FormFeedback variant="danger">
                        {errors.schemaPayload}
                      </FormFeedback>
                    )}
                    <FormFeedback>
                      La imagen no puede superar los 5MB, y debe estar en un
                      formato válido (jpg, jpeg, png).
                    </FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <FormLabel>Trial</FormLabel>
                    <SwitchCheck
                      label="Pregunta para usuarios con licencia gratuita"
                      checked={values.trial}
                      onChange={() => {
                        handleChange({
                          target: {
                            name: 'trial',
                            value: !values.trial,
                          },
                        });
                      }}
                    />
                    <FormFeedback>
                      Marca esta opción si quieres que este esquema sea visible
                      por usuarios con licencia gratuita.
                    </FormFeedback>
                  </FormGroup>

                  <div className={styles['footer']}>
                    {id !== '00000000-0000-0000-0000-000000000000' && (
                      <Tooltip
                        icon={
                          <IoTrashOutline className={styles['remove-icon']} />
                        }
                        placement="top"
                        behavior="click"
                      >
                        <div className={styles['tooltip-payload']}>
                          <span>¿Estás segurx?</span>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => {
                              deleteMutation.mutate(id);
                            }}
                          >
                            Eliminar
                          </Button>
                        </div>
                      </Tooltip>
                    )}
                    <Button
                      className={styles['button']}
                      loading={isSubmitting || isMutating}
                      disabled={isSubmitting || isMutating}
                      variant="outline-primary"
                      onClick={() => {
                        values.preview = true;
                        handleSubmit();
                      }}
                    >
                      Previsualizar
                    </Button>
                    <Button
                      className={styles['button']}
                      loading={isSubmitting || isMutating}
                      disabled={isSubmitting || isMutating}
                      variant="primary"
                      onClick={() => {
                        values.preview = false;
                        handleSubmit();
                      }}
                    >
                      Enviar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </CardPopup>
      {showPreview && payload && (
        <CardPopup
          className={styles['card-popup']}
          onClose={() => setShowPreview(false)}
          refClose={nodeRefPreview}
          closeable={false}
        >
          <ImageViewer
            id={id}
            heading={`Tema ${payload.lesson}`}
            title={payload.description}
            src={payload.schemaPayload}
            background="main"
            onClose={() => {
              nodeRefPreview.current?.click();
            }}
          />
        </CardPopup>
      )}
    </>
  );
}

export { TheorySchemaEditor };
