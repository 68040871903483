import { useEffect, useRef, useState } from 'react';

import {
  Button,
  Card,
  CardPopup,
  List,
  ListSkeleton,
  Tooltip,
} from '@sealfye/ui-components';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IoCheckmarkDoneCircleOutline, IoReaderOutline } from 'react-icons/io5';

import { Empty } from '../../../components/core/Empty';
import { Loading } from '../../../components/core/Loading';
import { AptitudeQuestionAnswerViewer } from '../../../components/features/aptitude/AptitudeQuestionAnswerViewer';
import { OffsetPagination } from '../../../components/shared/offset-pagination/OffsetPagination';
import { Page } from '../../../components/shared/page/Page';
import { useAptitudeQuestion } from '../../../services/api/hooks/useAptitudeQuestion';
import { QuestionFeedbackReportDto } from '../../../services/api/hooks/useFeedback';
import { OffsetPaginationResponse } from '../../../services/api/hooks/usePageRequest';
import { formatLongDate } from '../../../utils/date';

import styles from './AptitudeErrorsPage.module.scss';

function AptitudeErrorsPage() {
  const [paginationResponse, setPaginationResponse] =
    useState<OffsetPaginationResponse>();
  const [pageNumber, setPageNumber] = useState(1);
  const [feedback, setFeedback] = useState<QuestionFeedbackReportDto[]>([]);
  const [current, setCurrent] = useState<string>();

  const {
    getAptitudeQuestionReportedErrors,
    deleteAptitudeQuestionReportedError,
  } = useAptitudeQuestion();

  const nodeRef = useRef<HTMLDivElement>(null);
  const tooltipNodeRef = useRef<HTMLDivElement>(null);

  const {
    data: response,
    isSuccess,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['getAptitudeQuestionReportedErrors', pageNumber],
    queryFn: () => getAptitudeQuestionReportedErrors(pageNumber),
  });

  const mutation = useMutation({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: (input: any) => {
      return deleteAptitudeQuestionReportedError(
        input.questionId,
        input.feedbackId,
      );
    },
    onSuccess: () => {
      refetch();
      tooltipNodeRef.current?.click();
      nodeRef.current?.click();
    },
  });

  useEffect(() => {
    if (response?.data !== undefined) {
      setPaginationResponse(response.data);
      setFeedback(response.data.data);
    }
  }, [response]);

  if (isLoading) {
    return <Loading />;
  }

  if (isSuccess && response?.data?.data.length === 0) {
    return <Empty title="No hay errores en psicotécnicos 🎉" />;
  }

  return (
    <Page title="Errores reportados">
      <Card title="Errores en preguntas de psicotécnicos">
        {isLoading ? (
          <ListSkeleton items={15} />
        ) : (
          <>
            <List
              items={feedback.map((item) => ({
                text: item.description + ' | ' + item.email,
                info: formatLongDate(new Date(item.date)),
                icon: (
                  <>
                    <IoReaderOutline
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setCurrent(item.questionId);
                      }}
                    />
                    <Tooltip
                      key={item.id}
                      icon={
                        <IoCheckmarkDoneCircleOutline
                          style={{
                            cursor: 'pointer',
                          }}
                        />
                      }
                      placement="left"
                      behavior="click"
                      refClose={tooltipNodeRef}
                    >
                      <div className={styles['tooltip-payload']}>
                        <span>¿Marcar como revisada?</span>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => {
                            mutation.mutate({
                              questionId: item.questionId,
                              feedbackId: item.id,
                            });
                          }}
                        >
                          Confirmar
                        </Button>
                      </div>
                    </Tooltip>
                  </>
                ),
              }))}
            />
            <OffsetPagination
              pagination={paginationResponse}
              onPageChange={(page) => setPageNumber(page)}
            />
          </>
        )}
      </Card>
      {current ? (
        <CardPopup
          className={styles['card-popup']}
          onClose={() => {
            refetch();
            setCurrent(undefined);
          }}
          refClose={nodeRef}
          closeable={false}
        >
          <AptitudeQuestionAnswerViewer
            id={current}
            onClose={() => {
              nodeRef.current?.click();
            }}
          />
        </CardPopup>
      ) : null}
    </Page>
  );
}

export { AptitudeErrorsPage };
