import { Pagination } from '@sealfye/ui-components';
import classnames from 'classnames';

import { OffsetPaginationProps } from './OffsetPagination.types';

import styles from './OffsetPagination.module.scss';

function OffsetPagination({
  className,
  pagination,
  onPageChange,
  testId = 'ui-offset-pagination',
}: OffsetPaginationProps) {
  if (pagination === undefined || pagination.totalPages < 2) {
    return null;
  }

  return (
    <Pagination
      testId={testId}
      className={classnames(styles['main'], className)}
      pageNumber={pagination?.pageNumber || 1}
      pageSize={pagination?.pageSize || 1}
      totalRecords={pagination?.totalRecords || 1}
      onPageChange={onPageChange}
      pageNeighbours={2}
    />
  );
}

export { OffsetPagination };
