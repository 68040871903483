import { useEffect, useState } from 'react';

import { QuestionAnswer } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';

import { useProfile } from '../../../context/ProfileContext';
import {
  EnglishQuestionDto,
  useEnglishQuestion,
} from '../../../services/api/hooks/useEnglishQuestion';
import { useEditorActions } from '../../../state/editorStore';
import { useFeedbackActions } from '../../../state/feedbackStore';
import { BaseComponentProps } from '../../../types/base-component.types';

export type ContainerProps = BaseComponentProps & {
  id: string;
  onClose: () => void;
};

function EnglishQuestionAnswerViewer({ id, onClose }: ContainerProps) {
  const [question, setQuestion] = useState<EnglishQuestionDto>();
  const { openFeedback } = useFeedbackActions();
  const { openEditor } = useEditorActions();
  const { profile } = useProfile();

  const {
    getEnglishQuestion,
    reportEnglishQuestionError,
    bookmarkEnglishQuestion,
    unbookmarkEnglishQuestion,
  } = useEnglishQuestion();

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['getEnglishQuestion', id],
    queryFn: () => getEnglishQuestion(id),
  });

  useEffect(() => {
    if (response && response.data) {
      setQuestion(response.data);
    }
  }, [response]);

  if (isLoading || question === undefined) {
    return null;
  }

  return (
    <QuestionAnswer
      question={{
        id: question.id,
        text: question.question,
        caption: question.status === 4 ? '(Pregunta obsoleta)' : undefined,
        options: question.answers,
        subject: question.categoryName,
        selectedOption: question.selectedAnswer,
        bookmarked: question.bookmarked ?? false,
        correctOption: question.correctAnswer ?? 0,
        reason: question.reason,
      }}
      background="main"
      onBookmark={async (id) => {
        const response = await bookmarkEnglishQuestion(id);

        if (response.success) {
          setQuestion((prev) => {
            if (prev !== undefined) {
              return {
                ...prev,
                bookmarked: true,
              };
            } else {
              return prev;
            }
          });
        }
      }}
      onUnbookmark={async (id) => {
        const response = await unbookmarkEnglishQuestion(id);

        if (response.success) {
          setQuestion((prev) => {
            if (prev !== undefined) {
              return {
                ...prev,
                bookmarked: false,
              };
            } else {
              return prev;
            }
          });
        }
      }}
      onFeedback={
        profile.subscription?.isActive && !profile.subscription?.isTrial
          ? (id) => {
              openFeedback(
                '¿Has visto algún error en esta pregunta?',
                async (feedback) => {
                  const response = await reportEnglishQuestionError(
                    id,
                    feedback,
                  );

                  if (!response.success) {
                    throw new Error('Error al enviar el feedback');
                  }
                },
              );
            }
          : undefined
      }
      onEdit={
        profile.role === 'admin' ||
        profile.role === 'contributor' ||
        profile.role === 'contributor:english'
          ? () => {
              openEditor('english', question.id, refetch, refetch);
            }
          : undefined
      }
      onClose={onClose}
    />
  );
}

export { EnglishQuestionAnswerViewer };
