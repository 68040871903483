import { Button, CardPopup } from '@sealfye/ui-components';
import classnames from 'classnames';

import AppImg from '../../../assets/images/police-and-woman-app.svg';
import { useConfiguration } from '../../../context/ConfigurationContext';
import { useApp, useAppActions } from '../../../state/appStore';
import { BaseComponentProps } from '../../../types/base-component.types';

import styles from './GetPremiumPopup.module.scss';

function GetPremiumPopup({
  className,
  testId = 'ui-get-premium-popup',
}: BaseComponentProps) {
  const { configuration } = useConfiguration();
  const { showGetPremiumPopup } = useApp();
  const { setShowGetPremiumPopup } = useAppActions();

  if (!showGetPremiumPopup) {
    return null;
  }

  return (
    <CardPopup
      onClose={() => {
        setShowGetPremiumPopup(false);
      }}
      className={classnames(styles['main'], className)}
      testId={testId}
    >
      <div className={styles['modal']}>
        <div className={styles['text']}>
          <span className={styles['slogan']}>
            ¡Hazte Premium y prepárate para cumplir tu sueño!
          </span>
          <span className={styles['description']}>
            Con <strong>Premium</strong> podrás acceder a todo el contenido y
            funcionalidad de la plataforma de <strong>OpositandoPN</strong>
          </span>
        </div>
        <img className={styles['image']} src={AppImg} />
        <Button
          className={styles['button']}
          variant="premium"
          shadow
          onClick={() => {
            window.open(
              `${configuration.app?.pageUrl}/mi-cuenta/premium`,
              '_blank',
            );
            setShowGetPremiumPopup(false);
          }}
        >
          Hazte Premium
        </Button>
      </div>
    </CardPopup>
  );
}

export { GetPremiumPopup };
