import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { Loading } from '../../../../components/core/Loading';
import { QuestionnaireResult } from '../../../../components/shared/questionnaire-result/QuestionnaireResult';
import { useProfile } from '../../../../context/ProfileContext';
import { useEnglishQuestion } from '../../../../services/api/hooks/useEnglishQuestion';
import { useEnglishTest } from '../../../../services/api/hooks/useEnglishTest';
import { useEditorActions } from '../../../../state/editorStore';
import { useFeedbackActions } from '../../../../state/feedbackStore';
import { useSidebarActions } from '../../../../state/sidebarStore';
import { QuestionAnswerType } from '../../../../types/Question.types';

function EnglishQuestionnaireResultPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getEnglishTestResult } = useEnglishTest();
  const { setShowBtnToggle } = useSidebarActions();
  const { openFeedback } = useFeedbackActions();
  const { openEditor } = useEditorActions();

  const { profile } = useProfile();

  const {
    bookmarkEnglishQuestion,
    unbookmarkEnglishQuestion,
    reportEnglishQuestionError,
  } = useEnglishQuestion();

  useEffect(() => {
    setShowBtnToggle(false);
    return () => {
      setShowBtnToggle(true);
    };
  }, []);

  const [questions, setQuestions] = useState<QuestionAnswerType[]>([]);

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['getEnglishTestResult', id],
    queryFn: () => getEnglishTestResult(id!),
    gcTime: 5 * (60 * 1000), // 5 mins}
  });

  useEffect(() => {
    if (response?.data) {
      setQuestions(
        response.data.questions.map((question) => ({
          id: question.id,
          text: question.question,
          caption: question.status === 4 ? '(Pregunta obsoleta)' : undefined,
          subject: question.categoryName,
          options: question.answers,
          selectedOption: question.selectedAnswer ?? 0,
          bookmarked: question.bookmarked ?? false,
          correctOption: question.correctAnswer ?? 0,
          reason: question.reason,
        })) ?? [],
      );
    }
  }, [response]);

  if (isLoading || questions.length === 0) {
    return <Loading />;
  }

  return (
    <QuestionnaireResult
      mark={response?.data?.mark ?? 0}
      elapsedTime={response?.data?.elapsedTime ?? 0}
      correctAnswers={response?.data?.correctAnswers ?? 0}
      wrongAnswers={response?.data?.wrongAnswers ?? 0}
      emptyAnswers={response?.data?.emptyAnswers ?? 0}
      questions={questions}
      onBookmark={async (id) => {
        const response = await bookmarkEnglishQuestion(id);
        if (!response.success) {
          throw new Error("Couldn't bookmark question");
        }
      }}
      onUnbookmark={async (id) => {
        const response = await unbookmarkEnglishQuestion(id);
        if (!response.success) {
          throw new Error("Couldn't unbookmark question");
        }
      }}
      onFeedback={(id) => {
        openFeedback(
          '¿Has visto algún error en esta pregunta?',
          async (feedback) => {
            const response = await reportEnglishQuestionError(id, feedback);

            if (!response.success) {
              throw new Error('Error al enviar el feedback');
            }
          },
        );
      }}
      onEdit={
        profile.role === 'admin' ||
        profile.role === 'contributor' ||
        profile.role === 'contributor:english'
          ? (id) => {
              openEditor('english', id, refetch, refetch);
            }
          : undefined
      }
      onClose={() => {
        navigate('/ingles/simulacros/resultados');
      }}
    />
  );
}

export { EnglishQuestionnaireResultPage };
