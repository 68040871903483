import { useEffect, useState } from 'react';

import { ResultPreview } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { Empty } from '../../../components/core/Empty';
import { Loading } from '../../../components/core/Loading';
import { OffsetPagination } from '../../../components/shared/offset-pagination/OffsetPagination';
import { Page } from '../../../components/shared/page/Page';
import { useProfile } from '../../../context/ProfileContext';
import { useAptitudeTest } from '../../../services/api/hooks/useAptitudeTest';
import { OffsetPaginationResponse } from '../../../services/api/hooks/usePageRequest';
import { TestResult } from '../../../services/api/questions';
import { useAppActions } from '../../../state/appStore';
import { formatHourDate } from '../../../utils/date';

function AptitudeQuestionnaireHistoryPage() {
  const { getAptitudeTestResults } = useAptitudeTest();
  const [paginationResponse, setPaginationResponse] =
    useState<OffsetPaginationResponse>();
  const { setShowGetPremiumPopup } = useAppActions();
  const navigate = useNavigate();
  const { profile } = useProfile();
  const [results, setResults] = useState<TestResult[]>([]);
  const [pageNumber, setPageNumber] = useState(1);

  const {
    data: response,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ['getAptitudeTestResults', pageNumber],
    queryFn: () => getAptitudeTestResults(pageNumber),
    gcTime: 5 * (60 * 1000), // 5 mins
  });

  useEffect(() => {
    if (response?.data !== undefined) {
      setPaginationResponse(response.data);
      setResults(response.data.data);
    }
  }, [response]);

  if (isLoading) {
    return <Loading />;
  }

  if (isSuccess && response?.data?.data.length === 0) {
    return <Empty title="Aún no has realizado ningún simulacro" />;
  }

  return (
    <Page title="Histórico de simulacros">
      {results.map((item) => (
        <ResultPreview
          key={item.id}
          id={item.id}
          title={
            item.isCompetitive
              ? 'Simulacro competitivo'
              : 'Simulacro de psicotécnicos'
          }
          mark={item.mark}
          elapsedTime={item.elapsedTime}
          description={`${item.totalAnswers} preguntas`}
          correctAnswers={item.correctAnswers}
          wrongAnswers={item.wrongAnswers}
          emptyAnswers={item.emptyAnswers}
          isCompetitive={item.isCompetitive}
          submittedDate={formatHourDate(new Date(item.submittedDate))}
          onClick={(id) => {
            if (profile.subscription?.isActive) {
              navigate(`/psicotecnicos/simulacros/resultados/${id}`);
            } else {
              setShowGetPremiumPopup(true);
            }
          }}
        />
      ))}
      <OffsetPagination
        pagination={paginationResponse}
        onPageChange={(page) => setPageNumber(page)}
      />
    </Page>
  );
}

export { AptitudeQuestionnaireHistoryPage };
