import { useEffect } from 'react';

import { isMobile } from '../utils/device';

export function useAppHeight() {
  useEffect(() => {
    const updateAppHeight = () => {
      const ih = isMobile.iOS() ? screen.height : window.innerHeight;

      const height =
        ih && document.documentElement.clientHeight
          ? Math.min(ih, document.documentElement.clientHeight)
          : ih || document.documentElement.clientHeight;

      document.documentElement.style.setProperty('--app-height', `${height}px`);
    };

    window.addEventListener('resize', updateAppHeight);
    updateAppHeight();

    return () => window.removeEventListener('resize', updateAppHeight);
  }, []);
}
