/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAxios } from '../../../context/AxiosContext';
import { ApiResponse } from '../response';
import { UserScoreDto } from './useTheoryTest';

export interface SampleMarkRankingDto {
  mark: number;
  cutOffMark: number;
  position: number;
  total: number;
  userScores: UserScoreDto[];
}

export interface SampleMarkPostDto {
  mark: number;
}

export function useMark() {
  const { instance } = useAxios();

  async function getTheorySampleMarkRanking(): Promise<
    ApiResponse<SampleMarkRankingDto>
  > {
    try {
      const response = await instance.get<SampleMarkRankingDto>(
        `/marks/sample/ranking/theory`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      if (error.response.status === 404) {
        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function getAptitudeSampleMarkRanking(): Promise<
    ApiResponse<SampleMarkRankingDto>
  > {
    try {
      const response = await instance.get<SampleMarkRankingDto>(
        `/marks/sample/ranking/aptitude`,
      );

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch (error: any) {
      if (error.response.status === 404) {
        return {
          success: false,
        };
      } else {
        return {
          success: false,
        };
      }
    }
  }

  async function addTheorySampleMark(
    mark: SampleMarkPostDto,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(`/marks/sample`, {
        ...mark,
        subject: 0,
      });

      return {
        success: response.status === 200,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  async function addAptitudeSampleMark(
    mark: SampleMarkPostDto,
  ): Promise<ApiResponse<any>> {
    try {
      const response = await instance.post(`/marks/sample`, {
        ...mark,
        subject: 1,
      });

      return {
        success: response.status === 200,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  return {
    getTheorySampleMarkRanking,
    getAptitudeSampleMarkRanking,
    addTheorySampleMark,
    addAptitudeSampleMark,
  };
}
