import { Button } from '@sealfye/ui-components';
import { IoShieldHalfOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import CompetitionImg from '../../../../assets/images/competition.svg';
import { useProfile } from '../../../../context/ProfileContext';
import { useAppActions } from '../../../../state/appStore';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { SectionCard } from '../../../shared/section-card/SectionCard';

function ChallengeCard({ className }: BaseComponentProps) {
  const { profile } = useProfile();
  const { setShowGetPremiumPopup } = useAppActions();
  const navigate = useNavigate();

  const onSelect = () => {
    if (profile.subscription?.isActive) {
      navigate('/desafios/one-to-one');
    } else {
      setShowGetPremiumPopup(true);
    }
  };

  return (
    <SectionCard
      className={className}
      title="Reta a otro opositor en un duelo y gana puntos"
      image={CompetitionImg}
      button={
        <Button variant="outline-primary" onClick={onSelect}>
          Buscar oponente
        </Button>
      }
      icon={<IoShieldHalfOutline />}
    >
      <p>
        Tendréis que responder a 10 preguntas de cualquier tema. Ganarás 3
        puntos si consigues más puntuación que el oponente, 1 si quedáis empate
        y ningún punto si pierdes.
      </p>
    </SectionCard>
  );
}

export { ChallengeCard };
