import { useEffect, useState } from 'react';

import { Card, List, ListSkeleton } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import { IoArrowForwardCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { OffsetPagination } from '../../../components/shared/offset-pagination/OffsetPagination';
import { Page } from '../../../components/shared/page/Page';
import { useProfile } from '../../../context/ProfileContext';
import {
  AptitudeTestMetadataDto,
  useAptitudeTest,
} from '../../../services/api/hooks/useAptitudeTest';
import { OffsetPaginationResponse } from '../../../services/api/hooks/usePageRequest';
import { useAppActions } from '../../../state/appStore';
import { formatTimestamp } from '../../../utils/date';

function AptitudeCompetitiveQuestionnairesPage() {
  const { getAptitudeTestsListWithQuestions } = useAptitudeTest();
  const { setShowGetPremiumPopup } = useAppActions();
  const navigate = useNavigate();
  const { profile } = useProfile();
  const [paginationResponse, setPaginationResponse] =
    useState<OffsetPaginationResponse>();
  const [exams, setExams] = useState<AptitudeTestMetadataDto[]>([]);

  const [pageNumber, setPageNumber] = useState(1);

  const { data: response, isLoading } = useQuery({
    queryKey: ['getAptitudeTestsListWithQuestions', pageNumber],
    queryFn: () => getAptitudeTestsListWithQuestions(pageNumber),
    gcTime: 5 * (60 * 1000), // 5 mins
  });

  useEffect(() => {
    if (response?.data !== undefined) {
      setPaginationResponse(response.data);
      setExams(response.data.data);
    }
  }, [response]);

  const onSelect = (id: string) => {
    if (profile.subscription?.isActive && !profile.subscription?.isTrial) {
      navigate({
        pathname: '/psicotecnicos/simulacro',
        search: `?competitivo=${id}`,
      });
    } else {
      setShowGetPremiumPopup(true);
    }
  };

  return (
    <Page title="Simulacros semanales">
      <Card title="Simulacros semanales psicotécnicos">
        {isLoading ? (
          <ListSkeleton items={15} />
        ) : (
          exams &&
          exams.length > 0 && (
            <List
              items={exams.map((item) => ({
                label: {
                  value: item.title,
                  backgroundColor: '#9DA5CD',
                },
                text: `Nota media: ${
                  item.averageScore
                } | Tiempo: ${formatTimestamp(item.completionTime)}`,
                icon: (
                  <IoArrowForwardCircleOutline
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      onSelect(item.id);
                    }}
                  />
                ),
              }))}
            />
          )
        )}
        <OffsetPagination
          pagination={paginationResponse}
          onPageChange={(page) => setPageNumber(page)}
        />
      </Card>
    </Page>
  );
}

export { AptitudeCompetitiveQuestionnairesPage };
