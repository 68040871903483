import { Card } from '@sealfye/ui-components';
import classnames from 'classnames';

import { SectionCardProps } from './SectionCard.types';

import styles from './SectionCard.module.scss';

function SectionCard({
  className,
  title,
  children,
  loading,
  image,
  button,
  icon,
}: SectionCardProps) {
  return (
    <Card
      title={title}
      className={classnames(styles['main'], className, {
        [styles['main--image']]: image,
      })}
      loading={loading}
      icon={icon}
    >
      <div
        className={classnames(styles['section'], {
          [styles['section--image']]: image,
        })}
        style={{ backgroundImage: image ? `url(${image})` : 'none' }}
      >
        {children && <div>{children}</div>}
        {image && <img className={styles['image']} src={image} />}
        {button && <div className={styles['section-button']}>{button}</div>}
      </div>
    </Card>
  );
}

export { SectionCard };
