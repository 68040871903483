/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

import { QuestionAnswer, Ranking } from '@sealfye/ui-components';
import { useMachine } from '@xstate/react';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { createMachine } from 'xstate';

import {
  ChallengeResult,
  Room,
  useChallenge,
} from '../../../../../services/api/hooks/useChallenge';
import { useTheoryQuestion } from '../../../../../services/api/hooks/useTheoryQuestion';
import { QuestionAnswerType } from '../../../../../types/Question.types';
import { BaseComponentProps } from '../../../../../types/base-component.types';
import { sleep } from '../../../../../utils/crosscutting';
import { Countdown } from '../layout/Countdown';
import { OfflineSimpleQuestion } from './OfflineSimpleQuestion';
import { Presentation } from './Presentation';
import { Result } from './Result';

import styles from './Game.module.scss';

export type ContainerProps = BaseComponentProps & {
  challenge: Room;
};

function OfflineGame({
  className,
  challenge,
  testId = 'ui-challenge',
}: ContainerProps) {
  const navigate = useNavigate();

  const [question, setQuestion] = useState<QuestionAnswerType>();
  const [challengeResult, setChallengeResult] = useState<ChallengeResult>({
    topPlayers: challenge.players,
  });

  const { uploadChallengeResult, markOfflineChallengeQuestion } =
    useChallenge();

  const {
    getRandomTheoryQuestions,
    bookmarkTheoryQuestion,
    unbookmarkTheoryQuestion,
  } = useTheoryQuestion();

  const [current, send] = useMachine(() =>
    createMachine(
      {
        predictableActionArguments: true,
        id: 'offline-challenge',
        initial: 'presentation',
        context: {
          index: 1,
          challengeResult: challengeResult,
        },
        states: {
          presentation: {
            on: {
              PLAYERS_READY: {
                target: 'countdown',
              },
            },
          },
          countdown: {
            entry: ['getNextQuestion'],
            on: {
              NEXT: {
                target: 'question',
              },
            },
          },
          question: {
            on: {
              SELECT: { target: 'solution' },
              TIMEOUT: [
                {
                  target: 'result',
                  cond: 'isNextQuestion',
                },
                {
                  target: 'end',
                },
              ],
            },
          },
          solution: {
            on: {
              RESULT: [
                {
                  target: 'result',
                  cond: 'isNextQuestion',
                },
                {
                  target: 'end',
                },
              ],
              END: {
                target: 'end',
              },
            },
          },
          result: {
            entry: ['getNextQuestion'],
            on: {
              NEXT: {
                target: 'question',
              },
              END: {
                target: 'end',
              },
            },
          },
          end: {
            type: 'final',
            entry: ['uploadChallengeResult'],
          },
        },
      },
      {
        guards: {
          isNextQuestion: (context: any) => {
            context.index = context.index + 1;
            return context.index <= challenge.questions;
          },
        },
        actions: {
          getNextQuestion: async () => {
            const response = await getRandomTheoryQuestions();
            if (response.success && response.data !== undefined) {
              setQuestion({
                id: response.data.id,
                text: response.data.question,
                options: response.data.answers,
                subject: 'Tema ' + response.data.lesson?.toString(),
                selectedOption: 0,
                bookmarked: response.data.bookmarked ?? false,
                correctOption: response.data.correctAnswer ?? 0,
                reason: response.data.reason,
                correctAnswerRate: response.data.correctAnswerRate ?? 0,
                emptyAnswerRate: response.data.emptyAnswerRate ?? 0,
                wrongAnswerRate: response.data.wrongAnswerRate ?? 0,
              });
            }
          },
          uploadChallengeResult: async (context: any) => {
            await uploadChallengeResult(context.challengeResult);
          },
        },
      },
    ),
  );

  const onSendResponse = async (answer: number, timeInSeconds: number) => {
    if (question) {
      const response = await markOfflineChallengeQuestion({
        id: question.id,
        correctAnswer: question.correctOption,
        selectedAnswer: answer,
        timeInSeconds: timeInSeconds,
      });
      if (
        response.success &&
        response.data &&
        response.data !== undefined &&
        challengeResult
      ) {
        const data = response.data;
        const result: ChallengeResult = {
          topPlayers: challengeResult.topPlayers.map((player) => {
            return {
              ...player,
              score:
                player.score +
                data.topPlayers.filter((p) => p.id === player.id)[0].score,
            };
          }),
        };

        setChallengeResult(result);
        current.context.challengeResult = result;

        send('RESULT');
      }
    }
  };

  return (
    <div
      className={classnames(styles['main'], className)}
      data-test-id={testId}
    >
      {current.matches('presentation') && challenge && (
        <Presentation
          room={challenge}
          onReady={() => {
            send('PLAYERS_READY');
          }}
        />
      )}

      {current.matches('countdown') && challenge && (
        <Countdown
          index={current.context.index}
          seconds={3}
          onTimeout={() => {
            send('NEXT');
          }}
        />
      )}

      {current.matches('question') && challenge && question && (
        <OfflineSimpleQuestion
          className={styles['question']}
          timeInSeconds={challenge.questionTime}
          question={question}
          onBookmark={async (id) => {
            const response = await bookmarkTheoryQuestion(id);

            if (response.success) {
              setQuestion((prev) => {
                if (prev !== undefined) {
                  return {
                    ...prev,
                    bookmarked: true,
                  };
                } else {
                  return prev;
                }
              });
            }
          }}
          onUnbookmark={async (id) => {
            const response = await unbookmarkTheoryQuestion(id);

            if (response.success) {
              setQuestion((prev) => {
                if (prev !== undefined) {
                  return {
                    ...prev,
                    bookmarked: false,
                  };
                } else {
                  return prev;
                }
              });
            }
          }}
          onSubmit={async (question, timeInSeconds) => {
            setQuestion((prev) => {
              if (prev !== undefined) {
                return {
                  ...prev,
                  selectedOption: question.selectedOption,
                };
              } else {
                return prev;
              }
            });

            send('SELECT');
            await sleep(Math.floor(Math.random() * 2500) + 2500);
            onSendResponse(question.selectedOption, timeInSeconds);
          }}
          onTimeout={() => {
            onSendResponse(0, 0);
            send('TIMEOUT');
          }}
        />
      )}

      {current.matches('solution') && question && (
        <QuestionAnswer
          className={styles['question']}
          question={question}
          onBookmark={async (id) => {
            const response = await bookmarkTheoryQuestion(id);

            if (response.success) {
              setQuestion((prev) => {
                if (prev !== undefined) {
                  return {
                    ...prev,
                    bookmarked: true,
                  };
                } else {
                  return prev;
                }
              });
            }
          }}
          onUnbookmark={async (id) => {
            const response = await unbookmarkTheoryQuestion(id);

            if (response.success) {
              setQuestion((prev) => {
                if (prev !== undefined) {
                  return {
                    ...prev,
                    bookmarked: false,
                  };
                } else {
                  return prev;
                }
              });
            }
          }}
        >
          <span className={styles['waiting-for-player']}>
            Esperando a tu rival...
          </span>
        </QuestionAnswer>
      )}

      {current.matches('result') && question && challengeResult && (
        <>
          <Countdown
            index={current.context.index}
            seconds={3}
            onTimeout={() => {
              send('NEXT');
            }}
          />
          <Ranking
            className={styles['ranking']}
            columns={['Jugador', 'Puntuación']}
            rows={challengeResult.topPlayers
              .sort((a, b) => (a.score < b.score ? 1 : -1))
              .map((item) => ({
                cells: [
                  item.username.length > 20
                    ? item.username.substring(0, 20) + '…'
                    : item.username,
                  item.score.toString(),
                ],
              }))}
          />
        </>
      )}

      {current.matches('end') && challengeResult && (
        <Result
          result={challengeResult}
          onReady={() => {
            navigate('/');
          }}
        />
      )}
    </div>
  );
}

export { OfflineGame };
