import { Route, Routes } from 'react-router-dom';

import { Layout } from './components/core/layout/Layout';
import { useAppHeight } from './hooks/useAppHeight';
import { AboutPage } from './pages/AboutPage';
import { HomePage } from './pages/HomePage';
import { SettingsPage } from './pages/SettingsPage';
import { AppErrorsPage } from './pages/app/errors/AppErrorsPage';
import { AptitudeReviewQuestionsPage } from './pages/aptitude/admin/review/AptitudeReviewQuestionsPage';
import { AptitudeBookmarkedQuestionsPage } from './pages/aptitude/bookmarks/AptitudeBookmarkedQuestionsPage';
import { AptitudeCompetitiveQuestionnairesPage } from './pages/aptitude/competitive-questionnaires/AptitudeCompetitiveQuestionnairesPage';
import { AptitudeContributePage } from './pages/aptitude/contribute/AptitudeContributePage';
import { AptitudeErrorsPage } from './pages/aptitude/errors/AptitudeErrorsPage';
import { AptitudeQuestionnaireHistoryPage } from './pages/aptitude/history/AptitudeQuestionnaireHistoryPage';
import { AptitudeQuestionnairesPage } from './pages/aptitude/questionnaires/AptitudeQuestionnairesPage';
import { AptitudeQuestionnairePage } from './pages/aptitude/questionnaires/questionnaire/AptitudeQuestionnairePage';
import { AptitudeQuestionnaireResultPage } from './pages/aptitude/questionnaires/result/AptitudeQuestionnaireResultPage';
import { AptitudeSimpleQuestionnairePage } from './pages/aptitude/questionnaires/simple-questionnaire/AptitudeSimpleQuestionnairePage';
import { ChallengePage } from './pages/challenges/ChallengePage';
import { EnglishReviewQuestionsPage } from './pages/english/admin/review/EnglishReviewQuestionsPage';
import { EnglishBookmarkedQuestionsPage } from './pages/english/bookmarks/EnglishBookmarkedQuestionsPage';
import { EnglishContributePage } from './pages/english/contribute/EnglishContributePage';
import { EnglishErrorsPage } from './pages/english/errors/EnglishErrorsPage';
import { EnglishQuestionnaireHistoryPage } from './pages/english/history/EnglishQuestionnaireHistoryPage';
import { EnglishQuestionnairesPage } from './pages/english/questionnaires/EnglishQuestionnairesPage';
import { EnglishQuestionnairePage } from './pages/english/questionnaires/questionnaire/EnglishQuestionnairePage';
import { EnglishQuestionnaireResultPage } from './pages/english/questionnaires/result/EnglishQuestionnaireResultPage';
import { EnglishSimpleQuestionnairePage } from './pages/english/questionnaires/simple-questionnaire/EnglishSimpleQuestionnairePage';
import { StatisticsPage } from './pages/statistics/StatisticsPage';
import { TheoryQuestionsListPage } from './pages/theory/admin/question-list/TheoryQuestionsListPage';
import { TheoryReviewQuestionsPage } from './pages/theory/admin/review/TheoryReviewQuestionsPage';
import { TheoryBookmarkedQuestionsPage } from './pages/theory/bookmarks/TheoryBookmarkedQuestionsPage';
import { TheoryCompetitiveQuestionnairesPage } from './pages/theory/competitive-questionnaires/TheoryCompetitiveQuestionnairesPage';
import { TheoryContributePage } from './pages/theory/contribute/TheoryContributePage';
import { TheoryErrorsPage } from './pages/theory/errors/TheoryErrorsPage';
import { TheoryQuestionnaireHistoryPage } from './pages/theory/history/TheoryQuestionnaireHistoryPage';
import { TheoryOfficialExamsPage } from './pages/theory/official-exams/TheoryOfficialExamsPage';
import { TheoryQuestionnairesPage } from './pages/theory/questionnaires/TheoryQuestionnairesPage';
import { TheoryQuestionnairePage } from './pages/theory/questionnaires/questionnaire/TheoryQuestionnairePage';
import { TheoryQuestionnaireResultPage } from './pages/theory/questionnaires/result/TheoryQuestionnaireResultPage';
import { TheorySimpleQuestionnairePage } from './pages/theory/questionnaires/simple-questionnaire/TheorySimpleQuestionnairePage';
import { TheoryRegulationsPage } from './pages/theory/regulations/TheoryRegulationsPage';
import { TheorySchemasPage } from './pages/theory/schemas/TheorySchemasPage';
import { TheorySchemasContributePage } from './pages/theory/schemas/contribute/TheorySchemasContributePage';
import { TheorySchemasErrorsPage } from './pages/theory/schemas/errors/TheorySchemasErrorsPage';

function App() {
  useAppHeight();

  return (
    <Layout>
      <Routes>
        {/* Index */}
        <Route path="/" element={<HomePage />} />

        {/* Challenges */}
        <Route path="/desafios/one-to-one" element={<ChallengePage />} />

        {/* Theory */}
        <Route path="/teoria" element={<TheoryQuestionnairesPage />} />
        <Route
          path="/teoria/test"
          element={<TheorySimpleQuestionnairePage />}
        />
        <Route path="/teoria/simulacro" element={<TheoryQuestionnairePage />} />
        <Route path="/teoria/esquemas" element={<TheorySchemasPage />} />
        <Route path="/teoria/normativa" element={<TheoryRegulationsPage />} />
        <Route
          path="/teoria/preguntas/guardadas"
          element={<TheoryBookmarkedQuestionsPage />}
        />
        <Route
          path="/teoria/simulacros/competitivos"
          element={<TheoryCompetitiveQuestionnairesPage />}
        />
        <Route
          path="/teoria/simulacros/historico"
          element={<TheoryQuestionnaireHistoryPage />}
        />
        <Route
          path="/teoria/simulacros/resultados/:id"
          element={<TheoryQuestionnaireResultPage />}
        />
        <Route path="/teoria/examenes" element={<TheoryOfficialExamsPage />} />

        {/* Aptitude */}
        <Route path="/psicotecnicos" element={<AptitudeQuestionnairesPage />} />
        <Route
          path="/psicotecnicos/test"
          element={<AptitudeSimpleQuestionnairePage />}
        />
        <Route
          path="/psicotecnicos/simulacro"
          element={<AptitudeQuestionnairePage />}
        />
        <Route
          path="/psicotecnicos/preguntas/guardadas"
          element={<AptitudeBookmarkedQuestionsPage />}
        />
        <Route
          path="/psicotecnicos/simulacros/competitivos"
          element={<AptitudeCompetitiveQuestionnairesPage />}
        />
        <Route
          path="/psicotecnicos/simulacros/historico"
          element={<AptitudeQuestionnaireHistoryPage />}
        />
        <Route
          path="/psicotecnicos/simulacros/resultados/:id"
          element={<AptitudeQuestionnaireResultPage />}
        />

        {/* English */}
        <Route path="/ingles" element={<EnglishQuestionnairesPage />} />
        <Route
          path="/ingles/test"
          element={<EnglishSimpleQuestionnairePage />}
        />
        <Route
          path="/ingles/simulacro"
          element={<EnglishQuestionnairePage />}
        />
        <Route
          path="/ingles/preguntas/guardadas"
          element={<EnglishBookmarkedQuestionsPage />}
        />
        <Route
          path="/ingles/simulacros/historico"
          element={<EnglishQuestionnaireHistoryPage />}
        />
        <Route
          path="/ingles/simulacros/resultados/:id"
          element={<EnglishQuestionnaireResultPage />}
        />

        {/* Statistics */}
        <Route path="/progreso" element={<StatisticsPage />} />

        {/* Contribute */}
        <Route
          path="/contribuir/teoria/preguntas"
          element={<TheoryContributePage />}
        />
        <Route
          path="/contribuir/teoria/esquemas"
          element={<TheorySchemasContributePage />}
        />
        <Route
          path="/contribuir/psicotecnicos/preguntas"
          element={<AptitudeContributePage />}
        />
        <Route
          path="/contribuir/ingles/preguntas"
          element={<EnglishContributePage />}
        />

        {/* Feedback */}
        <Route
          path="/admin/teoria/preguntas/feedback"
          element={<TheoryErrorsPage />}
        />
        <Route
          path="/admin/teoria/esquemas/feedback"
          element={<TheorySchemasErrorsPage />}
        />
        <Route
          path="/admin/psicotecnicos/preguntas/feedback"
          element={<AptitudeErrorsPage />}
        />
        <Route
          path="/admin/ingles/preguntas/feedback"
          element={<EnglishErrorsPage />}
        />
        <Route path="/admin/feedback" element={<AppErrorsPage />} />

        {/* Admin */}
        <Route
          path="/admin/teoria/listado"
          element={<TheoryQuestionsListPage />}
        />
        <Route
          path="/admin/teoria/preguntas/revision"
          element={<TheoryReviewQuestionsPage />}
        />
        <Route
          path="/admin/psicotecnicos/preguntas/revision"
          element={<AptitudeReviewQuestionsPage />}
        />
        <Route
          path="/admin/ingles/preguntas/revision"
          element={<EnglishReviewQuestionsPage />}
        />

        {/* Other */}
        <Route path="/info" element={<AboutPage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </Layout>
  );
}

export { App };
