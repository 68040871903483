import { useEffect } from 'react';

import { Game } from '../../components/features/home/challenges/one-to-one/Game';
import { useSidebarActions } from '../../state/sidebarStore';

function ChallengePage() {
  const { setShowBtnToggle } = useSidebarActions();

  useEffect(() => {
    setShowBtnToggle(false);
    return () => {
      setShowBtnToggle(true);
    };
  }, []);

  return <Game />;
}

export { ChallengePage };
