import { useEffect, useState } from 'react';

import { Card, List, ListSkeleton } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';

import { Empty } from '../../../components/core/Empty';
import { Loading } from '../../../components/core/Loading';
import { OffsetPagination } from '../../../components/shared/offset-pagination/OffsetPagination';
import { Page } from '../../../components/shared/page/Page';
import {
  FeedbackReportDto,
  useFeedback,
} from '../../../services/api/hooks/useFeedback';
import { OffsetPaginationResponse } from '../../../services/api/hooks/usePageRequest';
import { formatLongDate } from '../../../utils/date';

function AppErrorsPage() {
  const [paginationResponse, setPaginationResponse] =
    useState<OffsetPaginationResponse>();
  const [pageNumber, setPageNumber] = useState(1);
  const [feedback, setFeedback] = useState<FeedbackReportDto[]>([]);

  const { getReportedErrors } = useFeedback();

  const {
    data: response,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ['getReportedErrors', pageNumber],
    queryFn: () => getReportedErrors(pageNumber),
  });

  useEffect(() => {
    if (response?.data !== undefined) {
      setPaginationResponse(response.data);
      setFeedback(response.data.data);
    }
  }, [response]);

  if (isLoading) {
    return <Loading />;
  }

  if (isSuccess && response?.data?.data.length === 0) {
    return <Empty title="No hay errores en la app reportados 🎉" />;
  }

  return (
    <Page title="Errores reportados">
      <Card title="Errores en la app">
        {isLoading ? (
          <ListSkeleton items={15} />
        ) : (
          <>
            <List
              items={feedback.map((item) => ({
                text: item.description + ' | ' + item.email,
                info: formatLongDate(new Date(item.date)),
              }))}
            />
            <OffsetPagination
              pagination={paginationResponse}
              onPageChange={(page) => setPageNumber(page)}
            />
          </>
        )}
      </Card>
    </Page>
  );
}

export { AppErrorsPage };
