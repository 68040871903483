import { useEffect, useRef, useState } from 'react';

import { Card, CardPopup } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';

import { SchemaViewer } from '../../../components/features/schemas/SchemaViewer';
import { SchemasTable } from '../../../components/features/schemas/SchemasTable';
import { Page } from '../../../components/shared/page/Page';
import {
  TheorySchemaSummaryDto,
  useTheorySchema,
} from '../../../services/api/hooks/useTheorySchema';

import styles from './TheorySchemasPage.module.scss';

function TheorySchemasPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [schemaSummary, setSchemaSummary] = useState<TheorySchemaSummaryDto[]>(
    [],
  );
  const [current, setCurrent] = useState<string>();

  const { getSchemaSummary } = useTheorySchema();

  const nodeRef = useRef<HTMLDivElement>(null);

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['getSchemaSummary'],
    queryFn: getSchemaSummary,
    gcTime: 5 * (60 * 1000),
  });

  useEffect(() => {
    if (response && response.data) {
      setSchemaSummary(response.data);
    }
  }, [response]);

  return (
    <Page title="Esquemas de teoría">
      {isOpen && current ? (
        <CardPopup
          className={styles['card-popup']}
          onClose={() => setIsOpen(false)}
          refClose={nodeRef}
          closeable={false}
        >
          <SchemaViewer
            id={current}
            onClose={() => {
              refetch();
              nodeRef.current?.click();
            }}
          />
        </CardPopup>
      ) : null}
      <Card>
        <SchemasTable
          items={schemaSummary}
          loading={isLoading}
          onSelect={(id) => {
            setCurrent(id);
            setIsOpen(!isOpen);
          }}
        />
      </Card>
    </Page>
  );
}

export { TheorySchemasPage };
