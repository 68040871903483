import classnames from 'classnames';

import { BaseComponentProps } from '../../../../types/base-component.types';

import styles from './Header.module.scss';

type ContainerProps = BaseComponentProps & {
  title?: string;
};

function Header({ className, title }: ContainerProps) {
  return (
    <div className={classnames(styles['main'], className)}>
      <div className={styles['title']}>{title}</div>
    </div>
  );
}

export { Header };
