import { useState } from 'react';

import {
  Button,
  ButtonGroup,
  CardPopup,
  FormFeedback,
  FormGroup,
  FormLabel,
  SelectDropdown,
} from '@sealfye/ui-components';
import classnames from 'classnames';
import {
  Form,
  Formik,
  FormikErrors,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import * as Yup from 'yup';

import { BaseComponentProps } from '../../../types/base-component.types';

import styles from './Calculator.module.scss';

type ContainerProps = BaseComponentProps & {
  onClose: () => void;
};

interface FormValues {
  correctAnswers: number;
  wrongAnswers: number;
  totalAnswers: number;
  numberOfResponses: number;
}

function Calculator({
  className,
  onClose,
  testId = 'ui-calculator',
}: ContainerProps) {
  const [mark, setMark] = useState<number | undefined>(undefined);

  return (
    <CardPopup
      className={classnames(styles['main'], className)}
      onClose={onClose}
      animation="bounceInUp"
      data-test-id={testId}
    >
      <div className={styles['wrapper']}>
        <div className={styles['icon']}>{<span>✖️➕➖➗</span>}</div>
        <Formik
          enableReinitialize
          validate={async (values: FormValues) => {
            const errors: FormikErrors<FormValues> = {};

            const validationSchema = Yup.object().shape({
              totalAnswers: Yup.number()
                .typeError('Este valor debe ser numérico.')
                .min(0, 'Este valor no puede ser negativo.')
                .max(100, 'Este valor no puede ser mayor que cien.'),
              correctAnswers: Yup.number().when(
                'totalAnswers',
                ([totalAnswers], schema) => {
                  return totalAnswers
                    ? schema
                        .typeError('Este valor debe ser numérico.')
                        .min(0, 'Este valor no puede ser negativo.')
                        .max(
                          totalAnswers,
                          'Este valor no puede ser mayor que el total de preguntas.',
                        )
                    : schema
                        .typeError('Este valor debe ser numérico.')
                        .min(0, 'Este valor no puede ser negativo.')
                        .max(100, 'Este valor no puede ser mayor que cien.');
                },
              ),
              wrongAnswers: Yup.number()
                .when('totalAnswers', ([totalAnswers], schema) => {
                  return totalAnswers
                    ? schema
                        .typeError('Este valor debe ser numérico.')
                        .min(0, 'Este valor no puede ser negativo.')
                        .max(
                          totalAnswers,
                          'Este valor no puede ser mayor que el total de preguntas.',
                        )
                    : schema
                        .typeError('Este valor debe ser numérico.')
                        .min(0, 'Este valor no puede ser negativo.')
                        .max(100, 'Este valor no puede ser mayor que cien.');
                })
                .when(
                  ['totalAnswers', 'correctAnswers'],
                  ([totalAnswers, correctAnswers], schema) => {
                    return correctAnswers
                      ? schema
                          .typeError('Este valor debe ser numérico.')
                          .min(0, 'Este valor no puede ser negativo.')
                          .max(
                            totalAnswers - correctAnswers,
                            'Este valor no puede ser mayor que el total de preguntas menos el número de aciertos.',
                          )
                      : schema
                          .typeError('Este valor debe ser numérico.')
                          .min(0, 'Este valor no puede ser negativo.')
                          .max(100, 'Este valor no puede ser mayor que cien.');
                  },
                ),
            });

            try {
              validateYupSchema<FormValues>(values, validationSchema, true);
            } catch (err) {
              return yupToFormErrors(err);
            }

            if (
              +values.correctAnswers + +values.wrongAnswers >
              +values.totalAnswers
            ) {
              errors.totalAnswers =
                'La suma de aciertos y errores no puede ser mayor que el total de preguntas.';
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);

            let mark =
              10 *
              ((values.correctAnswers -
                values.wrongAnswers / (values.numberOfResponses - 1)) /
                values.totalAnswers);

            mark = mark < 0 ? 0 : mark;
            setMark(Math.round(mark * 100) / 100);

            setSubmitting(false);
          }}
          initialValues={{
            totalAnswers: 100,
            correctAnswers: 0,
            wrongAnswers: 0,
            numberOfResponses: 3,
          }}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleSubmit,
          }) => (
            <Form className={styles['form']} noValidate onSubmit={handleSubmit}>
              <FormGroup>
                <FormLabel>Número de preguntas</FormLabel>
                <SelectDropdown
                  onSelect={(value) => {
                    handleChange({
                      target: {
                        name: 'totalAnswers',
                        value,
                      },
                    });
                  }}
                  options={Array.from(Array(100).keys())
                    .reverse()
                    .map((value) => ({
                      value: value + 1,
                      label: (value + 1).toString(),
                      selected: value + 1 === values.totalAnswers,
                    }))}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Número de aciertos</FormLabel>
                <SelectDropdown
                  onSelect={(value) => {
                    handleChange({
                      target: {
                        name: 'correctAnswers',
                        value,
                      },
                    });
                  }}
                  options={Array.from(Array(101).keys()).map((value) => ({
                    value: value,
                    label: value.toString(),
                    selected: value === values.correctAnswers,
                    disabled: value > values.totalAnswers,
                  }))}
                />
                {touched.correctAnswers && errors.correctAnswers && (
                  <FormFeedback variant="danger">
                    {errors.correctAnswers}
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <FormLabel>Número de errores</FormLabel>
                <SelectDropdown
                  onSelect={(value) => {
                    handleChange({
                      target: {
                        name: 'wrongAnswers',
                        value,
                      },
                    });
                  }}
                  options={Array.from(Array(101).keys()).map((value) => ({
                    value: value,
                    label: value.toString(),
                    selected: value === values.wrongAnswers,
                    disabled:
                      value > values.totalAnswers - values.correctAnswers,
                  }))}
                />
                {touched.wrongAnswers && errors.wrongAnswers && (
                  <FormFeedback variant="danger">
                    {errors.wrongAnswers}
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <FormLabel>Número de alternativas de respuesta</FormLabel>
                <ButtonGroup
                  onSelect={(value) => {
                    handleChange({
                      target: {
                        name: 'numberOfResponses',
                        value,
                      },
                    });
                  }}
                  options={[2, 3, 4].map((value) => ({
                    value: value,
                    label: value.toString(),
                    selected: value === values.numberOfResponses,
                  }))}
                />
              </FormGroup>
              <FormGroup>
                <FormFeedback>
                  Se utilizará la fórmula: [ A - E / (n - 1) ] * 10 / P, siendo
                  «A» el número de aciertos, «E» el de errores y «n» número de
                  alternativas de respuesta y «P» el número total de preguntas.
                </FormFeedback>
              </FormGroup>
              <div className={styles['footer']}>
                <Button
                  className={styles['button']}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                >
                  Calcular
                </Button>
                <span className={styles['mark']}>{mark}</span>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </CardPopup>
  );
}

export { Calculator };
