import classnames from 'classnames';

import { useEditor, useEditorActions } from '../../../state/editorStore';
import { BaseComponentProps } from '../../../types/base-component.types';
import { AptitudeQuestionEditor } from '../../features/aptitude/contribute/AptitudeQuestionEditor';
import { EnglishQuestionEditor } from '../../features/english/contribute/EnglishQuestionEditor';
import { TheorySchemaEditor } from '../../features/schemas/contribute/TheorySchemaEditor';
import { TheoryQuestionEditor } from '../../features/theory/contribute/TheoryQuestionEditor';

import styles from './EditorPopup.module.scss';

function EditorPopup({
  className,
  testId = 'ui-editor-popup',
}: BaseComponentProps) {
  const editor = useEditor();
  const { closeEditor } = useEditorActions();

  if (editor === undefined) {
    return null;
  }

  if (editor.type === 'theory') {
    return (
      <TheoryQuestionEditor
        className={classnames(styles['main'], className)}
        testId={testId}
        id={editor.id}
        onClose={closeEditor}
      />
    );
  }

  if (editor.type === 'aptitude') {
    return (
      <AptitudeQuestionEditor
        className={classnames(styles['main'], className)}
        testId={testId}
        id={editor.id}
        onClose={closeEditor}
      />
    );
  }

  if (editor.type === 'english') {
    return (
      <EnglishQuestionEditor
        className={classnames(styles['main'], className)}
        testId={testId}
        id={editor.id}
        onClose={closeEditor}
      />
    );
  }

  if (editor.type === 'schema') {
    return (
      <TheorySchemaEditor
        className={classnames(styles['main'], className)}
        testId={testId}
        id={editor.id}
        onClose={closeEditor}
      />
    );
  }

  return null;
}

export { EditorPopup };
