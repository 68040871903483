import {
  Button,
  Card,
  FormFeedback,
  FormGroup,
  FormLabel,
  Input,
  SelectDropdown,
  SwitchCheck,
} from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useTheoryLesson } from '../../../../services/api/hooks/useTheoryLesson';
import { BaseComponentProps } from '../../../../types/base-component.types';

import styles from './TheoryQuestionListFilter.module.scss';

interface TheoryQuestionListFilterForm {
  group?: number;
  contains?: string;
  trial?: boolean;
}

type ContainerProps = BaseComponentProps & {
  values: TheoryQuestionListFilterForm;
  onSubmit: (values: TheoryQuestionListFilterForm) => void;
};

function TheoryQuestionListFilter({
  className,
  values,
  onSubmit,
  testId = 'ui-questions-filter',
}: ContainerProps) {
  const { getTheoryLessons } = useTheoryLesson();

  const { data: response, isLoading } = useQuery({
    queryKey: ['getTheoryLessons'],
    queryFn: () => getTheoryLessons(),
  });

  return (
    <Card
      title="Filtros"
      className={classnames(styles['main'], className)}
      loading={isLoading}
      testId={testId}
    >
      <Formik
        enableReinitialize
        validationSchema={Yup.object().shape({
          contains: Yup.string().max(
            2000,
            'La descripción debe contener menos de 2000 caracteres.',
          ),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);

          onSubmit(values);

          setSubmitting(false);
        }}
        initialValues={{
          group: values.group || 1,
          contains: values.contains || '',
          trial: values.trial || false,
        }}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit,
        }) => (
          <Form className={styles['form']} noValidate onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>Tema</FormLabel>
              <SelectDropdown
                onSelect={(value) => {
                  handleChange({
                    target: {
                      name: 'group',
                      value,
                    },
                  });
                }}
                error={touched.group && !!errors.group}
                options={
                  response?.data?.map((lesson) => ({
                    value: lesson.id,
                    label: lesson.id + ' - ' + lesson.title,
                    selected: values.group === lesson.id,
                  })) || []
                }
              />
              <FormFeedback>
                El temario de las oposiciones para Policía Nacional consta 44
                temas divididos en tres bloques: ciencias jurídicas (1 al 26),
                ciencias sociales (27 al 36), materias técnico-científicas (37
                al 44).
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <FormLabel>Contenido</FormLabel>
              <Input
                id="contains"
                value={values.contains}
                onChange={handleChange}
                error={touched.contains && !!errors.contains}
              />
              {touched.contains && errors.contains && (
                <FormFeedback variant="danger">{errors.contains}</FormFeedback>
              )}
              <FormFeedback>
                Filtra por el contenido de la pregunta, puedes insertar un
                fragmento de texto que creas que la pregunta puede contener. El
                texto tiene que ser exactamente como aparece en la pregunta,
                incluyendo tildes y signos de puntuación.
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <FormLabel>Trial</FormLabel>
              <SwitchCheck
                label="Pregunta para usuarios con licencia gratuita"
                checked={values.trial}
                onChange={() => {
                  handleChange({
                    target: {
                      name: 'trial',
                      value: !values.trial,
                    },
                  });
                }}
              />
            </FormGroup>

            <div className={styles['footer']}>
              <Button
                className={styles['button']}
                loading={isSubmitting}
                disabled={isSubmitting}
                variant="primary"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Buscar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

export { TheoryQuestionListFilter };
