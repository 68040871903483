import { memo, useEffect, useRef, useState } from 'react';

import {
  CardPopup,
  QuestionAnswer,
  QuestionnaireResult as Result,
} from '@sealfye/ui-components';
import classnames from 'classnames';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';

import { QuestionAnswerType } from '../../../types/Question.types';
import { equal } from '../../../utils/equality';
import { QuestionnaireResultProps } from './QuestionnaireResult.types';

import styles from './QuestionnaireResult.module.scss';

function InnerQuestionnaireResult({
  className,
  mark,
  elapsedTime,
  correctAnswers,
  wrongAnswers,
  emptyAnswers,
  isCompetitive,
  questions,
  onBookmark,
  onUnbookmark,
  onFeedback,
  onEdit,
  onClose,
}: QuestionnaireResultProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [question, setQuestion] = useState<QuestionAnswerType>(
    questions[index],
  );

  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setQuestion(questions[index]);
  }, [index]);

  return (
    <div className={classnames(styles['main'], className)}>
      <div className={classnames(styles['wrapper'], className)}>
        <Result
          className={styles['questionnaire-result']}
          labels={{
            results: 'Resultados',
            description: 'Simulacro de examen',
          }}
          mark={mark}
          elapsedTime={elapsedTime}
          correctAnswers={correctAnswers}
          wrongAnswers={wrongAnswers}
          emptyAnswers={emptyAnswers}
          isCompetitive={isCompetitive}
          questions={questions}
          onSelect={(id) => {
            setIndex(questions.findIndex((q) => q.id === id));
            setIsOpen(true);
          }}
          onClose={onClose}
        />
      </div>
      {isOpen ? (
        <CardPopup
          className={styles['card-popup']}
          refClose={nodeRef}
          onClose={() => setIsOpen(!isOpen)}
          closeable={false}
        >
          <QuestionAnswer
            className={styles['answer']}
            question={question}
            onBookmark={onBookmark}
            onUnbookmark={onUnbookmark}
            onFeedback={onFeedback}
            onEdit={onEdit}
            onClose={() => nodeRef.current?.click()}
            background="main"
          >
            <div className={styles['index']}>
              <IoChevronBackOutline
                style={{
                  cursor: index > 0 ? 'pointer' : 'default',
                  opacity: index > 0 ? 1 : 0.1,
                }}
                onClick={() => setIndex(index > 0 ? index - 1 : index)}
              />
              <span>{index + 1}</span>
              <IoChevronForwardOutline
                style={{
                  cursor: index < questions.length - 1 ? 'pointer' : 'default',
                  opacity: index < questions.length - 1 ? 1 : 0.1,
                }}
                onClick={() =>
                  setIndex(index < questions.length - 1 ? index + 1 : index)
                }
              />
            </div>
          </QuestionAnswer>
        </CardPopup>
      ) : null}
    </div>
  );
}

function propsAreEqual(
  prev: QuestionnaireResultProps,
  next: QuestionnaireResultProps,
) {
  return equal(prev, next);
}

export const QuestionnaireResult = memo(
  InnerQuestionnaireResult,
  propsAreEqual,
);
