import { useEffect, useState } from 'react';

import { Questionnaire } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Loading } from '../../../../components/core/Loading';
import { useEnglishQuestion } from '../../../../services/api/hooks/useEnglishQuestion';
import { useEnglishTest } from '../../../../services/api/hooks/useEnglishTest';
import { useSidebarActions } from '../../../../state/sidebarStore';
import { QuestionType } from '../../../../types/Question.types';

import styles from './EnglishQuestionnairePage.module.scss';

function EnglishQuestionnairePage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setShowBtnToggle } = useSidebarActions();

  const { bookmarkEnglishQuestion, unbookmarkEnglishQuestion } =
    useEnglishQuestion();

  const { getBookmarkedEnglishTest, getRandomEnglishTest, markEnglishTest } =
    useEnglishTest();

  useEffect(() => {
    setShowBtnToggle(false);
    return () => {
      setShowBtnToggle(true);
    };
  }, []);

  const { data: response, isLoading } = useQuery({
    queryKey: ['getEnglishQuestionnairePage', searchParams],
    queryFn: () => {
      if (searchParams.get('guardadas')) {
        return getBookmarkedEnglishTest();
      } else if (searchParams.get('preguntas') && searchParams.get('temas')) {
        return getRandomEnglishTest({
          size: +searchParams.get('preguntas')!,
          categories: searchParams
            .get('temas')!
            .split(',')
            .map((item) => parseInt(item, 10)),
        });
      }
    },
  });

  useEffect(() => {
    if (response && !response.success) {
      navigate('/');
    }
  }, [response]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={styles['main']}>
      {response && response.data && (
        <Questionnaire
          className={styles['questionnaire']}
          timeInSeconds={response.data.timeInSeconds}
          questions={response.data.questions.map((question) => ({
            id: question.id,
            text: question.question,
            options: question.answers,
            selectedOption: question.selectedAnswer,
            bookmarked: question.bookmarked,
          }))}
          onBookmark={async (id) => {
            const response = await bookmarkEnglishQuestion(id);
            if (!response.success) {
              throw new Error("Couldn't bookmark question");
            }
          }}
          onUnbookmark={async (id) => {
            const response = await unbookmarkEnglishQuestion(id);
            if (!response.success) {
              throw new Error("Couldn't unbookmark question");
            }
          }}
          isSubmitting={isSubmitting}
          onSubmit={async (questions: QuestionType[]) => {
            setIsSubmitting(true);

            const markResponse = await markEnglishTest({
              id: response!.data!.id,
              timeInSeconds: response!.data!.timeInSeconds,
              questions: questions.map((question) => ({
                id: question.id,
                selectedAnswer: question.selectedOption,
              })),
            });

            if (markResponse.success && markResponse.data !== undefined) {
              navigate(`/ingles/simulacros/resultados/${markResponse.data.id}`);
            }

            setIsSubmitting(false);
          }}
          onClose={() => {
            navigate(`/ingles/`);
          }}
        />
      )}
    </div>
  );
}

export { EnglishQuestionnairePage };
