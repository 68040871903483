import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type EditorType = 'theory' | 'schema' | 'aptitude' | 'english';

export interface EditorPopup {
  type: EditorType;
  id: string;
  onEdit?: () => void;
  onDelete?: () => void;
}

export interface EditorState {
  editor?: EditorPopup;
  actions: {
    openEditor: (
      type: EditorType,
      id: string,
      onEdit?: () => void,
      onDelete?: () => void,
    ) => void;
    closeEditor: () => void;
  };
}

export const useEditorStore = create<EditorState>()(
  devtools((set) => ({
    editor: undefined,
    actions: {
      openEditor: (
        type: EditorType,
        id: string,
        onEdit?: () => void,
        onDelete?: () => void,
      ) =>
        set(() => {
          return {
            editor: {
              id,
              type,
              onEdit,
              onDelete,
            },
          };
        }),
      closeEditor: () =>
        set(() => ({
          editor: undefined,
        })),
    },
  })),
);

export const useEditor = () => useEditorStore((state) => state.editor);

export const useEditorActions = () => useEditorStore((state) => state.actions);
