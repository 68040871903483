/* eslint-disable no-empty */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
} from '@microsoft/signalr';
import {
  QuestionAnswer,
  Ranking,
  SimpleQuestion,
} from '@sealfye/ui-components';
import { useMachine } from '@xstate/react';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { assign, createMachine } from 'xstate';

import { useAuth } from '../../../../../context/AuthContext';
import { useConfiguration } from '../../../../../context/ConfigurationContext';
import {
  ChallengeResult,
  QuestionResult,
  Room,
  useChallenge,
} from '../../../../../services/api/hooks/useChallenge';
import {
  TheoryQuestionDto,
  useTheoryQuestion,
} from '../../../../../services/api/hooks/useTheoryQuestion';
import { useToasterActions } from '../../../../../state/toasterStore';
import { QuestionAnswerType } from '../../../../../types/Question.types';
import { BaseComponentProps } from '../../../../../types/base-component.types';
import { sleep } from '../../../../../utils/crosscutting';
import { MessagePopup } from '../../../../shared/message-popup/MessagePopup';
import { Countdown } from '../layout/Countdown';
import { OfflineGame } from './OfflineGame';
import { Presentation } from './Presentation';
import { Result } from './Result';
import { Searching } from './Searching';

import styles from './Game.module.scss';

function Game({ className, testId = 'ui-challenge' }: BaseComponentProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { configuration } = useConfiguration();
  const { sendMessage } = useToasterActions();

  const [connection, setConnection] = useState<HubConnection>();

  const [questionIndex, setQuestionIndex] = useState(1);
  const [showDisconnectedPopup, setShowDisconnectedPopup] = useState(false);
  const [offlineChallenge, setOfflineChallenge] = useState<Room>();
  const [challenge, setChallenge] = useState<Room>();
  const [question, setQuestion] = useState<QuestionAnswerType>();
  const [questionResult, setQuestionResult] = useState<QuestionResult>();
  const [challengeResult, setChallengeResult] = useState<ChallengeResult>();

  const { createOfflineChallenge } = useChallenge();

  const { bookmarkTheoryQuestion, unbookmarkTheoryQuestion } =
    useTheoryQuestion();

  const [current, send] = useMachine(() =>
    createMachine(
      {
        predictableActionArguments: true,
        id: 'challenge',
        initial: 'searching',
        context: {
          retries: 0,
        },
        states: {
          searching: {
            on: {
              READY: 'success',
              REJECT: 'failure',
            },
          },
          success: {
            initial: 'presentation',
            states: {
              presentation: {
                on: {
                  PLAYERS_READY: { target: 'countdown' },
                },
              },
              countdown: {
                on: {
                  NEXT: { target: 'question' },
                },
              },
              question: {
                on: {
                  SELECT: { target: 'solution' },
                },
              },
              solution: {
                on: {
                  RESULT: { target: 'result' },
                  END: { target: 'end' },
                },
              },
              result: {
                on: {
                  NEXT: [
                    { target: 'question', cond: 'isNextQuestion' },
                    { target: 'end' },
                  ],
                  END: { target: 'end' },
                },
              },
              end: {
                type: 'final',
              },
            },
          },
          failure: {
            on: {
              RETRY: {
                target: 'searching',
                actions: assign({
                  retries: (context: any) => context.retries + 1,
                }),
              },
            },
          },
        },
      },
      {
        guards: {
          isNextQuestion: () => true,
        },
      },
    ),
  );

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${configuration.app?.apiUrl}/hubs/challenge`, {
        accessTokenFactory: async () => await user?.getIdToken(),
        withCredentials: false,
        transport: HttpTransportType.WebSockets,
      } as IHttpConnectionOptions)
      .withAutomaticReconnect()
      .build();

    newConnection.serverTimeoutInMilliseconds = 100000; // 100 second

    newConnection
      .start()
      .then(() => {
        if (
          newConnection &&
          newConnection.state == HubConnectionState.Connected
        ) {
          return newConnection.send('JoinChallenge');
        }
      })
      .catch((error) => {
        if (
          error
            .toString()
            .includes('The connection was stopped during negotiation')
        ) {
          return;
        }

        sendMessage(
          '¡Ups!',
          'Parece que hay algún error con los desafíos, intentalo más tarde.',
          'danger',
        );
      });

    setConnection(newConnection);

    return () => {
      newConnection.stop();
    };
  }, [configuration.app?.apiUrl, sendMessage, user]);

  useEffect(() => {
    if (connection != null) {
      connection.on('ChallengeReady', (challenge: Room) => {
        setChallenge(challenge);
        send('READY');
      });

      connection.on('WaitingForOpponent', (challenge: Room) => {
        setChallenge(challenge);
      });

      connection.on('PlayersReady', () => {
        send('PLAYERS_READY');
      });

      connection.on('ReceiveQuestion', (question: TheoryQuestionDto) => {
        setQuestionIndex(
          (questionIndex) => (questionIndex = questionIndex + 1),
        );
        setQuestion({
          id: question.id,
          text: question.question,
          options: question.answers,
          subject: 'Tema ' + question.lesson?.toString(),
          selectedOption: 0,
          bookmarked: question.bookmarked ?? false,
          correctOption: question.correctAnswer ?? 0,
          reason: question.reason,
          correctAnswerRate: question.correctAnswerRate ?? 0,
          emptyAnswerRate: question.emptyAnswerRate ?? 0,
          wrongAnswerRate: question.wrongAnswerRate ?? 0,
        });
        send('NEXT');
      });

      connection.on('ReceiveQuestionResult', (result: QuestionResult) => {
        setQuestionResult(result);
        send('RESULT');
      });

      connection.on('ReceiveChallengeResult', (result: ChallengeResult) => {
        setChallengeResult(result);
        send('END');
      });

      connection.on('OpponentDisconnected', () => {
        setShowDisconnectedPopup(true);
      });
    }
  }, [connection, send]);

  const onReadyForChallenge = async () => {
    if (connection && connection.state == HubConnectionState.Connected) {
      try {
        await connection.send('ReadyForChallenge', challenge?.id);
      } catch {}
    }
  };

  const onNext = async () => {
    if (connection && connection.state == HubConnectionState.Connected) {
      try {
        await connection.send('ReadyForNextQuestion', challenge?.id);
        setQuestionResult(undefined);
      } catch {}
    }
  };

  const onSendResponse = async (r: number) => {
    if (connection && connection.state == HubConnectionState.Connected) {
      try {
        await connection.send('SendResponse', challenge?.id, question?.id, r);
      } catch {}
    }
  };

  const onTimeout = async () => {
    if (connection && connection.state == HubConnectionState.Connected) {
      try {
        await connection.stop();
        const response = await createOfflineChallenge();
        if (response.success && response.data !== undefined) {
          setOfflineChallenge(response.data);
        }

        send('REJECT');
      } catch {}
    }
  };

  const getTimeoutSeconds = () => {
    const now = new Date();
    const time = now.getHours() * 60 + now.getMinutes();

    if (time >= 8 * 60 + 30 && time < 24 * 60) {
      return Math.floor(Math.random() * 20) + 10;
    } else if (time >= 0 && time < 1 * 60 + 30) {
      return Math.floor(Math.random() * 40) + 20;
    }

    return Math.floor(Math.random() * 30) * 60;
  };

  return (
    <div
      className={classnames(styles['main'], className)}
      data-test-id={testId}
    >
      {showDisconnectedPopup && (
        <MessagePopup
          icon={<span>🔌</span>}
          title="¡Reto interrumpido!"
          onClose={() => {
            navigate('/');
          }}
          className={styles['player-disconnected']}
        >
          <span>Parece que tu oponente se ha desconectado...</span>
          <p>Te llevas +3 puntos</p>
        </MessagePopup>
      )}

      {current.matches('searching') && (
        <Searching
          seconds={getTimeoutSeconds()}
          onTimeout={() => {
            onTimeout();
          }}
          onQuit={() => {
            navigate('/');
          }}
        />
      )}

      {current.matches('success.presentation') && challenge && (
        <Presentation room={challenge} onReady={onReadyForChallenge} />
      )}

      {current.matches('success.countdown') && challenge && (
        <Countdown
          index={questionIndex}
          seconds={3}
          onTimeout={() => {
            onNext();
          }}
        />
      )}

      {current.matches('success.question') && challenge && question && (
        <SimpleQuestion
          className={styles['question']}
          timeInSeconds={challenge.questionTime}
          question={question}
          onBookmark={async (id) => {
            const response = await bookmarkTheoryQuestion(id);

            if (response.success) {
              setQuestion((prev) => {
                if (prev !== undefined) {
                  return {
                    ...prev,
                    bookmarked: true,
                  };
                } else {
                  return prev;
                }
              });
            }
          }}
          onUnbookmark={async (id) => {
            const response = await unbookmarkTheoryQuestion(id);

            if (response.success) {
              setQuestion((prev) => {
                if (prev !== undefined) {
                  return {
                    ...prev,
                    bookmarked: false,
                  };
                } else {
                  return prev;
                }
              });
            }
          }}
          onSubmit={async (question) => {
            setQuestion((prev) => {
              if (prev !== undefined) {
                return {
                  ...prev,
                  selectedOption: question.selectedOption,
                };
              } else {
                return prev;
              }
            });

            send('SELECT');
            await sleep(2000);
            onSendResponse(question.selectedOption);
          }}
        />
      )}

      {current.matches('success.solution') && question && (
        <QuestionAnswer
          className={styles['question']}
          question={question}
          onBookmark={async (id) => {
            const response = await bookmarkTheoryQuestion(id);

            if (response.success) {
              setQuestion((prev) => {
                if (prev !== undefined) {
                  return {
                    ...prev,
                    bookmarked: true,
                  };
                } else {
                  return prev;
                }
              });
            }
          }}
          onUnbookmark={async (id) => {
            const response = await unbookmarkTheoryQuestion(id);

            if (response.success) {
              setQuestion((prev) => {
                if (prev !== undefined) {
                  return {
                    ...prev,
                    bookmarked: false,
                  };
                } else {
                  return prev;
                }
              });
            }
          }}
        >
          <span className={styles['waiting-for-player']}>
            Esperando a tu rival...
          </span>
        </QuestionAnswer>
      )}

      {current.matches('success.result') && question && questionResult && (
        <>
          <Countdown
            index={questionIndex}
            seconds={3}
            onTimeout={() => {
              onNext();
            }}
          />
          <Ranking
            className={styles['ranking']}
            columns={['Jugador', 'Puntuación']}
            rows={questionResult.topPlayers
              .sort((a, b) => (a.score < b.score ? 1 : -1))
              .map((item) => ({
                cells: [
                  item.username.length > 20
                    ? item.username.substring(0, 20) + '…'
                    : item.username,
                  item.score.toString(),
                ],
              }))}
          />
        </>
      )}

      {current.matches('success.end') && challengeResult && (
        <Result
          result={challengeResult}
          onReady={() => {
            navigate('/');
          }}
        />
      )}

      {current.matches('failure') && offlineChallenge && (
        <OfflineGame challenge={offlineChallenge} />
      )}
    </div>
  );
}

export { Game };
