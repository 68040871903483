import { useEffect, useState } from 'react';

import { Button, Card, Logo } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import MainImg from '../../../../assets/images/main-police.svg';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import {
  RankingDto,
  useTheoryTest,
} from '../../../../services/api/hooks/useTheoryTest';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { formatTimestamp, getMonthName } from '../../../../utils/date';
import { MarksRanking } from '../../../shared/marks-ranking/MarksRanking';

import styles from './CompetitiveQuestionnaireCard.module.scss';

function CompetitiveQuestionnaireCard({ className }: BaseComponentProps) {
  const { isWidthGreaterThan } = useWindowSize();

  const navigate = useNavigate();
  const [didCurrentSimulationTest, setDidCurrentSimulationTest] =
    useState(false);
  const [currentRanking, setCurrentRanking] = useState<RankingDto>();

  const { getCurrentRanking } = useTheoryTest();

  const { data: response, isLoading } = useQuery({
    queryKey: ['getCurrentRanking'],
    queryFn: () => getCurrentRanking(),
  });

  useEffect(() => {
    if (response && response.data !== undefined) {
      setCurrentRanking(response.data);
      setDidCurrentSimulationTest(true);
    }
  }, [response]);

  const onSelect = () => {
    navigate({
      pathname: '/teoria/simulacro',
      search: `?mensual=true`,
    });
  };

  return (
    <Card className={classnames(styles['card'], className)} loading={isLoading}>
      <div className={classnames(styles['main'])}>
        {didCurrentSimulationTest && currentRanking ? (
          <>
            <div className={styles['description']}>
              <Logo className={styles['logo']} variant="icon" />
              <h1>
                Simulacro de{' '}
                {getMonthName(new Date().getUTCMonth()).toLowerCase()}
              </h1>
              <span>Nota obtenida: {currentRanking.mark}</span>
              <span>
                Puesto: {currentRanking.position} de {currentRanking.total}
              </span>
              <span>
                La nota media de los participantes es:{' '}
                {currentRanking.averageScore}
              </span>
            </div>
            <div className={styles['payload']}>
              {currentRanking &&
                currentRanking.userScores &&
                currentRanking.userScores.length > 0 && (
                  <MarksRanking
                    className={styles['marks-ranking']}
                    columns={['Usuario', 'Nota', 'Tiempo']}
                    rows={currentRanking.userScores?.map((userScore) => ({
                      cells: [
                        userScore.username === undefined ||
                        userScore.username === ''
                          ? 'Desconocido'
                          : userScore.username.length > 20
                            ? userScore.username.substring(0, 20) + '…'
                            : userScore.username,
                        userScore.mark.toString(),
                        formatTimestamp(userScore.elapsedTime),
                      ],
                    }))}
                  />
                )}
            </div>
          </>
        ) : (
          <>
            <div className={styles['description']}>
              <Logo className={styles['logo']} variant="icon" />
              <h1>
                ¡Simulacro de{' '}
                {getMonthName(new Date().getUTCMonth()).toLowerCase()}{' '}
                disponible!
              </h1>
              <p>Realiza el simulacro mensual y posiciónate en el ranking</p>
              <Button
                className={styles['button']}
                variant="primary"
                shadow
                onClick={onSelect}
              >
                Empezar el simulacro
              </Button>
            </div>
            {isWidthGreaterThan(768) && (
              <div className={styles['payload']}>
                <img className={styles['image']} src={MainImg} />
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  );
}

export { CompetitiveQuestionnaireCard };
