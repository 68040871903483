import { useEffect, useState } from 'react';

import { ImageViewer } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';

import { useProfile } from '../../../context/ProfileContext';
import {
  TheorySchemaDto,
  useTheorySchema,
} from '../../../services/api/hooks/useTheorySchema';
import { useEditorActions } from '../../../state/editorStore';
import { useFeedbackActions } from '../../../state/feedbackStore';
import { BaseComponentProps } from '../../../types/base-component.types';
import { convertToFileName, getImageExtension } from '../../../utils/files';

export type ContainerProps = BaseComponentProps & {
  id: string;
  onClose: () => void;
};

function SchemaViewer({ id, onClose }: ContainerProps) {
  const [schema, setSchema] = useState<TheorySchemaDto>();
  const { openFeedback } = useFeedbackActions();
  const { openEditor } = useEditorActions();
  const { profile } = useProfile();
  const { getSchema, reportTheorySchemaError } = useTheorySchema();

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['getSchema', id],
    queryFn: () => getSchema(id),
    gcTime: 5 * (60 * 1000), // 5 mins
  });

  useEffect(() => {
    if (response && response.data) {
      setSchema(response.data);
    }
  }, [response]);

  function downloadImage(src: string, description: string) {
    const a = document.createElement('a');
    a.href = src;
    a.download = `${convertToFileName(description)}.${getImageExtension(src)}`;
    a.click();
  }

  return (
    <ImageViewer
      id={isLoading || schema === undefined ? '' : schema.id}
      heading={isLoading || schema === undefined ? '' : `Tema ${schema.lesson}`}
      title={isLoading || schema === undefined ? '' : schema.description}
      src={isLoading || schema === undefined ? '' : schema.schemaPayload}
      onDownload={() => {
        if (schema) {
          downloadImage(schema.schemaPayload, schema.description);
        }
      }}
      onFeedback={
        profile.subscription?.isActive && !profile.subscription?.isTrial
          ? (id) => {
              openFeedback(
                '¿Has visto algún error en este esquema?',
                async (feedback) => {
                  const response = await reportTheorySchemaError(id, feedback);

                  if (!response.success) {
                    throw new Error('Error al enviar el feedback');
                  }
                },
              );
            }
          : undefined
      }
      onEdit={
        profile.role === 'admin'
          ? () => {
              openEditor('schema', id, refetch, onClose);
            }
          : undefined
      }
      onClose={onClose}
      background="main"
    />
  );
}

export { SchemaViewer };
