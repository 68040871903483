import { useState } from 'react';

import {
  Button,
  CardPopup,
  FormFeedback,
  FormGroup,
} from '@sealfye/ui-components';
import classnames from 'classnames';

import { BaseComponentProps } from '../../../types/base-component.types';
import { AptitudeSampleMarks } from './aptitude/AptitudeSampleMarks';
import { TheorySampleMarks } from './theory/TheorySampleMarks';

import styles from './SampleMarks.module.scss';

type ContainerProps = BaseComponentProps & {
  onClose: () => void;
};

function SampleMarks({
  className,
  onClose,
  testId = 'ui-sample-marks',
}: ContainerProps) {
  const [subject, setSubject] = useState<number | undefined>(undefined);

  return (
    <CardPopup
      className={classnames(styles['main'], className)}
      onClose={onClose}
      animation="bounceInUp"
      data-test-id={testId}
    >
      <div className={styles['wrapper']}>
        {subject === undefined && (
          <>
            <div className={styles['icon']}>{<span>📑</span>}</div>
            <div>
              <h2>Selecciona el examen</h2>
              <div className={styles['buttons']}>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setSubject(0);
                  }}
                >
                  Teoría
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setSubject(1);
                  }}
                >
                  Psicotécnico
                </Button>
              </div>
              <FormGroup>
                <FormFeedback>
                  Introduce la nota que hayas calculado con tu plantilla de
                  preguntas para los exámenes oficiales de Escala Básica
                  (promoción XL) de la convocatoria de 2024.
                </FormFeedback>
              </FormGroup>
            </div>
          </>
        )}
        {subject === 0 && <TheorySampleMarks />}
        {subject === 1 && <AptitudeSampleMarks />}
      </div>
    </CardPopup>
  );
}

export { SampleMarks };
