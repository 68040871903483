import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useProfile } from '../../../context/ProfileContext';
import {
  AptitudeCategoryDto,
  useAptitudeCategory,
} from '../../../services/api/hooks/useAptitudeCategory';
import { useAppActions } from '../../../state/appStore';
import { BaseComponentProps } from '../../../types/base-component.types';
import { SelectableListPopup } from '../../shared/selectable-list-popup/SelectableListPopup';

interface CategoryTableItem extends AptitudeCategoryDto {
  active: boolean;
}

export type ContainerProps = BaseComponentProps & {
  onSelect: (id: number[]) => void;
  onClose: () => void;
};

const BLOCK_1 = 'Todas las categorías';

function Categories({ className, onSelect, onClose }: ContainerProps) {
  const { getAptitudeCategories } = useAptitudeCategory();
  const { profile } = useProfile();
  const { setShowGetPremiumPopup } = useAppActions();

  const [categories, setCategories] = useState<CategoryTableItem[]>([]);

  const { data: response, isLoading } = useQuery({
    queryKey: ['getAptitudeCategories'],
    queryFn: () => getAptitudeCategories(),
    gcTime: 5 * (60 * 1000), // 5 mins
  });

  useEffect(() => {
    if (response && response.data) {
      setCategories(
        response.data.map((category) => ({
          id: category.id,
          title: category.title,
          description: category.description,
          active: true,
        })),
      );
    }
  }, [response]);

  const handleSelect = (id: number, active: boolean) => {
    const array = [...categories];
    array.forEach((item) => {
      if (item.id === id) {
        item.active = active;
      }
    });
    setCategories(array);
  };

  return (
    <SelectableListPopup
      className={className}
      title="Selecciona los temas para las preguntas"
      onClose={onClose}
      loading={isLoading}
      loadingItems={11}
      blocks={[
        {
          title: BLOCK_1,
          items:
            categories.map((lesson) => ({
              id: lesson.id,
              text: lesson.title,
              label: {
                value: ('0' + lesson.id).slice(-2),
                backgroundColor: '#ff9e99',
              },
              info: lesson.description,
              active: lesson.active,
            })) ?? [],
        },
      ]}
      onSelect={(id: number, active: boolean) => {
        if (profile.subscription?.isActive && !profile.subscription?.isTrial) {
          handleSelect(id, active);
        } else {
          setShowGetPremiumPopup(true);
        }
      }}
      onContinue={() => {
        if (profile.subscription?.isActive) {
          onSelect(
            categories
              .filter((lesson) => lesson.active)
              .map((lesson) => lesson.id),
          );
        } else {
          setShowGetPremiumPopup(true);
        }
      }}
    />
  );
}

export { Categories };
