import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import * as Sentry from '@sentry/react';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { Splash } from '../components/core/Splash';
import { useAppActions } from '../state/appStore';
import { useToasterActions } from '../state/toasterStore';
import { sleep } from '../utils/crosscutting';
import { useAuth } from './AuthContext';
import { useConfiguration } from './ConfigurationContext';
import { usePwa } from './PwaContext';

export type AxiosProps = {
  instance: AxiosInstance;
};

interface RetryableRequestConfig extends AxiosRequestConfig {
  __isRetryRequest?: boolean;
}

export const AxiosContext = createContext<AxiosProps | null>(null);

export const AxiosContextProvider = ({ children }: PropsWithChildren) => {
  const { configuration } = useConfiguration();
  const { user } = useAuth();
  const { setNeedUpdate } = usePwa();
  const { sendMessage } = useToasterActions();
  const { setShowGetPremiumPopup } = useAppActions();

  const [axiosInstance, setAxiosInstance] = useState<AxiosInstance>();

  useEffect(() => {
    if (user === null) {
      return;
    }

    const instance = axios.create({
      baseURL: configuration.app?.apiUrl,
    });

    const req = instance.interceptors.request.use(
      async (config) => {
        config.headers.Authorization = `Bearer ${await user.getIdToken()}`;
        config.headers.Accept = 'application/json';
        config.headers['Content-Type'] = 'application/json';
        config.headers['Version'] = '1.0';

        return config;
      },
      (error: AxiosError) => {
        Promise.reject(error);
      },
    );

    instance.defaults.validateStatus = (status: number) => status < 400;

    const res = instance.interceptors.response.use(
      (response) => {
        const xAppVersion = response.headers['x-app-version'];

        if (
          xAppVersion !== undefined &&
          import.meta.env.VITE_VERSION_MAJOR !== undefined &&
          import.meta.env.VITE_VERSION_MINOR !== undefined
        ) {
          const major = +xAppVersion.split('.')[0];
          const minor = +xAppVersion.split('.')[1];

          if (
            major > +import.meta.env.VITE_VERSION_MAJOR ||
            (major === +import.meta.env.VITE_VERSION_MAJOR &&
              minor > +import.meta.env.VITE_VERSION_MINOR)
          ) {
            sleep(5000).then(() => {
              setNeedUpdate(true);
            });
          } else {
            setNeedUpdate(false);
          }
        }

        return response;
      },
      async (error: AxiosError) => {
        if (error) {
          switch (error.code) {
            case 'ECONNABORTED':
            case 'ECONNREFUSED':
            case 'ECONNRESET':
            case 'ENOTFOUND':
            case 'ETIMEDOUT':
            case 'EHOSTUNREACH':
            case 'ERR_NETWORK':
              sendMessage(
                '¡Ups!',
                'Parece que ha ocurrido un error con la conexión de red.',
                'danger',
              );
              return Promise.reject(error);
          }
        }

        if (error.response) {
          const { config } = error;

          switch (error.response.status) {
            case 400:
              break;

            case 401:
              if (
                config &&
                !(config as RetryableRequestConfig).__isRetryRequest
              ) {
                (config as RetryableRequestConfig).__isRetryRequest = true;

                try {
                  config.headers.Authorization = `Bearer ${await user.getIdToken(
                    true,
                  )}`;
                  return instance.request(config);
                } catch (refreshError) {
                  Sentry.captureException(refreshError);
                  throw refreshError;
                }
              } else {
                sendMessage(
                  'No tienes permisos para realizar esta acción',
                  'No tienes permisos para realizar esta acción. Por favor, contacta con el administrador del sistema.',
                  'danger',
                );
              }
              break;

            case 403:
              setShowGetPremiumPopup(true);
              break;

            case 404:
              break;

            case 409:
              break;

            case 422:
              break;

            case 429:
              sendMessage(
                '¡Ups!',
                'Parece que has excedido el límite de uso permitido. Si el problema persiste escríbenos a info@opositandopn.com',
                'danger',
              );
              break;

            case 500:
              sendMessage(
                '¡Ups!',
                'Parece que ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.',
                'danger',
              );
              break;

            default:
              sendMessage(
                'Ha ocurrido un error',
                'Parece que algo salió mal',
                'danger',
              );
              break;
          }
        }

        return Promise.reject(error);
      },
    );

    setAxiosInstance(() => instance);

    return () => {
      instance.interceptors.request.eject(req);
      instance.interceptors.response.eject(res);
    };
  }, [configuration, sendMessage, setNeedUpdate, setShowGetPremiumPopup, user]);

  if (axiosInstance === undefined) {
    return <Splash />;
  }

  return (
    <AxiosContext.Provider value={{ instance: axiosInstance }}>
      {children}
    </AxiosContext.Provider>
  );
};

export const useAxios = () => {
  const context = useContext(AxiosContext);

  if (!context) {
    throw new Error('useAxios must be used within a AxiosContextProvider');
  }

  return context;
};
