import { useState } from 'react';

import {
  Button,
  Sidebar as SidebarComponent,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarSubMenu,
  SidebarToggle,
  ThemeToggleButton,
  Tooltip,
} from '@sealfye/ui-components';
import classnames from 'classnames';
import { FiSidebar } from 'react-icons/fi';
import {
  IoAnalyticsOutline,
  IoArrowDownCircleOutline,
  IoBugOutline,
  IoCalculatorOutline,
  IoChatboxEllipsesOutline,
  IoConstructOutline,
  IoDuplicateOutline,
  IoFileTrayFullOutline,
  IoHomeOutline,
  IoInformation,
  IoLibraryOutline,
  IoLogOutOutline,
  IoPersonCircleOutline,
  IoSettingsOutline,
  IoShapesOutline,
} from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SidebarImg } from '../../../assets/images/sidebar.svg';
import { useAuth } from '../../../context/AuthContext';
import { useConfiguration } from '../../../context/ConfigurationContext';
import { useProfile } from '../../../context/ProfileContext';
import { usePwa } from '../../../context/PwaContext';
import { useTheme } from '../../../context/ThemeContext';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useFeedback } from '../../../services/api/hooks/useFeedback';
import { useFeedbackActions } from '../../../state/feedbackStore';
import { useSidebar, useSidebarActions } from '../../../state/sidebarStore';
import { BaseComponentProps } from '../../../types/base-component.types';
import { Calculator } from '../../features/calculator/Calculator';
import { SampleMarks } from '../../features/sample-marks/SampleMarks';
import { User } from './User';

import styles from './Sidebar.module.scss';

function Sidebar({ className }: BaseComponentProps) {
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { profile } = useProfile();
  const { prompt, standalone, promptToInstall } = usePwa();
  const { theme, setTheme } = useTheme();
  const { toggled, showBtnToggle } = useSidebar();
  const { setToggled } = useSidebarActions();
  const { configuration } = useConfiguration();
  const { openFeedback } = useFeedbackActions();
  const { reportError } = useFeedback();

  const [showCalculator, setShowCalculator] = useState(false);
  const [showSampleMarks, setShowSampleMarks] = useState(false);

  async function logout() {
    auth.signOut();
    navigate('/');
  }

  return (
    <>
      <SidebarComponent
        className={classnames(styles['main'], className)}
        collapsed={windowSize.width < 992}
        toggled={toggled}
        onSidebarToggle={setToggled}
        image={<SidebarImg />}
      >
        <SidebarHeader>
          <User
            username={profile.username ?? 'username'}
            email={profile.email ?? 'email'}
            points={profile.challengesInfo?.points ?? 0}
            image={profile.avatarUrl ? profile.avatarUrl : undefined}
            school={profile.school?.name}
          />
        </SidebarHeader>
        <SidebarContent>
          <SidebarMenu>
            <SidebarMenuItem
              icon={<IoHomeOutline />}
              onClick={() => {
                navigate('/');
                setToggled(false);
              }}
            >
              Inicio
            </SidebarMenuItem>

            <SidebarSubMenu title="Teoría" icon={<IoLibraryOutline />}>
              <SidebarMenuItem
                onClick={() => {
                  navigate('/teoria');
                  setToggled(false);
                }}
              >
                Tests y simulacros
              </SidebarMenuItem>
              <SidebarMenuItem
                onClick={() => {
                  navigate('/teoria/esquemas');
                  setToggled(false);
                }}
              >
                Esquemas
              </SidebarMenuItem>
              <SidebarMenuItem
                onClick={() => {
                  navigate('/teoria/normativa');
                  setToggled(false);
                }}
              >
                Normativa
              </SidebarMenuItem>
              <SidebarMenuItem
                onClick={() => {
                  navigate('/teoria/simulacros/competitivos');
                  setToggled(false);
                }}
              >
                Simulacros mensuales
              </SidebarMenuItem>
              <SidebarMenuItem
                onClick={() => {
                  navigate('/teoria/examenes');
                  setToggled(false);
                }}
              >
                Exámenes oficiales
              </SidebarMenuItem>
              <SidebarMenuItem
                onClick={() => {
                  navigate('/teoria/preguntas/guardadas');
                  setToggled(false);
                }}
              >
                Preguntas guardadas
              </SidebarMenuItem>
              <SidebarMenuItem
                onClick={() => {
                  navigate('/teoria/simulacros/historico');
                  setToggled(false);
                }}
              >
                Histórico de simulacros
              </SidebarMenuItem>
            </SidebarSubMenu>

            <SidebarSubMenu title="Psicotécnicos" icon={<IoShapesOutline />}>
              <SidebarMenuItem
                onClick={() => {
                  navigate('/psicotecnicos');
                  setToggled(false);
                }}
              >
                Tests y simulacros
              </SidebarMenuItem>
              <SidebarMenuItem
                onClick={() => {
                  navigate('/psicotecnicos/simulacros/competitivos');
                  setToggled(false);
                }}
              >
                Simulacros semanales
              </SidebarMenuItem>
              <SidebarMenuItem
                onClick={() => {
                  navigate('/psicotecnicos/preguntas/guardadas');
                  setToggled(false);
                }}
              >
                Preguntas guardadas
              </SidebarMenuItem>
              <SidebarMenuItem
                onClick={() => {
                  navigate('/psicotecnicos/simulacros/historico');
                  setToggled(false);
                }}
              >
                Histórico de simulacros
              </SidebarMenuItem>
            </SidebarSubMenu>

            <SidebarSubMenu title="Inglés" icon={<IoChatboxEllipsesOutline />}>
              <SidebarMenuItem
                onClick={() => {
                  navigate('/ingles');
                  setToggled(false);
                }}
              >
                Tests y simulacros
              </SidebarMenuItem>
              <SidebarMenuItem
                onClick={() => {
                  navigate('/ingles/preguntas/guardadas');
                  setToggled(false);
                }}
              >
                Preguntas guardadas
              </SidebarMenuItem>
              <SidebarMenuItem
                onClick={() => {
                  navigate('/ingles/simulacros/historico');
                  setToggled(false);
                }}
              >
                Histórico de simulacros
              </SidebarMenuItem>
            </SidebarSubMenu>

            <SidebarMenuItem
              icon={<IoAnalyticsOutline />}
              onClick={() => {
                navigate('/progreso');
                setToggled(false);
              }}
            >
              Progreso
            </SidebarMenuItem>

            <SidebarMenuItem
              icon={<IoCalculatorOutline />}
              onClick={() => {
                setToggled(false);
                setShowCalculator(true);
              }}
            >
              Calculadora
            </SidebarMenuItem>

            {/* <SidebarMenuItem
              icon={<IoClipboardOutline />}
              onClick={() => {
                setToggled(false);
                setShowSampleMarks(true);
              }}
              suffix={
                <Badge bg="success" className={styles['badge']}>
                  2024
                </Badge>
              }
            >
              Notas del examen
            </SidebarMenuItem> */}

            {profile.role &&
              (profile.role === 'admin' ||
                profile.role.includes('contributor')) && (
                <SidebarSubMenu
                  title="Contribuir"
                  icon={<IoDuplicateOutline />}
                >
                  {(profile.role === 'admin' ||
                    profile.role === 'contributor' ||
                    profile.role === 'contributor:theory') && (
                    <SidebarMenuItem
                      onClick={() => {
                        navigate('/contribuir/teoria/preguntas');
                        setToggled(false);
                      }}
                    >
                      Teoría
                    </SidebarMenuItem>
                  )}
                  {(profile.role === 'admin' ||
                    profile.role === 'contributor' ||
                    profile.role === 'contributor:aptitude') && (
                    <SidebarMenuItem
                      onClick={() => {
                        navigate('/contribuir/psicotecnicos/preguntas');
                        setToggled(false);
                      }}
                    >
                      Psicotécnicos
                    </SidebarMenuItem>
                  )}
                  {(profile.role === 'admin' ||
                    profile.role === 'contributor' ||
                    profile.role === 'contributor:english') && (
                    <SidebarMenuItem
                      onClick={() => {
                        navigate('/contribuir/ingles/preguntas');
                        setToggled(false);
                      }}
                    >
                      Inglés
                    </SidebarMenuItem>
                  )}
                  {profile.role === 'admin' && (
                    <SidebarMenuItem
                      onClick={() => {
                        navigate('/contribuir/teoria/esquemas');
                        setToggled(false);
                      }}
                    >
                      Esquemas
                    </SidebarMenuItem>
                  )}
                </SidebarSubMenu>
              )}

            {profile.role &&
              (profile.role === 'admin' ||
                profile.role.includes('contributor')) && (
                <SidebarSubMenu
                  title="Errores"
                  icon={<IoFileTrayFullOutline />}
                >
                  {(profile.role === 'admin' ||
                    profile.role === 'contributor' ||
                    profile.role === 'contributor:theory') && (
                    <SidebarMenuItem
                      onClick={() => {
                        navigate('/admin/teoria/preguntas/feedback');
                        setToggled(false);
                      }}
                    >
                      Errores en preguntas
                    </SidebarMenuItem>
                  )}
                  {(profile.role === 'admin' ||
                    profile.role === 'contributor' ||
                    profile.role === 'contributor:theory') && (
                    <SidebarMenuItem
                      onClick={() => {
                        navigate('/admin/teoria/esquemas/feedback');
                        setToggled(false);
                      }}
                    >
                      Errores en esquemas
                    </SidebarMenuItem>
                  )}
                  {(profile.role === 'admin' ||
                    profile.role === 'contributor' ||
                    profile.role === 'contributor:aptitude') && (
                    <SidebarMenuItem
                      onClick={() => {
                        navigate('/admin/psicotecnicos/preguntas/feedback');
                        setToggled(false);
                      }}
                    >
                      Errores en psicotécnicos
                    </SidebarMenuItem>
                  )}
                  {(profile.role === 'admin' ||
                    profile.role === 'contributor' ||
                    profile.role === 'contributor:english') && (
                    <SidebarMenuItem
                      onClick={() => {
                        navigate('/admin/ingles/preguntas/feedback');
                        setToggled(false);
                      }}
                    >
                      Errores en inglés
                    </SidebarMenuItem>
                  )}
                  {profile.role === 'admin' && (
                    <SidebarMenuItem
                      onClick={() => {
                        navigate('/admin/feedback');
                        setToggled(false);
                      }}
                    >
                      Errores en la app
                    </SidebarMenuItem>
                  )}
                </SidebarSubMenu>
              )}

            {profile.role === 'admin' && (
              <SidebarSubMenu
                title="Administración"
                icon={<IoConstructOutline />}
              >
                <SidebarMenuItem
                  onClick={() => {
                    navigate('/admin/teoria/listado');
                    setToggled(false);
                  }}
                >
                  Listado de preguntas
                </SidebarMenuItem>
                <SidebarMenuItem
                  onClick={() => {
                    navigate('/admin/teoria/preguntas/revision');
                    setToggled(false);
                  }}
                >
                  Revisión de preguntas
                </SidebarMenuItem>
                <SidebarMenuItem
                  onClick={() => {
                    navigate('/admin/psicotecnicos/preguntas/revision');
                    setToggled(false);
                  }}
                >
                  Revisión de psicotécnicos
                </SidebarMenuItem>
                <SidebarMenuItem
                  onClick={() => {
                    navigate('/admin/ingles/preguntas/revision');
                    setToggled(false);
                  }}
                >
                  Revisión de inglés
                </SidebarMenuItem>
              </SidebarSubMenu>
            )}
          </SidebarMenu>
        </SidebarContent>
        <SidebarFooter>
          {profile.subscription?.isActive && profile.subscription?.isTrial && (
            <Button
              className={styles['button']}
              variant="outline-premium"
              onClick={() => {
                window.open(
                  `${configuration.app?.pageUrl}/mi-cuenta`,
                  '_blank',
                );
                setToggled(false);
              }}
            >
              Suscripción de prueba
            </Button>
          )}
          {!profile.subscription?.isActive &&
            !profile.subscription?.isTrial && (
              <Button
                className={styles['button']}
                variant="premium"
                shadow
                onClick={() => {
                  window.open(
                    `${configuration.app?.pageUrl}/mi-cuenta/premium`,
                    '_blank',
                  );
                  setToggled(false);
                }}
              >
                Hazte Premium
              </Button>
            )}
          <SidebarMenu>
            {prompt != null && !standalone && (
              <SidebarMenuItem
                icon={<IoArrowDownCircleOutline />}
                onClick={promptToInstall}
              >
                Instalar App
              </SidebarMenuItem>
            )}
            <SidebarMenuItem
              icon={<IoPersonCircleOutline />}
              onClick={() => {
                window.open(
                  `${configuration.app?.pageUrl}/mi-cuenta`,
                  '_blank',
                );
                setToggled(false);
              }}
            >
              Cuenta
            </SidebarMenuItem>
            <SidebarMenuItem
              icon={<IoSettingsOutline />}
              onClick={() => {
                window.open(
                  `${configuration.app?.pageUrl}/mi-cuenta/ajustes`,
                  '_blank',
                );
                setToggled(false);
              }}
            >
              Ajustes
            </SidebarMenuItem>
            <SidebarMenuItem
              icon={<IoBugOutline />}
              onClick={() => {
                setToggled(false);
                openFeedback(
                  '¿Has encontrado algún error en la app?',
                  async (feedback) => {
                    const response = await reportError(feedback);

                    if (!response.success) {
                      throw new Error('Error al enviar el feedback');
                    }
                  },
                );
              }}
            >
              Reportar un error
            </SidebarMenuItem>
            <SidebarMenuItem icon={<IoLogOutOutline />}>
              <Tooltip
                icon={<>Cerrar sesión</>}
                placement="left"
                behavior="click"
              >
                <div className={styles['tooltip-payload']}>
                  <span>¿Estás segurx?</span>
                  <Button variant="danger" size="sm" onClick={logout}>
                    Salir
                  </Button>
                </div>
              </Tooltip>
            </SidebarMenuItem>
            <SidebarMenuItem
              icon={<IoInformation />}
              onClick={() => {
                navigate('/info');
                setToggled(false);
              }}
              suffix={
                <div className={styles['options']}>
                  <ThemeToggleButton
                    className={styles['theme-toggle']}
                    checked={theme === 'dark'}
                    onToggle={() => {
                      setTheme(theme === 'dark' ? 'light' : 'dark');
                    }}
                  />
                </div>
              }
            >
              Info
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarFooter>
      </SidebarComponent>
      {showBtnToggle && windowSize.width < 992 && (
        <SidebarToggle
          className={styles['toggle']}
          onClick={() => setToggled(!toggled)}
        >
          <FiSidebar />
        </SidebarToggle>
      )}
      {showCalculator && (
        <Calculator onClose={() => setShowCalculator(false)} />
      )}
      {showSampleMarks && (
        <SampleMarks onClose={() => setShowSampleMarks(false)} />
      )}
    </>
  );
}

export { Sidebar };
