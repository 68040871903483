import { useEffect, useRef, useState } from 'react';

import {
  Badge,
  Card,
  CardPopup,
  List,
  ListSkeleton,
} from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import { IoReaderOutline } from 'react-icons/io5';

import { TheoryQuestionAnswerViewer } from '../../../../components/features/theory/TheoryQuestionAnswerViewer';
import { TheoryQuestionListFilter } from '../../../../components/features/theory/admin/TheoryQuestionListFilter';
import { OffsetPagination } from '../../../../components/shared/offset-pagination/OffsetPagination';
import { Page } from '../../../../components/shared/page/Page';
import { OffsetPaginationResponse } from '../../../../services/api/hooks/usePageRequest';
import {
  DetailedTheoryQuestionDto,
  useTheoryQuestion,
} from '../../../../services/api/hooks/useTheoryQuestion';
import { getMonthName } from '../../../../utils/date';

import styles from './TheoryQuestionsListPage.module.scss';

function TheoryQuestionsListPage() {
  const { getTheoryQuestions } = useTheoryQuestion();

  const [paginationResponse, setPaginationResponse] =
    useState<OffsetPaginationResponse>();
  const [questions, setQuestions] = useState<DetailedTheoryQuestionDto[]>([]);
  const [current, setCurrent] = useState<string>();

  const [pageNumber, setPageNumber] = useState(1);

  const nodeRef = useRef<HTMLDivElement>(null);

  const [filter, setFilter] = useState({
    group: 1,
    contains: '',
    trial: false,
  });

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['getTheoryQuestions', filter, pageNumber],
    queryFn: () => getTheoryQuestions(filter, pageNumber),
  });

  useEffect(() => {
    if (response?.data !== undefined) {
      setPaginationResponse(response.data);
      setQuestions(response.data.data);
    }
  }, [response]);

  return (
    <Page title="Listado de preguntas de teoría">
      {current ? (
        <CardPopup
          className={styles['card-popup']}
          onClose={() => {
            refetch();
            setCurrent(undefined);
          }}
          refClose={nodeRef}
          closeable={false}
        >
          <TheoryQuestionAnswerViewer
            id={current}
            onClose={() => {
              nodeRef.current?.click();
            }}
          />
        </CardPopup>
      ) : null}
      <TheoryQuestionListFilter
        values={{
          group: 1,
          contains: '',
          trial: false,
        }}
        onSubmit={(values) => {
          setPageNumber(1);
          setFilter({
            group: values.group || 1,
            contains: values.contains || '',
            trial: values.trial || false,
          });
        }}
      />
      <Card
        title="Listado de preguntas filtradas"
        caption={
          response?.data?.totalRecords
            ? `Total: ${response?.data?.totalRecords}`
            : undefined
        }
      >
        {isLoading ? (
          <ListSkeleton items={15} />
        ) : (
          <>
            <List
              items={questions.map((item) => ({
                label:
                  item.lesson < 2000
                    ? {
                        value: `Tema ${item.lesson}`,
                      }
                    : undefined,
                text:
                  item.question.length > 150
                    ? item.question.substring(0, 150) + '…'
                    : item.question,
                info: item.question,
                tag:
                  item.competitiveTest && item.competitiveTestMonth ? (
                    <Badge bg="warning">
                      {getMonthName(item.competitiveTestMonth - 1) +
                        ' ' +
                        item.competitiveTestYear}
                    </Badge>
                  ) : item.officialExam ? (
                    <Badge bg="featured">Examen oficial</Badge>
                  ) : null,
                icon: (
                  <IoReaderOutline
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setCurrent(item.id);
                    }}
                  />
                ),
              }))}
            />
            <OffsetPagination
              pagination={paginationResponse}
              onPageChange={(page) => setPageNumber(page)}
            />
          </>
        )}
      </Card>
    </Page>
  );
}

export { TheoryQuestionsListPage };
