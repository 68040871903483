import classnames from 'classnames';

import { BaseComponentProps } from '../../types/base-component.types';

import styles from './Version.module.scss';

function Version({ className }: BaseComponentProps) {
  return (
    <p className={classnames(styles['main'], className)}>
      v{import.meta.env.VITE_VERSION_MAJOR}.{import.meta.env.VITE_VERSION_MINOR}
      .{import.meta.env.VITE_VERSION_PATCH}
    </p>
  );
}

export { Version };
