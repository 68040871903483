import { useAxios } from '../../../context/AxiosContext';
import { ApiResponse } from '../response';

export interface AptitudeCategoryDto {
  id: number;
  title: string;
  description: string;
}

export function useAptitudeCategory() {
  const { instance } = useAxios();

  async function getAptitudeCategories(): Promise<
    ApiResponse<AptitudeCategoryDto[]>
  > {
    try {
      const response =
        await instance.get<AptitudeCategoryDto[]>(`/aptitude/categories`);

      return {
        success: response.status === 200,
        data: response.data || undefined,
      };
    } catch {
      return {
        success: false,
      };
    }
  }

  return { getAptitudeCategories };
}
