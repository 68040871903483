import { useEffect, useState } from 'react';

import { BarChartSkeleton, Card, LineChart } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import { IoStatsChartOutline } from 'react-icons/io5';

import { StatsMarkDto, useStats } from '../../../services/api/hooks/useStats';
import { formatSortDayDate } from '../../../utils/date';

import styles from './MarksStatistics.module.scss';

function MarksStatistics() {
  const [stats, setStats] = useState<StatsMarkDto[]>([]);

  const { getStatsMarks } = useStats();

  const { data: response, isLoading } = useQuery({
    queryKey: ['getStatsMarks'],
    queryFn: () => getStatsMarks(),
    gcTime: 5 * (60 * 1000), // 5 mins
  });

  useEffect(() => {
    if (response?.data !== undefined) {
      setStats(response.data);
    }
  }, [response]);

  return (
    <div className={styles['main']}>
      <Card
        className={styles['chart-card']}
        icon={<IoStatsChartOutline />}
        title="Media de los opositores en simulacros"
        caption="Últimos 14 días"
      >
        {isLoading ? (
          <BarChartSkeleton labels={2} />
        ) : (
          <LineChart
            className={styles['chart']}
            labels={stats.map((result) =>
              formatSortDayDate(new Date(result.date)),
            )}
            dataset={[
              {
                label: 'Teoría',
                values: stats.map((result) => result.theory.mean),
              },
              // {
              //   label: 'Ortografía',
              //   values: stats.map((result) => result.orthography.mean),
              // },
              {
                label: 'Psicotécnicos',
                values: stats.map((result) => result.aptitude.mean),
              },
              // {
              //   label: 'Inglés',
              //   values: stats.map((result) => result.english.mean),
              // },
            ]}
          />
        )}
      </Card>
    </div>
  );
}

export { MarksStatistics };
