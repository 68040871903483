import { ReactNode } from 'react';

import classnames from 'classnames';

import { BaseComponentProps } from '../../../types/base-component.types';
import { EditorPopup } from '../popups/EditorPopup';
import { FeedbackPopup } from '../popups/FeedbackPopup';
import { GetPremiumPopup } from '../popups/GetPremiumPopup';
import { Sidebar } from './Sidebar';

import styles from './Layout.module.scss';

type ContainerProps = BaseComponentProps & {
  children: ReactNode;
};

function Layout({ className, children }: ContainerProps) {
  return (
    <div className={classnames(styles['wrapper'], className)}>
      <Sidebar />
      <main className={styles['main']}>{children}</main>
      <FeedbackPopup />
      <EditorPopup />
      <GetPremiumPopup />
    </div>
  );
}

export { Layout };
