import {
  CardPopup,
  RadioButton,
  SelectableList,
  SelectableListSkeleton,
} from '@sealfye/ui-components';
import classnames from 'classnames';

import { SelectableListPopupProps } from './SelectableListPopup.types';
import { Footer } from './layout/Footer';
import { Header } from './layout/Header';

import styles from './SelectableListPopup.module.scss';

function SelectableListPopup({
  className,
  blocks,
  title,
  loading,
  loadingItems = 10,
  multiblock,
  onSelect,
  onClose,
  onContinue,
}: SelectableListPopupProps) {
  return (
    <CardPopup
      className={classnames(styles['card'], className)}
      onClose={onClose}
    >
      <div className={styles['main']}>
        <Header title={title} />
        {loading ? (
          <SelectableListSkeleton
            items={loadingItems}
            className={styles['skeleton']}
          />
        ) : (
          <div className={styles['body']}>
            {blocks.length > 0 && multiblock !== undefined && (
              <div className={styles['header']}>
                <span className={styles['header__title']}>
                  {multiblock.title}
                </span>
                <RadioButton
                  className={styles['button']}
                  checked={
                    blocks
                      .map((block) => block.items)
                      .flat()
                      .find((item) => item.active === false)
                      ? false
                      : true
                  }
                  onChange={() => {
                    multiblock.onSelectAll(
                      blocks
                        .map((block) => block.items)
                        .flat()
                        .find((item) => item.active === true)
                        ? false
                        : true,
                    );
                  }}
                />
              </div>
            )}
            {blocks.map((block) => (
              <SelectableList
                key={block.title}
                title={block.title}
                items={block.items}
                onSelect={onSelect}
              />
            ))}
          </div>
        )}
        <Footer
          onClick={onContinue}
          loading={loading}
          disabled={
            blocks
              .map((block) => block.items)
              .flat()
              .find((item) => item.active === true)
              ? false
              : true
          }
        />
      </div>
    </CardPopup>
  );
}

export { SelectableListPopup };
