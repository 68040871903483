import classnames from 'classnames';

import { ReactComponent as EmptyImg } from '../../assets/images/empty-woman.svg';
import { BaseComponentProps } from '../../types/base-component.types';

import styles from './Empty.module.scss';

type ContainerProps = BaseComponentProps & {
  title?: string;
};

function Empty({ className, title }: ContainerProps) {
  return (
    <div
      className={classnames(styles['main'], className)}
      data-test-id="ui-empty"
    >
      <div className={styles['wrapper']}>
        <EmptyImg className={styles['image']} />
        <h1 className={styles['title']}>{title ?? '¡Ups! No hay nada aquí'}</h1>
      </div>
    </div>
  );
}

export { Empty };
