import { useEffect, useState } from 'react';

import { Card, List, ListSkeleton } from '@sealfye/ui-components';
import { useQuery } from '@tanstack/react-query';
import { IoArrowForwardCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { OffsetPagination } from '../../../components/shared/offset-pagination/OffsetPagination';
import { Page } from '../../../components/shared/page/Page';
import { useProfile } from '../../../context/ProfileContext';
import { OffsetPaginationResponse } from '../../../services/api/hooks/usePageRequest';
import {
  CompetitiveTestMetadataDto,
  useTheoryTest,
} from '../../../services/api/hooks/useTheoryTest';
import { useAppActions } from '../../../state/appStore';
import { formatMonthDate } from '../../../utils/date';

function TheoryCompetitiveQuestionnairesPage() {
  const { getCompetitiveTestsList } = useTheoryTest();
  const { setShowGetPremiumPopup } = useAppActions();
  const navigate = useNavigate();
  const { profile } = useProfile();
  const [paginationResponse, setPaginationResponse] =
    useState<OffsetPaginationResponse>();
  const [exams, setExams] = useState<CompetitiveTestMetadataDto[]>([]);

  const [pageNumber, setPageNumber] = useState(1);

  const { data: response, isLoading } = useQuery({
    queryKey: ['getCompetitiveTestsList', pageNumber],
    queryFn: () => getCompetitiveTestsList(pageNumber),
    gcTime: 5 * (60 * 1000), // 5 mins
  });

  useEffect(() => {
    if (response?.data !== undefined) {
      setPaginationResponse(response.data);
      setExams(response.data.data);
    }
  }, [response]);

  const onSelect = (id: string) => {
    if (profile.subscription?.isActive && !profile.subscription?.isTrial) {
      navigate({
        pathname: '/teoria/simulacro',
        search: `?competitivo=${id}`,
      });
    } else {
      setShowGetPremiumPopup(true);
    }
  };

  return (
    <Page title="Simulacros mensuales">
      <Card title="Simulacros mensuales de teoría">
        {isLoading ? (
          <ListSkeleton items={15} />
        ) : (
          exams &&
          exams.length > 0 && (
            <List
              items={exams.map((item) => ({
                label: {
                  value: new Date(item.date).getFullYear(),
                },
                text: `Simulacro de ${formatMonthDate(
                  new Date(item.date),
                )} | Nota media: ${item.averageScore}`,
                icon: (
                  <IoArrowForwardCircleOutline
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      onSelect(item.id);
                    }}
                  />
                ),
              }))}
            />
          )
        )}
        <OffsetPagination
          pagination={paginationResponse}
          onPageChange={(page) => setPageNumber(page)}
        />
      </Card>
    </Page>
  );
}

export { TheoryCompetitiveQuestionnairesPage };
